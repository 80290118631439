import { z } from 'zod'
import { counselingSchema as base } from 's2-lib'

export const counselingAnswerSchema = z.record(
  z.union([z.string(), z.string().array()])
)

export const counselingInputTypeEnum = z.enum(['date'])

export const counselingSchema = base
  .omit({
    createdAt: true,
    updatedAt: true,
  })
  .extend({
    inputType: counselingInputTypeEnum.optional(),
    options: z
      .string()
      .array()
      .transform((v) =>
        v.map((str) => {
          const [key, value] = str.split(':')
          return {
            value: key.trim(),
            label: (value || key).trim(),
          }
        }, {})
      )
      .nullable()
      .default(null),
  })

export type Counseling = z.infer<typeof counselingSchema>

export type CounselingAnswer = z.infer<typeof counselingAnswerSchema>

export type CounselingInputType = z.infer<typeof counselingInputTypeEnum>
