import { Button, Slide, styled } from '@mui/material'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { FC, useState, useEffect, useCallback, memo, useRef } from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { useNavigate } from 'react-router-dom'
import { S2Snackbar } from 's2-component'
import { userGender, DefaultProfileEnum } from 's2-lib'
import {
  S2FaceDetectCameraView,
  S2FaceDetectUIView,
  S2TipsLoadingView,
  FaceDirVariant,
  useFaceDetect,
  ERROR_CAMERA_NOT_FOUND,
  ERROR_CAMERA_PERMISSION_DENIED,
} from 's2-shooting-component'
import { useAccount } from '../../../common/hooks/useAccount'
import { useSkinDetect } from '../../../common/hooks/useDiagnosis'
import { useProjectValue } from '../../../common/hooks/useProject'
import { useRedirect } from '../../../common/hooks/useRedirect'
import CameraAccessDeniedDialog from './CameraAccessDeniedDialog'
import HowToBestShootingLauncher from './HowToBestShootingLauncher'
import ShootingConfirm from './ShootingConfirm'

const PUBLIC_BASE_PATH = import.meta.env.VITE_PUBLIC_BASE_PATH ?? '/'
const MemoizeS2FaceDetectCameraView = memo(S2FaceDetectCameraView)

const MemoizeShootingConfirm = memo(ShootingConfirm)

const ManualPlayButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 0,
  padding: theme.spacing(4),
  borderRadius: '9999px',
}))

type ShootingViewProps = {
  postLoading?: boolean
  answers?: Record<string, string | string[]>
  variants: FaceDirVariant[]
  variant: FaceDirVariant
}

const PhaseEnum = {
  Initializing: 0,
  Capturing: 1,
  Confirm: 2,
  Fetching: 3,
} as const

type PhaseEnumType = (typeof PhaseEnum)[keyof typeof PhaseEnum]

const ShootingView: FC<ShootingViewProps> = ({
  postLoading,
  answers,
  variants,
  variant,
}) => {
  const [account, { updateProfile }] = useAccount()
  const {
    tips,
    labels,
    history: {
      features: { trimmingPict },
    },
  } = useProjectValue()
  const nextVariant = variants[variants.indexOf(variant) + 1]
  const navigater = useNavigate()

  // 撮影バリエーション
  const shotImageMapRef = useRef<
    Record<FaceDirVariant, { origin: string; trimming: string } | undefined>
  >({
    front: undefined,
    'dir-left': undefined,
    'dir-right': undefined,
  })

  const detect = useSkinDetect()
  const redirect = useRedirect()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [enableManualPlayVideo, setEnableManualPlayVideo] = useState(false)
  const [showCameraDenied, setShowCameraDenied] = useState(false)
  const [phase, setPhase] = useState<PhaseEnumType>(PhaseEnum.Initializing)
  const [loadingState, setLoading] = useState({
    label: '',
    progress: 0,
  })
  const { toBase64, ready, start, stop } = useFaceDetect()

  const onDonePostLoading = useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.delete('variant')
    redirect({
      pathname: '/result',
      search: searchParams.toString(),
    })
  }, [redirect])

  const onReadyOrReset = useCallback(async () => {
    setPhase(PhaseEnum.Capturing)
    const success = await start()
    setEnableManualPlayVideo(!success)
  }, [start])

  const onCapture = useCallback(() => {
    setPhase(PhaseEnum.Confirm)
    stop()
  }, [stop])

  const onSubmit = useCallback(async () => {
    const { front, ...additionalImages } = shotImageMapRef.current
    const additionals = Object.entries(additionalImages).reduce<
      Record<string, string>
    >((acc, [key, value]) => {
      if (value) {
        acc[key] = value.origin
      }
      return acc
    }, {})

    if (!front) {
      throw new Error('撮影された画像がありません')
    }

    if (postLoading) {
      setPhase(PhaseEnum.Fetching)
    }

    const startTime = performance.now()
    const detectingLabel = labels.detecting
    let reqId = 0

    setErrorMessage(null)

    setLoading({
      label: detectingLabel,
      progress: 0,
    })

    // もしまだ必須プロフィールが空なら更新
    const gender = answers?.[DefaultProfileEnum.Gender]
    const birthDate = answers?.[DefaultProfileEnum.BirthDate]

    if (
      !account?.gender &&
      !account?.birthDate &&
      userGender.safeParse(gender).success &&
      typeof birthDate === 'string' &&
      !Number.isNaN(Date.parse(birthDate))
    ) {
      const updateProfileResult = await updateProfile({
        gender: userGender.parse(gender),
        birthDate,
      })
      if (updateProfileResult.isErr) {
        throw updateProfileResult.error
      }
    }

    // 2秒（現在平均的にかかるAPIの時間）かけて90%のとこまで進める
    ;(function tick(timeStamp: number) {
      const progress = Math.min(1, (timeStamp - startTime) / 2000)

      if (progress > 1) {
        return
      }

      reqId = requestAnimationFrame(tick)
      setLoading({
        label: detectingLabel,
        progress: progress * 0.9,
      })
    })(startTime)

    const detectResult = await detect(
      front.origin,
      front.trimming,
      additionals,
      answers!
    )

    cancelAnimationFrame(reqId)

    if (detectResult.isErr) {
      throw detectResult.error
    }

    setLoading({
      label: detectingLabel,
      progress: 1,
    })
  }, [
    account?.birthDate,
    account?.gender,
    answers,
    detect,
    labels.detecting,
    postLoading,
    updateProfile,
  ])

  const onPreSubmit = useCallback(() => {
    try {
      const images = toBase64(trimmingPict)
      shotImageMapRef.current[variant] = images
      if (nextVariant) {
        onReadyOrReset()
        const searchParams = new URLSearchParams(window.location.search)
        searchParams.set('variant', nextVariant)
        navigater(`/shooting?${searchParams.toString()}`)
      } else {
        onSubmit()
      }
    } catch (e) {
      setErrorMessage(
        e instanceof Error
          ? e.message
          : `診断中にエラーが発生しました。\n時間をおいて改めてお試しください。`
      )
      onReadyOrReset()
    }
  }, [
    trimmingPict,
    nextVariant,
    navigater,
    onSubmit,
    onReadyOrReset,
    toBase64,
    variant,
  ])

  // Initialize開始
  useEffect(() => {
    let isMounted = true

    ready(`${PUBLIC_BASE_PATH}models`, (progress: number) => {
      if (isMounted) {
        setLoading({ label: '初期化中', progress })
      }
    }).catch((e) => {
      const { message } = e as Error
      switch (message) {
        case ERROR_CAMERA_NOT_FOUND:
        case ERROR_CAMERA_PERMISSION_DENIED:
          setShowCameraDenied(true)
          break
        default:
          setErrorMessage(message)
          break
      }

      setLoading({ label: '初期化に失敗しました', progress: 0 })
    })

    return () => {
      isMounted = false
    }
  }, [ready])

  // front撮影前にvariantがfrontでない場合はリダイレクト
  useEffect(() => {
    if (variant !== 'front' && !shotImageMapRef.current.front) {
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.delete('variant')
      navigater(`/shooting?${searchParams.toString()}`)
    }
  }, [navigater, variant])

  return (
    <>
      <MemoizeS2FaceDetectCameraView />
      <SwitchTransition>
        <CSSTransition key={phase} classNames="fade" timeout={200}>
          <div>
            {phase === PhaseEnum.Initializing && (
              <S2TipsLoadingView
                {...loadingState}
                tips={tips}
                onDone={onReadyOrReset}
              />
            )}
            {phase === PhaseEnum.Capturing && (
              <>
                <S2FaceDetectUIView
                  variant={variant}
                  onCapture={onCapture}
                  bottom={<HowToBestShootingLauncher />}
                />
                {enableManualPlayVideo && (
                  <ManualPlayButton
                    variant="contained"
                    color="primary"
                    size="large"
                    aria-label="play video"
                    onClick={onReadyOrReset}
                  >
                    <PlayArrowIcon fontSize="large" />
                  </ManualPlayButton>
                )}
              </>
            )}
            {phase === PhaseEnum.Fetching && (
              <S2TipsLoadingView
                {...loadingState}
                tips={tips}
                onDone={onDonePostLoading}
              />
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>
      <Slide
        direction="up"
        in={phase === PhaseEnum.Confirm}
        mountOnEnter
        unmountOnExit
      >
        <MemoizeShootingConfirm
          onSubmit={onPreSubmit}
          onReset={onReadyOrReset}
          isLastVariant={!nextVariant}
        />
      </Slide>
      {errorMessage && <S2Snackbar severity="error">{errorMessage}</S2Snackbar>}
      <CameraAccessDeniedDialog
        open={showCameraDenied}
        onClose={() => setShowCameraDenied(false)}
      />
    </>
  )
}

export default ShootingView
