import { ErrorBoundary, withSentryReactRouterV6Routing } from '@sentry/react'
import { FC, Suspense, StrictMode, memo } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { LocalizationProvider } from '@mui/x-date-pickers'
import CssBaseline from '@mui/material/CssBaseline'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { RecoilRoot } from 'recoil'
import {
  BrowserRouter,
  MemoryRouter,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { FallbackLoader } from 's2-component'
import { AuthProvider } from './common/hooks/useAuth'
import { projectIdState } from './common/hooks/useProject'
import { gaMeasurementIdState } from './common/hooks/useGA'
import { idTokenState } from './common/hooks/useAccount'
import { BaseLayout } from './components/layouts/BaseLayout'
import { ErrorBoundaryFallback } from './components/ui/ErrorBoundaryFallback'
import { GASetup } from './components/functional/GASetup'
import NotFound from './pages/not-found'

import Home from './pages/index'
import Welcome from './pages/welcome'
import Counseling from './pages/counseling'
import Shooting from './pages/shooting'
import Result from './pages/result'
import PrivacyPolicy from './pages/privacy-policy'
import TermsOfService from './pages/terms-of-service'
// const Home = lazy(() => import('./pages/index'))
// const Welcome = lazy(() => import('./pages/welcome'))
// const Counseling = lazy(() => import('./pages/counseling'))
// const Shooting = lazy(() => import('./pages/shooting'))
// const Result = lazy(() => import('./pages/result'))
// const PrivacyPolicy = lazy(() => import('./pages/privacy-policy'))
// const TermsOfService = lazy(() => import('./pages/terms-of-service'))

const SentryRoutes = withSentryReactRouterV6Routing(Routes)

const Routers = memo(() => {
  const location = useLocation()
  return (
    <SwitchTransition>
      <CSSTransition key={location.pathname} classNames="fade" timeout={200}>
        <Suspense fallback={<FallbackLoader fullHeight />}>
          <SentryRoutes location={location}>
            <Route path="/" element={<Home />} />
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/counseling" element={<Counseling />} />
            <Route path="/shooting" element={<Shooting />} />
            <Route path="/result" element={<Result />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="*" element={<NotFound />} />
          </SentryRoutes>
        </Suspense>
      </CSSTransition>
    </SwitchTransition>
  )
})

type AppProps = {
  projectId: string
  idToken?: string
  idLinkage?: string
  routerType?: 'memory' | 'browser'
  baseUrl?: string
  dynamicParams?: Record<string, any>
}

export const App: FC<AppProps> = ({
  projectId,
  idToken,
  idLinkage,
  routerType = 'browser',
  baseUrl = '/',
  dynamicParams,
}) => {
  const Router = routerType === 'memory' ? MemoryRouter : BrowserRouter
  const basename = routerType === 'memory' ? '/' : baseUrl
  const gaMeasurementId = import.meta.env.VITE_GA_MEASUREMENT_ID

  return (
    <StrictMode>
      <ErrorBoundary
        // eslint-disable-next-line react/no-unstable-nested-components
        fallback={(props) => (
          <ErrorBoundaryFallback
            {...props}
            postMessage={
              <>
                エラーは開発チームに通知済です。
                <br />
                再発する場合は、時間をおいてから改めてご利用ください。
              </>
            }
          />
        )}
      >
        <HelmetProvider>
          <Helmet>
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Roboto:wght@400;500;700&display=swap"
            />
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/icon?family=Material+Icons+Round"
            />
          </Helmet>
          <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="ja">
            <RecoilRoot
              initializeState={({ set }) => {
                set(gaMeasurementIdState, gaMeasurementId)
                set(projectIdState, projectId)
                set(idTokenState, { idLinkage, idToken, dynamicParams })
              }}
            >
              <CssBaseline />
              <BaseLayout>
                <Suspense fallback={<FallbackLoader fullHeight />}>
                  <AuthProvider projectId={projectId}>
                    <GASetup />
                    <Router basename={basename}>
                      <Routers />
                    </Router>
                  </AuthProvider>
                </Suspense>
              </BaseLayout>
            </RecoilRoot>
          </LocalizationProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </StrictMode>
  )
}
