import { AxiosError } from 'axios'
import { Result } from '@badrap/result'
import { APIError, APIResult, useRecoilValueWithPrevious } from 's2-lib'
import {
  atom,
  atomFamily,
  selectorFamily,
  useRecoilCallback,
  useRecoilValue,
  useRecoilValueLoadable,
} from 'recoil'
import { startTransition, useRef } from 'react'
import {
  diagnosisResultSchema,
  DiagnosisResult,
  diagnosisSchema,
  Diagnosis,
} from '../schemas/diagnosis'
import client from '../utils/api-client'
import { projectState } from './useProject'
import { accountState } from './useAccount'

export const currentDiagnosisIdState = atom<number | null>({
  key: 'currentDiagnosisIdState',
  default: null,
})

export const latestDiagnosisIdState = atom<number | null>({
  key: 'latestDiagnosisIdState',
  default: null,
})

/**
 * 履歴用
 */
export const diagnosisHistoryState = atomFamily<number[], number>({
  key: 'diagnosisHistoryState',
  default: [],
})

/**
 * 期間結果用
 */
export const diagnosisListState = atomFamily<Diagnosis[], number>({
  key: 'diagnosisListState',
  default: [],
})

export const diagnosisListSelector = selectorFamily<Diagnosis[], number>({
  key: 'diagnosisListSelector',
  get:
    (first) =>
    async ({ get }) => {
      const latestId = get(latestDiagnosisIdState)
      const account = get(accountState)

      if (!latestId || !account) {
        throw new Error('Not Found Latest Result')
      }

      const list = get(diagnosisListState(latestId))

      if (list.length >= first) {
        return list.slice(0, first)
      }

      const [after, before] = [
        latestId,
        // MEMO:
        // 履歴移行によって登録されたユーザーの場合
        // ユーザーの登録日より初回診断日の方が過去になってしまうので
        // 苦肉の策だがユーザーの作成日を1日前にする
        Date.parse(account.createdAt) - 1000 * 60 * 60 * 24,
      ].sort()
      const response = await client.fetchDiagnoses(before + 1, after - 1, first)
      return response.data.items.map((item) => diagnosisSchema.parse(item))
    },
})

/**
 * 詳細結果用
 */
export const diagnosisState = atomFamily<DiagnosisResult | null, number>({
  key: 'diagnosisState',
  default: null,
})

export const diagnosisSelector = selectorFamily<
  DiagnosisResult | null,
  number | null
>({
  key: 'diagnosisSelector',
  get:
    (id) =>
    async ({ get }) => {
      if (!id) {
        return null
      }

      const state = get(diagnosisState(id))
      if (state) {
        return state
      }
      const response = await client.findDiagnosisById(id)
      return diagnosisResultSchema.parse(response.data)
    },
  set:
    (id) =>
    ({ set }, newValue) => {
      if (id) {
        set(diagnosisState(id), newValue)
      }
    },
})

export const useCurrentDiagnosisValueLoadable = () => {
  const currentId = useRecoilValue(currentDiagnosisIdState)
  return useRecoilValueLoadable(diagnosisSelector(currentId))
}

export const useComparisonDiagnosisValueLoadable = (id: number | null) =>
  useRecoilValueLoadable(diagnosisSelector(id))

export const useLatestDiagnosisValue = () => {
  const latestId = useRecoilValue(latestDiagnosisIdState)
  return useRecoilValue(diagnosisSelector(latestId))
}

export const useHistory = () => {
  const latestId = useRecoilValue(latestDiagnosisIdState)

  if (!latestId) {
    throw new Error('Not Found Latest Result')
  }

  const history = useRecoilValue(diagnosisHistoryState(latestId))
  const nextRef = useRef<{
    token?: string
    hasNext: boolean
  }>({
    hasNext: true,
  })
  const fetchHistory = useRecoilCallback(
    ({ set, snapshot }) =>
      async (): Promise<APIResult<boolean>> => {
        try {
          const state = diagnosisHistoryState(latestId)
          const current = await snapshot.getPromise(state)
          const response = await client.fetchHistories(
            latestId,
            nextRef.current.token
          )
          const { items = [], nextToken } = response.data
          nextRef.current = {
            token: nextToken,
            hasNext: !!nextToken,
          }
          const uniqueItems = new Set([...current, ...items])
          set(
            state,
            [...uniqueItems].sort((a, b) => b - a)
          )
          return Result.ok(true)
        } catch (e) {
          const err = e as AxiosError
          return Result.err(
            new APIError({
              code: err.code,
              statusCode: err.status ?? 500,
              message: err.message,
            })
          )
        }
      },
    [latestId]
  )

  return {
    history,
    fetchHistory,
    hasNext: nextRef.current.hasNext,
  }
}

export const useSkinDetect = () => {
  const detectSkin = useRecoilCallback(
    ({ set, snapshot }) =>
      async (
        originPict: string,
        trimmingPict: string,
        additionalPicts: Record<string, string>,
        counseling: Record<string, any>
      ): Promise<APIResult<DiagnosisResult>> => {
        try {
          const project = await snapshot.getPromise(projectState)
          const enabledSaveFacePict = project.features.saveFacePict
          const response = await client.detectSkin({
            trimmingPict,
            originPict: enabledSaveFacePict ? originPict : undefined,
            additionalPicts: enabledSaveFacePict ? additionalPicts : undefined,
            counseling,
          })
          const result = diagnosisResultSchema.parse(response.data)
          const { prevId } = result.item
          const id = result.item.createdAt
          const prevState = prevId && diagnosisState(prevId)
          const prev = prevState && (await snapshot.getPromise(prevState))

          startTransition(() => {
            set(diagnosisSelector(id), result)
            set(currentDiagnosisIdState, id)
            set(latestDiagnosisIdState, id)

            if (prev) {
              set(diagnosisSelector(prevId), {
                ...prev,
                item: {
                  ...prev.item,
                  nextId: id,
                },
              })
            }
          })

          return Result.ok(result)
        } catch (e) {
          const err = e as AxiosError
          return Result.err(
            new APIError({
              code: err.code,
              statusCode: err.status ?? 500,
              message: err.message,
            })
          )
        }
      },
    []
  )

  return detectSkin
}

export const useDiagnoses = (limit: number) =>
  useRecoilValueWithPrevious(diagnosisListSelector(limit))

export const useTransferDiagnosis = () => {
  const signTransferKey = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const [latest] = await Promise.all([
          snapshot.getPromise(latestDiagnosisIdState),
        ])
        if (!latest) {
          return null
        }
        const response = await client.signTransferDiagnosisKey(latest)
        return response.data.key
      },
    []
  )

  const transferDiagnosis = useRecoilCallback(
    ({ snapshot, set }) =>
      async (key: string) => {
        try {
          const [response, latest] = await Promise.all([
            client.transferDiagnosis({ key }),
            snapshot.getPromise(latestDiagnosisIdState),
          ])
          const { success, createdAt: id } = response.data
          if (success) {
            set(latestDiagnosisIdState, Math.max(id, latest ?? 0))
            set(currentDiagnosisIdState, id)
          }
          return success
        } catch {
          return false
        }
      },
    []
  )

  return {
    signTransferKey,
    transferDiagnosis,
  }
}
