import { z } from 'zod'

export const skinTypeEnum = z.enum([
  'oily',
  'mix',
  'sensitive',
  'normal',
  'dry',
])

export const skinRankEnum = z.enum(['A', 'B', 'C', 'D', 'E'])

export const skinScore = z.object({
  average: z.number().optional(),
  score: z.number(),
  rank: skinRankEnum,
  details: z
    .object({
      detailType: z.string().nullable().default(null),
      detailUnit: z.string().nullable().default(null),
      detailValue: z.union([z.string(), z.number()]).nullable().default(null),
    })
    .nullable()
    .default(null),
})

export const diagnosisSchema = z.object({
  scores: z.object({
    wrinkle: skinScore,
    stain: skinScore,
    texture: skinScore,
    clarity: skinScore,
    moisture: skinScore,
    pores: skinScore,
  }),
  type: skinTypeEnum,
  age: z.number(),
  createdAt: z.number(),
  prevId: z.number().optional(),
  nextId: z.number().optional(),
  counseling: z.record(z.union([z.string(), z.string().array()])).optional(),
  faceImagePath: z.string().nullable().default(null),
  additionalImagePaths: z.record(z.string()).optional(),
})

export type Diagnosis = z.infer<typeof diagnosisSchema>
