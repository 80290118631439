import { Alert, AlertTitle, Box, Button } from '@mui/material'
import { FallbackRender } from '@sentry/react'
import { FC, ReactNode } from 'react'
import { createBoxShadow } from '../../common/utils/createBoxShadow'
import { FillHeight } from '../layouts/FillHeight'

export type ErrorBoundaryFallbackProps = Partial<
  Parameters<FallbackRender>[0]
> & {
  title?: string
  message?: ReactNode
  preMessage?: ReactNode
  postMessage?: ReactNode
}

export const ErrorBoundaryFallback: FC<ErrorBoundaryFallbackProps> = ({
  title = 'エラーが発生しました。',
  message,
  preMessage: preDescription,
  postMessage: postDescription,
  error,
  resetError,
  // componentStack,
  // eventId,
}) => {
  if (!message && error instanceof Error) {
    // eslint-disable-next-line no-param-reassign
    message = error.message
  }

  return (
    <FillHeight
      className="s2-flex-center"
      sx={{
        mx: 'auto',
        maxWidth: 'sm',
        position: 'relative',
        backgroundColor: '#fff',
        p: 2,
        boxShadow: (theme) =>
          createBoxShadow(theme.shadows[6], theme.palette.primary.main),
      }}
    >
      <Box>
        <Alert severity="error">
          <AlertTitle>{title}</AlertTitle>
          {preDescription && <Box my={2}>{preDescription}</Box>}
          {message && <Box my={2}>{message}</Box>}
          {postDescription && <Box my={2}>{postDescription}</Box>}
        </Alert>
        {resetError && (
          <Box textAlign="center" mt={4}>
            <Button
              variant="outlined"
              size="large"
              fullWidth
              onClick={resetError}
            >
              やり直す
            </Button>
          </Box>
        )}
      </Box>
    </FillHeight>
  )
}
