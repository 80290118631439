/* tslint:disable */
/* eslint-disable */
/**
 * skinsense API
 * skinsenseで用いるAPIの仕様書
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration'
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common'
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from './base'

/**
 *
 * @export
 * @interface CheckHelth200Response
 */
export interface CheckHelth200Response {
  /**
   *
   * @type {boolean}
   * @memberof CheckHelth200Response
   */
  success: boolean
}
/**
 *
 * @export
 * @interface CheckHelth400Response
 */
export interface CheckHelth400Response {
  /**
   *
   * @type {string}
   * @memberof CheckHelth400Response
   */
  code: string
  /**
   *
   * @type {string}
   * @memberof CheckHelth400Response
   */
  message: string
  /**
   *
   * @type {any}
   * @memberof CheckHelth400Response
   */
  data?: any
  /**
   *
   * @type {number}
   * @memberof CheckHelth400Response
   */
  statusCode: CheckHelth400ResponseStatusCodeEnum
}

export const CheckHelth400ResponseStatusCodeEnum = {
  NUMBER_400: 400,
} as const

export type CheckHelth400ResponseStatusCodeEnum =
  (typeof CheckHelth400ResponseStatusCodeEnum)[keyof typeof CheckHelth400ResponseStatusCodeEnum]

/**
 *
 * @export
 * @interface CheckHelth401Response
 */
export interface CheckHelth401Response {
  /**
   *
   * @type {string}
   * @memberof CheckHelth401Response
   */
  code: string
  /**
   *
   * @type {string}
   * @memberof CheckHelth401Response
   */
  message: string
  /**
   *
   * @type {any}
   * @memberof CheckHelth401Response
   */
  data?: any
  /**
   *
   * @type {number}
   * @memberof CheckHelth401Response
   */
  statusCode: CheckHelth401ResponseStatusCodeEnum
}

export const CheckHelth401ResponseStatusCodeEnum = {
  NUMBER_401: 401,
} as const

export type CheckHelth401ResponseStatusCodeEnum =
  (typeof CheckHelth401ResponseStatusCodeEnum)[keyof typeof CheckHelth401ResponseStatusCodeEnum]

/**
 *
 * @export
 * @interface CheckHelth403Response
 */
export interface CheckHelth403Response {
  /**
   *
   * @type {string}
   * @memberof CheckHelth403Response
   */
  code: string
  /**
   *
   * @type {string}
   * @memberof CheckHelth403Response
   */
  message: string
  /**
   *
   * @type {any}
   * @memberof CheckHelth403Response
   */
  data?: any
  /**
   *
   * @type {number}
   * @memberof CheckHelth403Response
   */
  statusCode: CheckHelth403ResponseStatusCodeEnum
}

export const CheckHelth403ResponseStatusCodeEnum = {
  NUMBER_403: 403,
} as const

export type CheckHelth403ResponseStatusCodeEnum =
  (typeof CheckHelth403ResponseStatusCodeEnum)[keyof typeof CheckHelth403ResponseStatusCodeEnum]

/**
 *
 * @export
 * @interface CheckHelth404Response
 */
export interface CheckHelth404Response {
  /**
   *
   * @type {string}
   * @memberof CheckHelth404Response
   */
  code: string
  /**
   *
   * @type {string}
   * @memberof CheckHelth404Response
   */
  message: string
  /**
   *
   * @type {any}
   * @memberof CheckHelth404Response
   */
  data?: any
  /**
   *
   * @type {number}
   * @memberof CheckHelth404Response
   */
  statusCode: CheckHelth404ResponseStatusCodeEnum
}

export const CheckHelth404ResponseStatusCodeEnum = {
  NUMBER_404: 404,
} as const

export type CheckHelth404ResponseStatusCodeEnum =
  (typeof CheckHelth404ResponseStatusCodeEnum)[keyof typeof CheckHelth404ResponseStatusCodeEnum]

/**
 *
 * @export
 * @interface CheckHelth405Response
 */
export interface CheckHelth405Response {
  /**
   *
   * @type {string}
   * @memberof CheckHelth405Response
   */
  code: string
  /**
   *
   * @type {string}
   * @memberof CheckHelth405Response
   */
  message: string
  /**
   *
   * @type {any}
   * @memberof CheckHelth405Response
   */
  data?: any
  /**
   *
   * @type {number}
   * @memberof CheckHelth405Response
   */
  statusCode: CheckHelth405ResponseStatusCodeEnum
}

export const CheckHelth405ResponseStatusCodeEnum = {
  NUMBER_405: 405,
} as const

export type CheckHelth405ResponseStatusCodeEnum =
  (typeof CheckHelth405ResponseStatusCodeEnum)[keyof typeof CheckHelth405ResponseStatusCodeEnum]

/**
 *
 * @export
 * @interface CreateAiRecommendationModel201Response
 */
export interface CreateAiRecommendationModel201Response {
  /**
   *
   * @type {CreateAiRecommendationModel201ResponseItem}
   * @memberof CreateAiRecommendationModel201Response
   */
  item: CreateAiRecommendationModel201ResponseItem
}
/**
 *
 * @export
 * @interface CreateAiRecommendationModel201ResponseItem
 */
export interface CreateAiRecommendationModel201ResponseItem {
  /**
   * 購買データCSVのSHA256ハッシュ値
   * @type {string}
   * @memberof CreateAiRecommendationModel201ResponseItem
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof CreateAiRecommendationModel201ResponseItem
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof CreateAiRecommendationModel201ResponseItem
   */
  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof CreateAiRecommendationModel201ResponseItem
   */
  projectId: string
  /**
   * 診断数
   * @type {number}
   * @memberof CreateAiRecommendationModel201ResponseItem
   */
  diagnosisCount: number
  /**
   * 商品数
   * @type {number}
   * @memberof CreateAiRecommendationModel201ResponseItem
   */
  productCount: number
  /**
   * 購買データ数
   * @type {number}
   * @memberof CreateAiRecommendationModel201ResponseItem
   */
  purchaseCount: number
  /**
   * 有効データ数
   * @type {number}
   * @memberof CreateAiRecommendationModel201ResponseItem
   */
  validDataCount: number
  /**
   * 未登録の購入数TOP10の商品コード
   * @type {Array<FetchAiRecommendationModels200ResponseItemsInnerUnregisteredProductsInner>}
   * @memberof CreateAiRecommendationModel201ResponseItem
   */
  unregisteredProducts: Array<FetchAiRecommendationModels200ResponseItemsInnerUnregisteredProductsInner>
}
/**
 *
 * @export
 * @interface CreateAiRecommendationModelRequest
 */
export interface CreateAiRecommendationModelRequest {
  /**
   * 購買データCSVのSHA256ハッシュ値
   * @type {string}
   * @memberof CreateAiRecommendationModelRequest
   */
  id: string
}
/**
 *
 * @export
 * @interface CreateCounseling201Response
 */
export interface CreateCounseling201Response {
  /**
   *
   * @type {CreateCounseling201ResponseItem}
   * @memberof CreateCounseling201Response
   */
  item: CreateCounseling201ResponseItem
}
/**
 *
 * @export
 * @interface CreateCounseling201ResponseItem
 */
export interface CreateCounseling201ResponseItem {
  /**
   *
   * @type {string}
   * @memberof CreateCounseling201ResponseItem
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof CreateCounseling201ResponseItem
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof CreateCounseling201ResponseItem
   */
  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof CreateCounseling201ResponseItem
   */
  projectId: string
  /**
   *
   * @type {string}
   * @memberof CreateCounseling201ResponseItem
   */
  title: string
  /**
   *
   * @type {boolean}
   * @memberof CreateCounseling201ResponseItem
   */
  required?: boolean
  /**
   *
   * @type {string}
   * @memberof CreateCounseling201ResponseItem
   */
  fieldType: CreateCounseling201ResponseItemFieldTypeEnum
  /**
   *
   * @type {Array<string>}
   * @memberof CreateCounseling201ResponseItem
   */
  options?: Array<string> | null
}

export const CreateCounseling201ResponseItemFieldTypeEnum = {
  Text: 'text',
  Checkbox: 'checkbox',
  Radiobox: 'radiobox',
  Selectbox: 'selectbox',
} as const

export type CreateCounseling201ResponseItemFieldTypeEnum =
  (typeof CreateCounseling201ResponseItemFieldTypeEnum)[keyof typeof CreateCounseling201ResponseItemFieldTypeEnum]

/**
 *
 * @export
 * @interface CreateCounselingRequest
 */
export interface CreateCounselingRequest {
  /**
   *
   * @type {string}
   * @memberof CreateCounselingRequest
   */
  title: string
  /**
   *
   * @type {boolean}
   * @memberof CreateCounselingRequest
   */
  required?: boolean
  /**
   *
   * @type {string}
   * @memberof CreateCounselingRequest
   */
  fieldType: CreateCounselingRequestFieldTypeEnum
  /**
   *
   * @type {Array<string>}
   * @memberof CreateCounselingRequest
   */
  options?: Array<string> | null
}

export const CreateCounselingRequestFieldTypeEnum = {
  Text: 'text',
  Checkbox: 'checkbox',
  Radiobox: 'radiobox',
  Selectbox: 'selectbox',
} as const

export type CreateCounselingRequestFieldTypeEnum =
  (typeof CreateCounselingRequestFieldTypeEnum)[keyof typeof CreateCounselingRequestFieldTypeEnum]

/**
 *
 * @export
 * @interface CreateFeedback201Response
 */
export interface CreateFeedback201Response {
  /**
   *
   * @type {CreateFeedback201ResponseItem}
   * @memberof CreateFeedback201Response
   */
  item: CreateFeedback201ResponseItem
}
/**
 *
 * @export
 * @interface CreateFeedback201ResponseItem
 */
export interface CreateFeedback201ResponseItem {
  /**
   *
   * @type {string}
   * @memberof CreateFeedback201ResponseItem
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof CreateFeedback201ResponseItem
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof CreateFeedback201ResponseItem
   */
  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof CreateFeedback201ResponseItem
   */
  projectId: string
  /**
   *
   * @type {string}
   * @memberof CreateFeedback201ResponseItem
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof CreateFeedback201ResponseItem
   */
  layout: CreateFeedback201ResponseItemLayoutEnum
  /**
   *
   * @type {FetchFeedbacks200ResponseItemsInnerContent}
   * @memberof CreateFeedback201ResponseItem
   */
  content: FetchFeedbacks200ResponseItemsInnerContent
}

export const CreateFeedback201ResponseItemLayoutEnum = {
  Rich: 'rich',
  Comment: 'comment',
  Banner: 'banner',
} as const

export type CreateFeedback201ResponseItemLayoutEnum =
  (typeof CreateFeedback201ResponseItemLayoutEnum)[keyof typeof CreateFeedback201ResponseItemLayoutEnum]

/**
 *
 * @export
 * @interface CreateFeedbackRequest
 */
export interface CreateFeedbackRequest {
  /**
   *
   * @type {string}
   * @memberof CreateFeedbackRequest
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof CreateFeedbackRequest
   */
  layout: CreateFeedbackRequestLayoutEnum
  /**
   *
   * @type {FetchFeedbacks200ResponseItemsInnerContent}
   * @memberof CreateFeedbackRequest
   */
  content: FetchFeedbacks200ResponseItemsInnerContent
}

export const CreateFeedbackRequestLayoutEnum = {
  Rich: 'rich',
  Comment: 'comment',
  Banner: 'banner',
} as const

export type CreateFeedbackRequestLayoutEnum =
  (typeof CreateFeedbackRequestLayoutEnum)[keyof typeof CreateFeedbackRequestLayoutEnum]

/**
 *
 * @export
 * @interface CreateProduct201Response
 */
export interface CreateProduct201Response {
  /**
   *
   * @type {CreateProduct201ResponseItem}
   * @memberof CreateProduct201Response
   */
  item: CreateProduct201ResponseItem
}
/**
 *
 * @export
 * @interface CreateProduct201ResponseItem
 */
export interface CreateProduct201ResponseItem {
  /**
   *
   * @type {string}
   * @memberof CreateProduct201ResponseItem
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof CreateProduct201ResponseItem
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof CreateProduct201ResponseItem
   */
  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof CreateProduct201ResponseItem
   */
  title: string
  /**
   *
   * @type {number}
   * @memberof CreateProduct201ResponseItem
   */
  price: number
  /**
   *
   * @type {string}
   * @memberof CreateProduct201ResponseItem
   */
  projectId: string
  /**
   *
   * @type {string}
   * @memberof CreateProduct201ResponseItem
   */
  code?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateProduct201ResponseItem
   */
  thumbnailUrl?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateProduct201ResponseItem
   */
  brand?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateProduct201ResponseItem
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateProduct201ResponseItem
   */
  link?: string | null
  /**
   *
   * @type {Array<FetchProducts200ResponseItemsInnerCategoriesInner>}
   * @memberof CreateProduct201ResponseItem
   */
  categories?: Array<FetchProducts200ResponseItemsInnerCategoriesInner>
  /**
   *
   * @type {Array<string>}
   * @memberof CreateProduct201ResponseItem
   */
  tags?: Array<string>
}
/**
 *
 * @export
 * @interface CreateProductCategory201Response
 */
export interface CreateProductCategory201Response {
  /**
   *
   * @type {FetchProducts200ResponseItemsInnerCategoriesInner}
   * @memberof CreateProductCategory201Response
   */
  item: FetchProducts200ResponseItemsInnerCategoriesInner
}
/**
 *
 * @export
 * @interface CreateProductCategoryRequest
 */
export interface CreateProductCategoryRequest {
  /**
   *
   * @type {string}
   * @memberof CreateProductCategoryRequest
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof CreateProductCategoryRequest
   */
  priority: number
}
/**
 *
 * @export
 * @interface CreateProducts201Response
 */
export interface CreateProducts201Response {
  /**
   *
   * @type {number}
   * @memberof CreateProducts201Response
   */
  count: number
}
/**
 *
 * @export
 * @interface CreateProductsRequest
 */
export interface CreateProductsRequest {
  /**
   *
   * @type {Array<CreateProductsRequestItemsInner>}
   * @memberof CreateProductsRequest
   */
  items: Array<CreateProductsRequestItemsInner>
}
/**
 *
 * @export
 * @interface CreateProductsRequestItemsInner
 */
export interface CreateProductsRequestItemsInner {
  /**
   *
   * @type {string}
   * @memberof CreateProductsRequestItemsInner
   */
  title: string
  /**
   *
   * @type {number}
   * @memberof CreateProductsRequestItemsInner
   */
  price: number
  /**
   *
   * @type {string}
   * @memberof CreateProductsRequestItemsInner
   */
  code?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateProductsRequestItemsInner
   */
  brand?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateProductsRequestItemsInner
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateProductsRequestItemsInner
   */
  link?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof CreateProductsRequestItemsInner
   */
  tags?: Array<string>
  /**
   *
   * @type {string}
   * @memberof CreateProductsRequestItemsInner
   */
  thumbnailData?: string | null
  /**
   *
   * @type {any}
   * @memberof CreateProductsRequestItemsInner
   */
  categories?: any | null
}
/**
 *
 * @export
 * @interface CreateProject201Response
 */
export interface CreateProject201Response {
  /**
   *
   * @type {FetchProjects200ResponseItemsInner}
   * @memberof CreateProject201Response
   */
  item: FetchProjects200ResponseItemsInner
}
/**
 *
 * @export
 * @interface CreateProjectRequest
 */
export interface CreateProjectRequest {
  /**
   *
   * @type {string}
   * @memberof CreateProjectRequest
   */
  title: string
  /**
   *
   * @type {FetchProjects200ResponseItemsInnerEnabledFeatures}
   * @memberof CreateProjectRequest
   */
  enabledFeatures?: FetchProjects200ResponseItemsInnerEnabledFeatures
  /**
   *
   * @type {string}
   * @memberof CreateProjectRequest
   */
  note?: string | null
  /**
   *
   * @type {string}
   * @memberof CreateProjectRequest
   */
  id?: string
  /**
   *
   * @type {Array<string>}
   * @memberof CreateProjectRequest
   */
  providers: Array<string>
}
/**
 *
 * @export
 * @interface CreateProvider201Response
 */
export interface CreateProvider201Response {
  /**
   *
   * @type {CreateProvider201ResponseItem}
   * @memberof CreateProvider201Response
   */
  item: CreateProvider201ResponseItem
}
/**
 *
 * @export
 * @interface CreateProvider201ResponseItem
 */
export interface CreateProvider201ResponseItem {
  /**
   *
   * @type {string}
   * @memberof CreateProvider201ResponseItem
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof CreateProvider201ResponseItem
   */
  displayName: string
  /**
   *
   * @type {string}
   * @memberof CreateProvider201ResponseItem
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof CreateProvider201ResponseItem
   */
  createdAt: string
  /**
   *
   * @type {Array<CreateProvider201ResponseItemProjectsInner>}
   * @memberof CreateProvider201ResponseItem
   */
  projects?: Array<CreateProvider201ResponseItemProjectsInner>
}
/**
 *
 * @export
 * @interface CreateProvider201ResponseItemProjectsInner
 */
export interface CreateProvider201ResponseItemProjectsInner {
  /**
   *
   * @type {string}
   * @memberof CreateProvider201ResponseItemProjectsInner
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof CreateProvider201ResponseItemProjectsInner
   */
  title: string
}
/**
 *
 * @export
 * @interface CreateProviderRequest
 */
export interface CreateProviderRequest {
  /**
   *
   * @type {string}
   * @memberof CreateProviderRequest
   */
  displayName: string
  /**
   *
   * @type {string}
   * @memberof CreateProviderRequest
   */
  email: string
  /**
   *
   * @type {Array<string>}
   * @memberof CreateProviderRequest
   */
  projects?: Array<string>
}
/**
 *
 * @export
 * @interface CreateRelease201Response
 */
export interface CreateRelease201Response {
  /**
   *
   * @type {FetchReleases200ResponseItemsInner}
   * @memberof CreateRelease201Response
   */
  item: FetchReleases200ResponseItemsInner
}
/**
 *
 * @export
 * @interface CreateReleaseRequest
 */
export interface CreateReleaseRequest {
  /**
   *
   * @type {string}
   * @memberof CreateReleaseRequest
   */
  title: string
  /**
   *
   * @type {boolean}
   * @memberof CreateReleaseRequest
   */
  activated: boolean
  /**
   *
   * @type {Array<CreateReleaseRequestRulesInner>}
   * @memberof CreateReleaseRequest
   */
  rules: Array<CreateReleaseRequestRulesInner>
  /**
   *
   * @type {Array<CreateReleaseRequestRulesInner>}
   * @memberof CreateReleaseRequest
   */
  counselings: Array<CreateReleaseRequestRulesInner>
  /**
   * 購買データCSVのSHA256ハッシュ値
   * @type {string}
   * @memberof CreateReleaseRequest
   */
  aiModelId?: string | null
}
/**
 *
 * @export
 * @interface CreateReleaseRequestRulesInner
 */
export interface CreateReleaseRequestRulesInner {
  /**
   *
   * @type {string}
   * @memberof CreateReleaseRequestRulesInner
   */
  id: string
  /**
   *
   * @type {number}
   * @memberof CreateReleaseRequestRulesInner
   */
  order: number
}
/**
 *
 * @export
 * @interface CreateRule201Response
 */
export interface CreateRule201Response {
  /**
   *
   * @type {CreateRule201ResponseItem}
   * @memberof CreateRule201Response
   */
  item: CreateRule201ResponseItem
}
/**
 *
 * @export
 * @interface CreateRule201ResponseItem
 */
export interface CreateRule201ResponseItem {
  /**
   *
   * @type {string}
   * @memberof CreateRule201ResponseItem
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof CreateRule201ResponseItem
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof CreateRule201ResponseItem
   */
  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof CreateRule201ResponseItem
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof CreateRule201ResponseItem
   */
  ruleLogicalOperator: CreateRule201ResponseItemRuleLogicalOperatorEnum
  /**
   *
   * @type {Array<FetchRules200ResponseItemsInnerConditionsInner>}
   * @memberof CreateRule201ResponseItem
   */
  conditions: Array<FetchRules200ResponseItemsInnerConditionsInner>
  /**
   *
   * @type {Array<FetchRules200ResponseItemsInnerRewardProductsInner>}
   * @memberof CreateRule201ResponseItem
   */
  rewardProducts: Array<FetchRules200ResponseItemsInnerRewardProductsInner>
  /**
   *
   * @type {Array<FetchRules200ResponseItemsInnerRewardProductsInner>}
   * @memberof CreateRule201ResponseItem
   */
  rewardFeedbacks: Array<FetchRules200ResponseItemsInnerRewardProductsInner>
}

export const CreateRule201ResponseItemRuleLogicalOperatorEnum = {
  And: 'and',
  Or: 'or',
} as const

export type CreateRule201ResponseItemRuleLogicalOperatorEnum =
  (typeof CreateRule201ResponseItemRuleLogicalOperatorEnum)[keyof typeof CreateRule201ResponseItemRuleLogicalOperatorEnum]

/**
 *
 * @export
 * @interface CreateRuleRequest
 */
export interface CreateRuleRequest {
  /**
   *
   * @type {string}
   * @memberof CreateRuleRequest
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof CreateRuleRequest
   */
  ruleLogicalOperator: CreateRuleRequestRuleLogicalOperatorEnum
  /**
   *
   * @type {Array<FetchRules200ResponseItemsInnerConditionsInner>}
   * @memberof CreateRuleRequest
   */
  conditions: Array<FetchRules200ResponseItemsInnerConditionsInner>
  /**
   *
   * @type {Array<FetchRules200ResponseItemsInnerRewardProductsInner>}
   * @memberof CreateRuleRequest
   */
  rewardProducts: Array<FetchRules200ResponseItemsInnerRewardProductsInner>
  /**
   *
   * @type {Array<FetchRules200ResponseItemsInnerRewardProductsInner>}
   * @memberof CreateRuleRequest
   */
  rewardFeedbacks: Array<FetchRules200ResponseItemsInnerRewardProductsInner>
}

export const CreateRuleRequestRuleLogicalOperatorEnum = {
  And: 'and',
  Or: 'or',
} as const

export type CreateRuleRequestRuleLogicalOperatorEnum =
  (typeof CreateRuleRequestRuleLogicalOperatorEnum)[keyof typeof CreateRuleRequestRuleLogicalOperatorEnum]

/**
 *
 * @export
 * @interface CreateStripeCustomerRequest
 */
export interface CreateStripeCustomerRequest {
  /**
   *
   * @type {string}
   * @memberof CreateStripeCustomerRequest
   */
  redirectUrl: string
}
/**
 *
 * @export
 * @interface CreateUserCustomAttribute201Response
 */
export interface CreateUserCustomAttribute201Response {
  /**
   *
   * @type {FetchUserCustomAttributes200ResponseItemsInner}
   * @memberof CreateUserCustomAttribute201Response
   */
  item: FetchUserCustomAttributes200ResponseItemsInner
}
/**
 *
 * @export
 * @interface CreateUserCustomAttributeRequest
 */
export interface CreateUserCustomAttributeRequest {
  /**
   *
   * @type {string}
   * @memberof CreateUserCustomAttributeRequest
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof CreateUserCustomAttributeRequest
   */
  key: string
  /**
   *
   * @type {string}
   * @memberof CreateUserCustomAttributeRequest
   */
  type: CreateUserCustomAttributeRequestTypeEnum
}

export const CreateUserCustomAttributeRequestTypeEnum = {
  String: 'String',
  Number: 'Number',
  Boolean: 'Boolean',
  Date: 'Date',
} as const

export type CreateUserCustomAttributeRequestTypeEnum =
  (typeof CreateUserCustomAttributeRequestTypeEnum)[keyof typeof CreateUserCustomAttributeRequestTypeEnum]

/**
 *
 * @export
 * @interface DetectSkin201Response
 */
export interface DetectSkin201Response {
  /**
   *
   * @type {FindDiagnosisUserById200ResponseDiagnosesItemsInner}
   * @memberof DetectSkin201Response
   */
  item: FindDiagnosisUserById200ResponseDiagnosesItemsInner
  /**
   *
   * @type {DetectSkinWithReleaseDebug200Response}
   * @memberof DetectSkin201Response
   */
  rewards: DetectSkinWithReleaseDebug200Response
}
/**
 *
 * @export
 * @interface DetectSkinRequest
 */
export interface DetectSkinRequest {
  /**
   *
   * @type {string}
   * @memberof DetectSkinRequest
   */
  trimmingPict: string
  /**
   *
   * @type {string}
   * @memberof DetectSkinRequest
   */
  originPict?: string
  /**
   *
   * @type {{ [key: string]: DetectSkinRequestAdditionalPictsValue; }}
   * @memberof DetectSkinRequest
   */
  additionalPicts?: { [key: string]: DetectSkinRequestAdditionalPictsValue }
  /**
   *
   * @type {{ [key: string]: FindDiagnosisUserById200ResponseDiagnosesItemsInnerCounselingValue; }}
   * @memberof DetectSkinRequest
   */
  counseling?: {
    [
      key: string
    ]: FindDiagnosisUserById200ResponseDiagnosesItemsInnerCounselingValue
  }
}
/**
 *
 * @export
 * @interface DetectSkinRequestAdditionalPictsValue
 */
export interface DetectSkinRequestAdditionalPictsValue {}
/**
 *
 * @export
 * @interface DetectSkinWithReleaseDebug200Response
 */
export interface DetectSkinWithReleaseDebug200Response {
  /**
   *
   * @type {Array<DetectSkinWithReleaseDebug200ResponseProductsInner>}
   * @memberof DetectSkinWithReleaseDebug200Response
   */
  products: Array<DetectSkinWithReleaseDebug200ResponseProductsInner>
  /**
   *
   * @type {Array<FetchProducts200ResponseItemsInnerCategoriesInner>}
   * @memberof DetectSkinWithReleaseDebug200Response
   */
  productCategories: Array<FetchProducts200ResponseItemsInnerCategoriesInner>
  /**
   *
   * @type {Array<CreateFeedback201ResponseItem>}
   * @memberof DetectSkinWithReleaseDebug200Response
   */
  feedbacks: Array<CreateFeedback201ResponseItem>
}
/**
 *
 * @export
 * @interface DetectSkinWithReleaseDebug200ResponseProductsInner
 */
export interface DetectSkinWithReleaseDebug200ResponseProductsInner {
  /**
   *
   * @type {string}
   * @memberof DetectSkinWithReleaseDebug200ResponseProductsInner
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof DetectSkinWithReleaseDebug200ResponseProductsInner
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof DetectSkinWithReleaseDebug200ResponseProductsInner
   */
  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof DetectSkinWithReleaseDebug200ResponseProductsInner
   */
  title: string
  /**
   *
   * @type {number}
   * @memberof DetectSkinWithReleaseDebug200ResponseProductsInner
   */
  price: number
  /**
   *
   * @type {string}
   * @memberof DetectSkinWithReleaseDebug200ResponseProductsInner
   */
  projectId: string
  /**
   *
   * @type {string}
   * @memberof DetectSkinWithReleaseDebug200ResponseProductsInner
   */
  code?: string | null
  /**
   *
   * @type {string}
   * @memberof DetectSkinWithReleaseDebug200ResponseProductsInner
   */
  thumbnailUrl?: string | null
  /**
   *
   * @type {string}
   * @memberof DetectSkinWithReleaseDebug200ResponseProductsInner
   */
  brand?: string | null
  /**
   *
   * @type {string}
   * @memberof DetectSkinWithReleaseDebug200ResponseProductsInner
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof DetectSkinWithReleaseDebug200ResponseProductsInner
   */
  link?: string | null
  /**
   *
   * @type {any}
   * @memberof DetectSkinWithReleaseDebug200ResponseProductsInner
   */
  categories?: any
  /**
   *
   * @type {Array<string>}
   * @memberof DetectSkinWithReleaseDebug200ResponseProductsInner
   */
  tags?: Array<string>
}
/**
 *
 * @export
 * @interface DetectSkinWithReleaseDebugRequest
 */
export interface DetectSkinWithReleaseDebugRequest {
  /**
   *
   * @type {DetectSkinWithReleaseDebugRequestScores}
   * @memberof DetectSkinWithReleaseDebugRequest
   */
  scores: DetectSkinWithReleaseDebugRequestScores
  /**
   *
   * @type {string}
   * @memberof DetectSkinWithReleaseDebugRequest
   */
  birthDate: string
  /**
   *
   * @type {string}
   * @memberof DetectSkinWithReleaseDebugRequest
   */
  gender: DetectSkinWithReleaseDebugRequestGenderEnum
  /**
   *
   * @type {string}
   * @memberof DetectSkinWithReleaseDebugRequest
   */
  skinType: DetectSkinWithReleaseDebugRequestSkinTypeEnum
  /**
   *
   * @type {number}
   * @memberof DetectSkinWithReleaseDebugRequest
   */
  skinAge: number
  /**
   *
   * @type {{ [key: string]: FindDiagnosisUserById200ResponseDiagnosesItemsInnerCounselingValue; }}
   * @memberof DetectSkinWithReleaseDebugRequest
   */
  counselings?: {
    [
      key: string
    ]: FindDiagnosisUserById200ResponseDiagnosesItemsInnerCounselingValue
  }
  /**
   *
   * @type {string}
   * @memberof DetectSkinWithReleaseDebugRequest
   */
  connectId?: string
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof DetectSkinWithReleaseDebugRequest
   */
  dynamicParams?: { [key: string]: any }
  /**
   *
   * @type {boolean}
   * @memberof DetectSkinWithReleaseDebugRequest
   */
  useUserCustomAttributeApi?: boolean
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof DetectSkinWithReleaseDebugRequest
   */
  userCustomAttributes?: { [key: string]: any }
  /**
   * 購買データCSVのSHA256ハッシュ値
   * @type {string}
   * @memberof DetectSkinWithReleaseDebugRequest
   */
  aiModelId?: string | null
}

export const DetectSkinWithReleaseDebugRequestGenderEnum = {
  Female: 'female',
  Male: 'male',
  Others: 'others',
} as const

export type DetectSkinWithReleaseDebugRequestGenderEnum =
  (typeof DetectSkinWithReleaseDebugRequestGenderEnum)[keyof typeof DetectSkinWithReleaseDebugRequestGenderEnum]
export const DetectSkinWithReleaseDebugRequestSkinTypeEnum = {
  Dry: 'dry',
  Oily: 'oily',
  Mix: 'mix',
  Sensitive: 'sensitive',
  Normal: 'normal',
} as const

export type DetectSkinWithReleaseDebugRequestSkinTypeEnum =
  (typeof DetectSkinWithReleaseDebugRequestSkinTypeEnum)[keyof typeof DetectSkinWithReleaseDebugRequestSkinTypeEnum]

/**
 *
 * @export
 * @interface DetectSkinWithReleaseDebugRequestScores
 */
export interface DetectSkinWithReleaseDebugRequestScores {
  /**
   *
   * @type {number}
   * @memberof DetectSkinWithReleaseDebugRequestScores
   */
  wrinkle: number
  /**
   *
   * @type {number}
   * @memberof DetectSkinWithReleaseDebugRequestScores
   */
  stain: number
  /**
   *
   * @type {number}
   * @memberof DetectSkinWithReleaseDebugRequestScores
   */
  texture: number
  /**
   *
   * @type {number}
   * @memberof DetectSkinWithReleaseDebugRequestScores
   */
  pores: number
  /**
   *
   * @type {number}
   * @memberof DetectSkinWithReleaseDebugRequestScores
   */
  moisture: number
  /**
   *
   * @type {number}
   * @memberof DetectSkinWithReleaseDebugRequestScores
   */
  clarity: number
}
/**
 *
 * @export
 * @interface FetchAiRecommendationModels200Response
 */
export interface FetchAiRecommendationModels200Response {
  /**
   *
   * @type {Array<FetchAiRecommendationModels200ResponseItemsInner>}
   * @memberof FetchAiRecommendationModels200Response
   */
  items: Array<FetchAiRecommendationModels200ResponseItemsInner>
  /**
   *
   * @type {number}
   * @memberof FetchAiRecommendationModels200Response
   */
  total: number
}
/**
 *
 * @export
 * @interface FetchAiRecommendationModels200ResponseItemsInner
 */
export interface FetchAiRecommendationModels200ResponseItemsInner {
  /**
   * 購買データCSVのSHA256ハッシュ値
   * @type {string}
   * @memberof FetchAiRecommendationModels200ResponseItemsInner
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof FetchAiRecommendationModels200ResponseItemsInner
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof FetchAiRecommendationModels200ResponseItemsInner
   */
  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof FetchAiRecommendationModels200ResponseItemsInner
   */
  projectId: string
  /**
   * 診断数
   * @type {number}
   * @memberof FetchAiRecommendationModels200ResponseItemsInner
   */
  diagnosisCount: number
  /**
   * 商品数
   * @type {number}
   * @memberof FetchAiRecommendationModels200ResponseItemsInner
   */
  productCount: number
  /**
   * 購買データ数
   * @type {number}
   * @memberof FetchAiRecommendationModels200ResponseItemsInner
   */
  purchaseCount: number
  /**
   * 有効データ数
   * @type {number}
   * @memberof FetchAiRecommendationModels200ResponseItemsInner
   */
  validDataCount: number
  /**
   * 未登録の購入数TOP10の商品コード
   * @type {Array<FetchAiRecommendationModels200ResponseItemsInnerUnregisteredProductsInner>}
   * @memberof FetchAiRecommendationModels200ResponseItemsInner
   */
  unregisteredProducts: Array<FetchAiRecommendationModels200ResponseItemsInnerUnregisteredProductsInner>
  /**
   *
   * @type {boolean}
   * @memberof FetchAiRecommendationModels200ResponseItemsInner
   */
  connectedActiveRelease: boolean
}
/**
 *
 * @export
 * @interface FetchAiRecommendationModels200ResponseItemsInnerUnregisteredProductsInner
 */
export interface FetchAiRecommendationModels200ResponseItemsInnerUnregisteredProductsInner {
  /**
   * 商品コード
   * @type {string}
   * @memberof FetchAiRecommendationModels200ResponseItemsInnerUnregisteredProductsInner
   */
  code: string
  /**
   * 購入数
   * @type {number}
   * @memberof FetchAiRecommendationModels200ResponseItemsInnerUnregisteredProductsInner
   */
  count: number
}
/**
 *
 * @export
 * @interface FetchCounselings200Response
 */
export interface FetchCounselings200Response {
  /**
   *
   * @type {number}
   * @memberof FetchCounselings200Response
   */
  limit?: number
  /**
   *
   * @type {number}
   * @memberof FetchCounselings200Response
   */
  offset?: number
  /**
   *
   * @type {Array<FetchCounselings200ResponseItemsInner>}
   * @memberof FetchCounselings200Response
   */
  items: Array<FetchCounselings200ResponseItemsInner>
  /**
   *
   * @type {number}
   * @memberof FetchCounselings200Response
   */
  total: number
}
/**
 *
 * @export
 * @interface FetchCounselings200ResponseItemsInner
 */
export interface FetchCounselings200ResponseItemsInner {
  /**
   *
   * @type {string}
   * @memberof FetchCounselings200ResponseItemsInner
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof FetchCounselings200ResponseItemsInner
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof FetchCounselings200ResponseItemsInner
   */
  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof FetchCounselings200ResponseItemsInner
   */
  projectId: string
  /**
   *
   * @type {string}
   * @memberof FetchCounselings200ResponseItemsInner
   */
  title: string
  /**
   *
   * @type {boolean}
   * @memberof FetchCounselings200ResponseItemsInner
   */
  required?: boolean
  /**
   *
   * @type {string}
   * @memberof FetchCounselings200ResponseItemsInner
   */
  fieldType: FetchCounselings200ResponseItemsInnerFieldTypeEnum
  /**
   *
   * @type {Array<string>}
   * @memberof FetchCounselings200ResponseItemsInner
   */
  options?: Array<string> | null
  /**
   *
   * @type {boolean}
   * @memberof FetchCounselings200ResponseItemsInner
   */
  isRewardedReleases: boolean
}

export const FetchCounselings200ResponseItemsInnerFieldTypeEnum = {
  Text: 'text',
  Checkbox: 'checkbox',
  Radiobox: 'radiobox',
  Selectbox: 'selectbox',
} as const

export type FetchCounselings200ResponseItemsInnerFieldTypeEnum =
  (typeof FetchCounselings200ResponseItemsInnerFieldTypeEnum)[keyof typeof FetchCounselings200ResponseItemsInnerFieldTypeEnum]

/**
 *
 * @export
 * @interface FetchDashboard200Response
 */
export interface FetchDashboard200Response {
  /**
   *
   * @type {FetchDashboard200ResponseOverview}
   * @memberof FetchDashboard200Response
   */
  overview: FetchDashboard200ResponseOverview
}
/**
 *
 * @export
 * @interface FetchDashboard200ResponseOverview
 */
export interface FetchDashboard200ResponseOverview {
  /**
   *
   * @type {FetchDashboard200ResponseOverviewUser}
   * @memberof FetchDashboard200ResponseOverview
   */
  user: FetchDashboard200ResponseOverviewUser
  /**
   *
   * @type {FetchDashboard200ResponseOverviewProduct}
   * @memberof FetchDashboard200ResponseOverview
   */
  product: FetchDashboard200ResponseOverviewProduct
  /**
   *
   * @type {FetchDashboard200ResponseOverviewProduct}
   * @memberof FetchDashboard200ResponseOverview
   */
  feedback: FetchDashboard200ResponseOverviewProduct
  /**
   *
   * @type {FetchDashboard200ResponseOverviewProduct}
   * @memberof FetchDashboard200ResponseOverview
   */
  counseling: FetchDashboard200ResponseOverviewProduct
  /**
   *
   * @type {FetchDashboard200ResponseOverviewProduct}
   * @memberof FetchDashboard200ResponseOverview
   */
  rule: FetchDashboard200ResponseOverviewProduct
  /**
   *
   * @type {FetchDashboard200ResponseOverviewRelease}
   * @memberof FetchDashboard200ResponseOverview
   */
  release: FetchDashboard200ResponseOverviewRelease
}
/**
 *
 * @export
 * @interface FetchDashboard200ResponseOverviewProduct
 */
export interface FetchDashboard200ResponseOverviewProduct {
  /**
   *
   * @type {number}
   * @memberof FetchDashboard200ResponseOverviewProduct
   */
  count: number
}
/**
 *
 * @export
 * @interface FetchDashboard200ResponseOverviewRelease
 */
export interface FetchDashboard200ResponseOverviewRelease {
  /**
   *
   * @type {number}
   * @memberof FetchDashboard200ResponseOverviewRelease
   */
  count: number
  /**
   *
   * @type {boolean}
   * @memberof FetchDashboard200ResponseOverviewRelease
   */
  activated: boolean
}
/**
 *
 * @export
 * @interface FetchDashboard200ResponseOverviewUser
 */
export interface FetchDashboard200ResponseOverviewUser {
  /**
   *
   * @type {number}
   * @memberof FetchDashboard200ResponseOverviewUser
   */
  count: number
  /**
   *
   * @type {number}
   * @memberof FetchDashboard200ResponseOverviewUser
   */
  unique: number
}
/**
 *
 * @export
 * @interface FetchDiagnoses200Response
 */
export interface FetchDiagnoses200Response {
  /**
   *
   * @type {Array<FindDiagnosisUserById200ResponseDiagnosesItemsInner>}
   * @memberof FetchDiagnoses200Response
   */
  items: Array<FindDiagnosisUserById200ResponseDiagnosesItemsInner>
}
/**
 *
 * @export
 * @interface FetchDiagnosisUsers200Response
 */
export interface FetchDiagnosisUsers200Response {
  /**
   *
   * @type {Array<FetchDiagnosisUsers200ResponseItemsInner>}
   * @memberof FetchDiagnosisUsers200Response
   */
  items: Array<FetchDiagnosisUsers200ResponseItemsInner>
  /**
   *
   * @type {string}
   * @memberof FetchDiagnosisUsers200Response
   */
  nextKey?: string
}
/**
 *
 * @export
 * @interface FetchDiagnosisUsers200ResponseItemsInner
 */
export interface FetchDiagnosisUsers200ResponseItemsInner {
  /**
   *
   * @type {string}
   * @memberof FetchDiagnosisUsers200ResponseItemsInner
   */
  connectStatus?: FetchDiagnosisUsers200ResponseItemsInnerConnectStatusEnum
  /**
   *
   * @type {string}
   * @memberof FetchDiagnosisUsers200ResponseItemsInner
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof FetchDiagnosisUsers200ResponseItemsInner
   */
  birthDate?: string
  /**
   *
   * @type {string}
   * @memberof FetchDiagnosisUsers200ResponseItemsInner
   */
  lastDiagnosedAt?: string
  /**
   *
   * @type {string}
   * @memberof FetchDiagnosisUsers200ResponseItemsInner
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof FetchDiagnosisUsers200ResponseItemsInner
   */
  displayName?: string
  /**
   *
   * @type {string}
   * @memberof FetchDiagnosisUsers200ResponseItemsInner
   */
  gender?: string
  /**
   *
   * @type {string}
   * @memberof FetchDiagnosisUsers200ResponseItemsInner
   */
  thumbnailUrl?: string | null
  /**
   *
   * @type {string}
   * @memberof FetchDiagnosisUsers200ResponseItemsInner
   */
  connectId?: string
}

export const FetchDiagnosisUsers200ResponseItemsInnerConnectStatusEnum = {
  Connected: 'Connected',
  Reject: 'Reject',
  Request: 'Request',
  Resolve: 'Resolve',
  None: 'None',
} as const

export type FetchDiagnosisUsers200ResponseItemsInnerConnectStatusEnum =
  (typeof FetchDiagnosisUsers200ResponseItemsInnerConnectStatusEnum)[keyof typeof FetchDiagnosisUsers200ResponseItemsInnerConnectStatusEnum]

/**
 *
 * @export
 * @interface FetchFeedbacks200Response
 */
export interface FetchFeedbacks200Response {
  /**
   *
   * @type {Array<FetchFeedbacks200ResponseItemsInner>}
   * @memberof FetchFeedbacks200Response
   */
  items: Array<FetchFeedbacks200ResponseItemsInner>
  /**
   *
   * @type {number}
   * @memberof FetchFeedbacks200Response
   */
  total: number
}
/**
 *
 * @export
 * @interface FetchFeedbacks200ResponseItemsInner
 */
export interface FetchFeedbacks200ResponseItemsInner {
  /**
   *
   * @type {string}
   * @memberof FetchFeedbacks200ResponseItemsInner
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof FetchFeedbacks200ResponseItemsInner
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof FetchFeedbacks200ResponseItemsInner
   */
  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof FetchFeedbacks200ResponseItemsInner
   */
  projectId: string
  /**
   *
   * @type {string}
   * @memberof FetchFeedbacks200ResponseItemsInner
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof FetchFeedbacks200ResponseItemsInner
   */
  layout: FetchFeedbacks200ResponseItemsInnerLayoutEnum
  /**
   *
   * @type {FetchFeedbacks200ResponseItemsInnerContent}
   * @memberof FetchFeedbacks200ResponseItemsInner
   */
  content: FetchFeedbacks200ResponseItemsInnerContent
  /**
   *
   * @type {boolean}
   * @memberof FetchFeedbacks200ResponseItemsInner
   */
  isRewardedRules: boolean
}

export const FetchFeedbacks200ResponseItemsInnerLayoutEnum = {
  Rich: 'rich',
  Comment: 'comment',
  Banner: 'banner',
} as const

export type FetchFeedbacks200ResponseItemsInnerLayoutEnum =
  (typeof FetchFeedbacks200ResponseItemsInnerLayoutEnum)[keyof typeof FetchFeedbacks200ResponseItemsInnerLayoutEnum]

/**
 *
 * @export
 * @interface FetchFeedbacks200ResponseItemsInnerContent
 */
export interface FetchFeedbacks200ResponseItemsInnerContent {
  /**
   *
   * @type {string}
   * @memberof FetchFeedbacks200ResponseItemsInnerContent
   */
  banner?: string | null
  /**
   *
   * @type {FetchFeedbacks200ResponseItemsInnerContentLink}
   * @memberof FetchFeedbacks200ResponseItemsInnerContent
   */
  link?: FetchFeedbacks200ResponseItemsInnerContentLink | null
  /**
   *
   * @type {string}
   * @memberof FetchFeedbacks200ResponseItemsInnerContent
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof FetchFeedbacks200ResponseItemsInnerContent
   */
  comment?: string
  /**
   *
   * @type {string}
   * @memberof FetchFeedbacks200ResponseItemsInnerContent
   */
  thumbnail?: string | null
  /**
   *
   * @type {string}
   * @memberof FetchFeedbacks200ResponseItemsInnerContent
   */
  thumbMainTitle?: string
  /**
   *
   * @type {string}
   * @memberof FetchFeedbacks200ResponseItemsInnerContent
   */
  thumbSubTitle?: string
}
/**
 *
 * @export
 * @interface FetchFeedbacks200ResponseItemsInnerContentLink
 */
export interface FetchFeedbacks200ResponseItemsInnerContentLink {}
/**
 *
 * @export
 * @enum {string}
 */

export const FetchFeedbacks200ResponseItemsInnerContentLinkAnyOf = {
  Empty: '',
} as const

export type FetchFeedbacks200ResponseItemsInnerContentLinkAnyOf =
  (typeof FetchFeedbacks200ResponseItemsInnerContentLinkAnyOf)[keyof typeof FetchFeedbacks200ResponseItemsInnerContentLinkAnyOf]

/**
 *
 * @export
 * @interface FetchHistories200Response
 */
export interface FetchHistories200Response {
  /**
   *
   * @type {Array<number>}
   * @memberof FetchHistories200Response
   */
  items?: Array<number>
  /**
   *
   * @type {string}
   * @memberof FetchHistories200Response
   */
  nextToken?: string
}
/**
 *
 * @export
 * @interface FetchProductCategories200Response
 */
export interface FetchProductCategories200Response {
  /**
   *
   * @type {Array<FetchProductCategories200ResponseItemsInner>}
   * @memberof FetchProductCategories200Response
   */
  items: Array<FetchProductCategories200ResponseItemsInner>
  /**
   *
   * @type {number}
   * @memberof FetchProductCategories200Response
   */
  total: number
}
/**
 *
 * @export
 * @interface FetchProductCategories200ResponseItemsInner
 */
export interface FetchProductCategories200ResponseItemsInner {
  /**
   *
   * @type {string}
   * @memberof FetchProductCategories200ResponseItemsInner
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof FetchProductCategories200ResponseItemsInner
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof FetchProductCategories200ResponseItemsInner
   */
  priority: number
  /**
   *
   * @type {string}
   * @memberof FetchProductCategories200ResponseItemsInner
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof FetchProductCategories200ResponseItemsInner
   */
  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof FetchProductCategories200ResponseItemsInner
   */
  projectId: string
  /**
   *
   * @type {number}
   * @memberof FetchProductCategories200ResponseItemsInner
   */
  productsCount: number
}
/**
 *
 * @export
 * @interface FetchProductTags200Response
 */
export interface FetchProductTags200Response {
  /**
   *
   * @type {Array<string>}
   * @memberof FetchProductTags200Response
   */
  tags: Array<string>
}
/**
 *
 * @export
 * @interface FetchProducts200Response
 */
export interface FetchProducts200Response {
  /**
   *
   * @type {Array<FetchProducts200ResponseItemsInner>}
   * @memberof FetchProducts200Response
   */
  items: Array<FetchProducts200ResponseItemsInner>
  /**
   *
   * @type {number}
   * @memberof FetchProducts200Response
   */
  total: number
}
/**
 *
 * @export
 * @interface FetchProducts200ResponseItemsInner
 */
export interface FetchProducts200ResponseItemsInner {
  /**
   *
   * @type {string}
   * @memberof FetchProducts200ResponseItemsInner
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof FetchProducts200ResponseItemsInner
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof FetchProducts200ResponseItemsInner
   */
  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof FetchProducts200ResponseItemsInner
   */
  title: string
  /**
   *
   * @type {number}
   * @memberof FetchProducts200ResponseItemsInner
   */
  price: number
  /**
   *
   * @type {string}
   * @memberof FetchProducts200ResponseItemsInner
   */
  projectId: string
  /**
   *
   * @type {string}
   * @memberof FetchProducts200ResponseItemsInner
   */
  code?: string | null
  /**
   *
   * @type {string}
   * @memberof FetchProducts200ResponseItemsInner
   */
  thumbnailUrl?: string | null
  /**
   *
   * @type {string}
   * @memberof FetchProducts200ResponseItemsInner
   */
  brand?: string | null
  /**
   *
   * @type {string}
   * @memberof FetchProducts200ResponseItemsInner
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof FetchProducts200ResponseItemsInner
   */
  link?: string | null
  /**
   *
   * @type {Array<FetchProducts200ResponseItemsInnerCategoriesInner>}
   * @memberof FetchProducts200ResponseItemsInner
   */
  categories?: Array<FetchProducts200ResponseItemsInnerCategoriesInner>
  /**
   *
   * @type {Array<string>}
   * @memberof FetchProducts200ResponseItemsInner
   */
  tags?: Array<string>
  /**
   *
   * @type {boolean}
   * @memberof FetchProducts200ResponseItemsInner
   */
  isRewardedRules: boolean
}
/**
 *
 * @export
 * @interface FetchProducts200ResponseItemsInnerCategoriesInner
 */
export interface FetchProducts200ResponseItemsInnerCategoriesInner {
  /**
   *
   * @type {string}
   * @memberof FetchProducts200ResponseItemsInnerCategoriesInner
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof FetchProducts200ResponseItemsInnerCategoriesInner
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof FetchProducts200ResponseItemsInnerCategoriesInner
   */
  priority: number
  /**
   *
   * @type {string}
   * @memberof FetchProducts200ResponseItemsInnerCategoriesInner
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof FetchProducts200ResponseItemsInnerCategoriesInner
   */
  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof FetchProducts200ResponseItemsInnerCategoriesInner
   */
  projectId: string
}
/**
 *
 * @export
 * @interface FetchProject200Response
 */
export interface FetchProject200Response {
  /**
   *
   * @type {FetchProject200ResponseItem}
   * @memberof FetchProject200Response
   */
  item: FetchProject200ResponseItem
}
/**
 *
 * @export
 * @interface FetchProject200ResponseItem
 */
export interface FetchProject200ResponseItem {
  /**
   *
   * @type {string}
   * @memberof FetchProject200ResponseItem
   */
  id: string
  /**
   * 作成日時
   * @type {string}
   * @memberof FetchProject200ResponseItem
   */
  createdAt: string
  /**
   * 更新日時
   * @type {string}
   * @memberof FetchProject200ResponseItem
   */
  updatedAt: string
  /**
   * 削除日時
   * @type {string}
   * @memberof FetchProject200ResponseItem
   */
  deletedAt?: string | null
  /**
   *
   * @type {string}
   * @memberof FetchProject200ResponseItem
   */
  title: string
  /**
   *
   * @type {FetchProjects200ResponseItemsInnerBilling}
   * @memberof FetchProject200ResponseItem
   */
  billing?: FetchProjects200ResponseItemsInnerBilling | null
  /**
   *
   * @type {string}
   * @memberof FetchProject200ResponseItem
   */
  titleImageUrl?: string | null
  /**
   *
   * @type {string}
   * @memberof FetchProject200ResponseItem
   */
  thumbnailUrl?: string | null
  /**
   *
   * @type {FetchProject200ResponseItemColor}
   * @memberof FetchProject200ResponseItem
   */
  color: FetchProject200ResponseItemColor
  /**
   *
   * @type {FetchProjects200ResponseItemsInnerFeatures}
   * @memberof FetchProject200ResponseItem
   */
  features: FetchProjects200ResponseItemsInnerFeatures
  /**
   *
   * @type {FetchProjects200ResponseItemsInnerEnabledFeatures}
   * @memberof FetchProject200ResponseItem
   */
  enabledFeatures?: FetchProjects200ResponseItemsInnerEnabledFeatures
  /**
   *
   * @type {FetchProjects200ResponseItemsInnerCustomPages}
   * @memberof FetchProject200ResponseItem
   */
  customPages?: FetchProjects200ResponseItemsInnerCustomPages | null
  /**
   *
   * @type {FetchProject200ResponseItemHistory}
   * @memberof FetchProject200ResponseItem
   */
  history: FetchProject200ResponseItemHistory
  /**
   *
   * @type {FetchProject200ResponseItemProduct}
   * @memberof FetchProject200ResponseItem
   */
  product: FetchProject200ResponseItemProduct
  /**
   *
   * @type {FetchProject200ResponseItemFeedback}
   * @memberof FetchProject200ResponseItem
   */
  feedback: FetchProject200ResponseItemFeedback
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof FetchProject200ResponseItem
   */
  labels?: { [key: string]: string }
  /**
   *
   * @type {Array<string>}
   * @memberof FetchProject200ResponseItem
   */
  tips?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof FetchProject200ResponseItem
   */
  secretKey: string
  /**
   *
   * @type {Array<string>}
   * @memberof FetchProject200ResponseItem
   */
  siteDomains: Array<string>
}
/**
 *
 * @export
 * @interface FetchProject200ResponseItemColor
 */
export interface FetchProject200ResponseItemColor {
  /**
   *
   * @type {string}
   * @memberof FetchProject200ResponseItemColor
   */
  primary?: string
  /**
   *
   * @type {string}
   * @memberof FetchProject200ResponseItemColor
   */
  secondary?: string
  /**
   *
   * @type {string}
   * @memberof FetchProject200ResponseItemColor
   */
  accent?: string
}
/**
 *
 * @export
 * @interface FetchProject200ResponseItemFeedback
 */
export interface FetchProject200ResponseItemFeedback {
  /**
   * 最大表示数
   * @type {number}
   * @memberof FetchProject200ResponseItemFeedback
   */
  maxNumOf?: number
  /**
   * リンクの挙動
   * @type {string}
   * @memberof FetchProject200ResponseItemFeedback
   */
  linkBehavior?: FetchProject200ResponseItemFeedbackLinkBehaviorEnum
}

export const FetchProject200ResponseItemFeedbackLinkBehaviorEnum = {
  Blank: 'blank',
  Self: 'self',
} as const

export type FetchProject200ResponseItemFeedbackLinkBehaviorEnum =
  (typeof FetchProject200ResponseItemFeedbackLinkBehaviorEnum)[keyof typeof FetchProject200ResponseItemFeedbackLinkBehaviorEnum]

/**
 *
 * @export
 * @interface FetchProject200ResponseItemHistory
 */
export interface FetchProject200ResponseItemHistory {
  /**
   *
   * @type {FetchProject200ResponseItemHistoryVisibleItem}
   * @memberof FetchProject200ResponseItemHistory
   */
  visibleItem?: FetchProject200ResponseItemHistoryVisibleItem
  /**
   *
   * @type {FetchProject200ResponseItemHistoryFeatures}
   * @memberof FetchProject200ResponseItemHistory
   */
  features?: FetchProject200ResponseItemHistoryFeatures
}
/**
 *
 * @export
 * @interface FetchProject200ResponseItemHistoryFeatures
 */
export interface FetchProject200ResponseItemHistoryFeatures {
  /**
   * 詳細結果の有効/無効
   * @type {boolean}
   * @memberof FetchProject200ResponseItemHistoryFeatures
   */
  detailView?: boolean
  /**
   * 変遷グラフの有効/無効
   * @type {boolean}
   * @memberof FetchProject200ResponseItemHistoryFeatures
   */
  transactionView?: boolean
  /**
   * 結果ページ表示時のデフォルトビュー
   * @type {string}
   * @memberof FetchProject200ResponseItemHistoryFeatures
   */
  defaultView?: FetchProject200ResponseItemHistoryFeaturesDefaultViewEnum
  /**
   * 顔写真のトリミング方法
   * @type {string}
   * @memberof FetchProject200ResponseItemHistoryFeatures
   */
  trimmingPict?: FetchProject200ResponseItemHistoryFeaturesTrimmingPictEnum
  /**
   * 総合評価を四捨五入するか
   * @type {boolean}
   * @memberof FetchProject200ResponseItemHistoryFeatures
   */
  roundOverallRating?: boolean
  /**
   * 追加画像の種類
   * @type {Array<string>}
   * @memberof FetchProject200ResponseItemHistoryFeatures
   */
  additionalPicts?: Array<FetchProject200ResponseItemHistoryFeaturesAdditionalPictsEnum>
}

export const FetchProject200ResponseItemHistoryFeaturesDefaultViewEnum = {
  Detail: 'detail',
  Transaction: 'transaction',
} as const

export type FetchProject200ResponseItemHistoryFeaturesDefaultViewEnum =
  (typeof FetchProject200ResponseItemHistoryFeaturesDefaultViewEnum)[keyof typeof FetchProject200ResponseItemHistoryFeaturesDefaultViewEnum]
export const FetchProject200ResponseItemHistoryFeaturesTrimmingPictEnum = {
  Full: 'full',
  Nose: 'nose',
} as const

export type FetchProject200ResponseItemHistoryFeaturesTrimmingPictEnum =
  (typeof FetchProject200ResponseItemHistoryFeaturesTrimmingPictEnum)[keyof typeof FetchProject200ResponseItemHistoryFeaturesTrimmingPictEnum]
export const FetchProject200ResponseItemHistoryFeaturesAdditionalPictsEnum = {
  Right: 'dir-right',
  Left: 'dir-left',
} as const

export type FetchProject200ResponseItemHistoryFeaturesAdditionalPictsEnum =
  (typeof FetchProject200ResponseItemHistoryFeaturesAdditionalPictsEnum)[keyof typeof FetchProject200ResponseItemHistoryFeaturesAdditionalPictsEnum]

/**
 *
 * @export
 * @interface FetchProject200ResponseItemHistoryVisibleItem
 */
export interface FetchProject200ResponseItemHistoryVisibleItem {
  /**
   * 肌質の表示可否
   * @type {boolean}
   * @memberof FetchProject200ResponseItemHistoryVisibleItem
   */
  skinType?: boolean
  /**
   * 肌年齢の表示可否
   * @type {boolean}
   * @memberof FetchProject200ResponseItemHistoryVisibleItem
   */
  skinAge?: boolean
  /**
   * 総合評価の表示可否
   * @type {boolean}
   * @memberof FetchProject200ResponseItemHistoryVisibleItem
   */
  overallRating?: boolean
  /**
   * 顔写真の表示可否
   * @type {boolean}
   * @memberof FetchProject200ResponseItemHistoryVisibleItem
   */
  facePict?: boolean
  /**
   * 診断履歴の表示可否
   * @type {boolean}
   * @memberof FetchProject200ResponseItemHistoryVisibleItem
   */
  backnumber?: boolean
  /**
   * 比較スコアの表示可否
   * @type {boolean}
   * @memberof FetchProject200ResponseItemHistoryVisibleItem
   */
  comparisonScore?: boolean
  /**
   *
   * @type {FetchProject200ResponseItemHistoryVisibleItemSkinScore}
   * @memberof FetchProject200ResponseItemHistoryVisibleItem
   */
  skinScore?: FetchProject200ResponseItemHistoryVisibleItemSkinScore
}
/**
 * 項目別の表示可否
 * @export
 * @interface FetchProject200ResponseItemHistoryVisibleItemSkinScore
 */
export interface FetchProject200ResponseItemHistoryVisibleItemSkinScore {
  /**
   *
   * @type {boolean}
   * @memberof FetchProject200ResponseItemHistoryVisibleItemSkinScore
   */
  clarity: boolean
  /**
   *
   * @type {boolean}
   * @memberof FetchProject200ResponseItemHistoryVisibleItemSkinScore
   */
  moisture: boolean
  /**
   *
   * @type {boolean}
   * @memberof FetchProject200ResponseItemHistoryVisibleItemSkinScore
   */
  pores: boolean
  /**
   *
   * @type {boolean}
   * @memberof FetchProject200ResponseItemHistoryVisibleItemSkinScore
   */
  stain: boolean
  /**
   *
   * @type {boolean}
   * @memberof FetchProject200ResponseItemHistoryVisibleItemSkinScore
   */
  texture: boolean
  /**
   *
   * @type {boolean}
   * @memberof FetchProject200ResponseItemHistoryVisibleItemSkinScore
   */
  wrinkle: boolean
}
/**
 *
 * @export
 * @interface FetchProject200ResponseItemProduct
 */
export interface FetchProject200ResponseItemProduct {
  /**
   *
   * @type {FetchProject200ResponseItemProductVisibleItem}
   * @memberof FetchProject200ResponseItemProduct
   */
  visibleItem?: FetchProject200ResponseItemProductVisibleItem
  /**
   * 最大表示数
   * @type {number}
   * @memberof FetchProject200ResponseItemProduct
   */
  maxNumOf?: number
  /**
   * グループ商品の表示方法
   * @type {string}
   * @memberof FetchProject200ResponseItemProduct
   */
  groupBy?: FetchProject200ResponseItemProductGroupByEnum
  /**
   * グループ商品の最大表示数
   * @type {number}
   * @memberof FetchProject200ResponseItemProduct
   */
  maxNumOfInGroup?: number
  /**
   * 非グループ商品の表示優先度
   * @type {number}
   * @memberof FetchProject200ResponseItemProduct
   */
  nonGroupPriority?: number
  /**
   * 表示レイアウトの列数
   * @type {number}
   * @memberof FetchProject200ResponseItemProduct
   */
  columns?: number
  /**
   * リンクの挙動
   * @type {string}
   * @memberof FetchProject200ResponseItemProduct
   */
  linkBehavior?: FetchProject200ResponseItemProductLinkBehaviorEnum
}

export const FetchProject200ResponseItemProductGroupByEnum = {
  Category: 'category',
} as const

export type FetchProject200ResponseItemProductGroupByEnum =
  (typeof FetchProject200ResponseItemProductGroupByEnum)[keyof typeof FetchProject200ResponseItemProductGroupByEnum]
export const FetchProject200ResponseItemProductLinkBehaviorEnum = {
  Blank: 'blank',
  Self: 'self',
} as const

export type FetchProject200ResponseItemProductLinkBehaviorEnum =
  (typeof FetchProject200ResponseItemProductLinkBehaviorEnum)[keyof typeof FetchProject200ResponseItemProductLinkBehaviorEnum]

/**
 *
 * @export
 * @interface FetchProject200ResponseItemProductVisibleItem
 */
export interface FetchProject200ResponseItemProductVisibleItem {
  /**
   * 商品画像の表示可否
   * @type {boolean}
   * @memberof FetchProject200ResponseItemProductVisibleItem
   */
  thumbnail?: boolean
  /**
   * ブランドの表示可否
   * @type {boolean}
   * @memberof FetchProject200ResponseItemProductVisibleItem
   */
  brand?: boolean
  /**
   * 商品名の表示可否
   * @type {boolean}
   * @memberof FetchProject200ResponseItemProductVisibleItem
   */
  name?: boolean
  /**
   * 価格の表示可否
   * @type {boolean}
   * @memberof FetchProject200ResponseItemProductVisibleItem
   */
  price?: boolean
  /**
   * タグの表示可否
   * @type {boolean}
   * @memberof FetchProject200ResponseItemProductVisibleItem
   */
  tags?: boolean
  /**
   * 商品説明の表示可否
   * @type {boolean}
   * @memberof FetchProject200ResponseItemProductVisibleItem
   */
  description?: boolean
}
/**
 *
 * @export
 * @interface FetchProjects200Response
 */
export interface FetchProjects200Response {
  /**
   *
   * @type {Array<FetchProjects200ResponseItemsInner>}
   * @memberof FetchProjects200Response
   */
  items: Array<FetchProjects200ResponseItemsInner>
  /**
   *
   * @type {number}
   * @memberof FetchProjects200Response
   */
  total: number
}
/**
 *
 * @export
 * @interface FetchProjects200ResponseItemsInner
 */
export interface FetchProjects200ResponseItemsInner {
  /**
   *
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInner
   */
  id: string
  /**
   * 作成日時
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInner
   */
  createdAt: string
  /**
   * 削除日時
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInner
   */
  deletedAt?: string | null
  /**
   *
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInner
   */
  title: string
  /**
   *
   * @type {FetchProjects200ResponseItemsInnerBilling}
   * @memberof FetchProjects200ResponseItemsInner
   */
  billing?: FetchProjects200ResponseItemsInnerBilling | null
  /**
   *
   * @type {Array<FetchProjects200ResponseItemsInnerProvidersInner>}
   * @memberof FetchProjects200ResponseItemsInner
   */
  providers?: Array<FetchProjects200ResponseItemsInnerProvidersInner>
  /**
   *
   * @type {FetchProjects200ResponseItemsInnerCustomPages}
   * @memberof FetchProjects200ResponseItemsInner
   */
  customPages?: FetchProjects200ResponseItemsInnerCustomPages | null
  /**
   *
   * @type {FetchProjects200ResponseItemsInnerFeatures}
   * @memberof FetchProjects200ResponseItemsInner
   */
  features: FetchProjects200ResponseItemsInnerFeatures
  /**
   *
   * @type {FetchProjects200ResponseItemsInnerEnabledFeatures}
   * @memberof FetchProjects200ResponseItemsInner
   */
  enabledFeatures?: FetchProjects200ResponseItemsInnerEnabledFeatures
  /**
   *
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInner
   */
  note?: string | null
}
/**
 *
 * @export
 * @interface FetchProjects200ResponseItemsInnerBilling
 */
export interface FetchProjects200ResponseItemsInnerBilling {
  /**
   *
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerBilling
   */
  status?: FetchProjects200ResponseItemsInnerBillingStatusEnum
  /**
   *
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerBilling
   */
  planId?: string | null
}

export const FetchProjects200ResponseItemsInnerBillingStatusEnum = {
  Active: 'active',
  All: 'all',
  Canceled: 'canceled',
  Ended: 'ended',
  Incomplete: 'incomplete',
  IncompleteExpired: 'incomplete_expired',
  PastDue: 'past_due',
  Trialing: 'trialing',
  Unpaid: 'unpaid',
} as const

export type FetchProjects200ResponseItemsInnerBillingStatusEnum =
  (typeof FetchProjects200ResponseItemsInnerBillingStatusEnum)[keyof typeof FetchProjects200ResponseItemsInnerBillingStatusEnum]

/**
 * クライアント独自で用意できるカスタムページ
 * @export
 * @interface FetchProjects200ResponseItemsInnerCustomPages
 */
export interface FetchProjects200ResponseItemsInnerCustomPages {
  /**
   *
   * @type {FetchProjects200ResponseItemsInnerCustomPagesWelcome}
   * @memberof FetchProjects200ResponseItemsInnerCustomPages
   */
  welcome?: FetchProjects200ResponseItemsInnerCustomPagesWelcome | null
  /**
   *
   * @type {FetchProjects200ResponseItemsInnerCustomPagesPrivacyPolicy}
   * @memberof FetchProjects200ResponseItemsInnerCustomPages
   */
  privacyPolicy?: FetchProjects200ResponseItemsInnerCustomPagesPrivacyPolicy | null
  /**
   *
   * @type {FetchProjects200ResponseItemsInnerCustomPagesPrivacyPolicy}
   * @memberof FetchProjects200ResponseItemsInnerCustomPages
   */
  termsOfService?: FetchProjects200ResponseItemsInnerCustomPagesPrivacyPolicy | null
}
/**
 *
 * @export
 * @interface FetchProjects200ResponseItemsInnerCustomPagesPrivacyPolicy
 */
export interface FetchProjects200ResponseItemsInnerCustomPagesPrivacyPolicy {
  /**
   *
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerCustomPagesPrivacyPolicy
   */
  type: FetchProjects200ResponseItemsInnerCustomPagesPrivacyPolicyTypeEnum
  /**
   *
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerCustomPagesPrivacyPolicy
   */
  url: string
  /**
   * マークダウン表記
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerCustomPagesPrivacyPolicy
   */
  content: string
}

export const FetchProjects200ResponseItemsInnerCustomPagesPrivacyPolicyTypeEnum =
  {
    Customize: 'customize',
  } as const

export type FetchProjects200ResponseItemsInnerCustomPagesPrivacyPolicyTypeEnum =
  (typeof FetchProjects200ResponseItemsInnerCustomPagesPrivacyPolicyTypeEnum)[keyof typeof FetchProjects200ResponseItemsInnerCustomPagesPrivacyPolicyTypeEnum]

/**
 *
 * @export
 * @interface FetchProjects200ResponseItemsInnerCustomPagesPrivacyPolicyAnyOf
 */
export interface FetchProjects200ResponseItemsInnerCustomPagesPrivacyPolicyAnyOf {
  /**
   *
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerCustomPagesPrivacyPolicyAnyOf
   */
  type: FetchProjects200ResponseItemsInnerCustomPagesPrivacyPolicyAnyOfTypeEnum
  /**
   * マークダウン表記
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerCustomPagesPrivacyPolicyAnyOf
   */
  content: string
}

export const FetchProjects200ResponseItemsInnerCustomPagesPrivacyPolicyAnyOfTypeEnum =
  {
    Customize: 'customize',
  } as const

export type FetchProjects200ResponseItemsInnerCustomPagesPrivacyPolicyAnyOfTypeEnum =
  (typeof FetchProjects200ResponseItemsInnerCustomPagesPrivacyPolicyAnyOfTypeEnum)[keyof typeof FetchProjects200ResponseItemsInnerCustomPagesPrivacyPolicyAnyOfTypeEnum]

/**
 *
 * @export
 * @interface FetchProjects200ResponseItemsInnerCustomPagesWelcome
 */
export interface FetchProjects200ResponseItemsInnerCustomPagesWelcome {
  /**
   *
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerCustomPagesWelcome
   */
  type: FetchProjects200ResponseItemsInnerCustomPagesWelcomeTypeEnum
  /**
   *
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerCustomPagesWelcome
   */
  url: string
  /**
   *
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerCustomPagesWelcome
   */
  title: string
  /**
   * マークダウン表記
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerCustomPagesWelcome
   */
  content: string
  /**
   *
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerCustomPagesWelcome
   */
  btnLabel: string
  /**
   * トップ画像パス
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerCustomPagesWelcome
   */
  kvPath?: string
}

export const FetchProjects200ResponseItemsInnerCustomPagesWelcomeTypeEnum = {
  Customize: 'customize',
} as const

export type FetchProjects200ResponseItemsInnerCustomPagesWelcomeTypeEnum =
  (typeof FetchProjects200ResponseItemsInnerCustomPagesWelcomeTypeEnum)[keyof typeof FetchProjects200ResponseItemsInnerCustomPagesWelcomeTypeEnum]

/**
 *
 * @export
 * @interface FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOf
 */
export interface FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOf {
  /**
   *
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOf
   */
  type: FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOfTypeEnum
}

export const FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOfTypeEnum =
  {
    Default: 'default',
  } as const

export type FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOfTypeEnum =
  (typeof FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOfTypeEnum)[keyof typeof FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOfTypeEnum]

/**
 *
 * @export
 * @interface FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOf1
 */
export interface FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOf1 {
  /**
   *
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOf1
   */
  type: FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOf1TypeEnum
  /**
   *
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOf1
   */
  url: string
}

export const FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOf1TypeEnum =
  {
    External: 'external',
  } as const

export type FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOf1TypeEnum =
  (typeof FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOf1TypeEnum)[keyof typeof FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOf1TypeEnum]

/**
 *
 * @export
 * @interface FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOf2
 */
export interface FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOf2 {
  /**
   *
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOf2
   */
  type: FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOf2TypeEnum
  /**
   *
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOf2
   */
  title: string
  /**
   * マークダウン表記
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOf2
   */
  content: string
  /**
   *
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOf2
   */
  btnLabel: string
  /**
   * トップ画像パス
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOf2
   */
  kvPath?: string
}

export const FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOf2TypeEnum =
  {
    Customize: 'customize',
  } as const

export type FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOf2TypeEnum =
  (typeof FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOf2TypeEnum)[keyof typeof FetchProjects200ResponseItemsInnerCustomPagesWelcomeAnyOf2TypeEnum]

/**
 * 管理画面でコントールする機能の有効/無効
 * @export
 * @interface FetchProjects200ResponseItemsInnerEnabledFeatures
 */
export interface FetchProjects200ResponseItemsInnerEnabledFeatures {
  /**
   * 商品レコメンドの有効/無効
   * @type {boolean}
   * @memberof FetchProjects200ResponseItemsInnerEnabledFeatures
   */
  productRuleRecommendation?: boolean
  /**
   * 商品のAIレコメンドの有効/無効
   * @type {boolean}
   * @memberof FetchProjects200ResponseItemsInnerEnabledFeatures
   */
  aiReccomendation?: boolean
  /**
   * ID連携の有効/無効
   * @type {boolean}
   * @memberof FetchProjects200ResponseItemsInnerEnabledFeatures
   */
  connectId?: boolean
  /**
   * 診断画像の保存・閲覧機能の有効/無効
   * @type {boolean}
   * @memberof FetchProjects200ResponseItemsInnerEnabledFeatures
   */
  facePict?: boolean
  /**
   * 外部APIの有効/無効
   * @type {boolean}
   * @memberof FetchProjects200ResponseItemsInnerEnabledFeatures
   */
  externalAPI?: boolean
  /**
   * 鼻のトリミングの有効/無効
   * @type {boolean}
   * @memberof FetchProjects200ResponseItemsInnerEnabledFeatures
   */
  trimmingNose?: boolean
}
/**
 * プロジェクトの機能設定
 * @export
 * @interface FetchProjects200ResponseItemsInnerFeatures
 */
export interface FetchProjects200ResponseItemsInnerFeatures {
  /**
   * ID連携の有効/無効
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerFeatures
   */
  connectId?: FetchProjects200ResponseItemsInnerFeaturesConnectIdEnum
  /**
   * 匿名（年齢・性別の任意化）の有効/無効
   * @type {boolean}
   * @memberof FetchProjects200ResponseItemsInnerFeatures
   */
  anonymous?: boolean
  /**
   * ホーム画面として表示するページ
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerFeatures
   */
  homePage?: FetchProjects200ResponseItemsInnerFeaturesHomePageEnum
  /**
   * 顔写真保存の有効/無効
   * @type {boolean}
   * @memberof FetchProjects200ResponseItemsInnerFeatures
   */
  saveFacePict?: boolean
  /**
   * 商品レコメンドの種類
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerFeatures
   */
  productRecommendation?: FetchProjects200ResponseItemsInnerFeaturesProductRecommendationEnum
  /**
   * ID連携用のLIFF ID
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerFeatures
   */
  connectLiffId?: string | null
  /**
   *
   * @type {FetchProjects200ResponseItemsInnerFeaturesTransferAccountUrl}
   * @memberof FetchProjects200ResponseItemsInnerFeatures
   */
  transferAccountUrl?: FetchProjects200ResponseItemsInnerFeaturesTransferAccountUrl
  /**
   * カスタムユーザ属性取得APIエンドポイント
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerFeatures
   */
  userCustomAttributeAPIEndpoint?: string
  /**
   * AIモデルの最終リリース日時
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerFeatures
   */
  aiLatestReleasedAt?: string
}

export const FetchProjects200ResponseItemsInnerFeaturesConnectIdEnum = {
  Required: 'required',
  Optional: 'optional',
  Disabled: 'disabled',
} as const

export type FetchProjects200ResponseItemsInnerFeaturesConnectIdEnum =
  (typeof FetchProjects200ResponseItemsInnerFeaturesConnectIdEnum)[keyof typeof FetchProjects200ResponseItemsInnerFeaturesConnectIdEnum]
export const FetchProjects200ResponseItemsInnerFeaturesHomePageEnum = {
  Result: 'result',
  Shooting: 'shooting',
} as const

export type FetchProjects200ResponseItemsInnerFeaturesHomePageEnum =
  (typeof FetchProjects200ResponseItemsInnerFeaturesHomePageEnum)[keyof typeof FetchProjects200ResponseItemsInnerFeaturesHomePageEnum]
export const FetchProjects200ResponseItemsInnerFeaturesProductRecommendationEnum =
  {
    Rule: 'rule',
    Ai: 'ai',
  } as const

export type FetchProjects200ResponseItemsInnerFeaturesProductRecommendationEnum =
  (typeof FetchProjects200ResponseItemsInnerFeaturesProductRecommendationEnum)[keyof typeof FetchProjects200ResponseItemsInnerFeaturesProductRecommendationEnum]

/**
 * アカウント情報を別端末へ引き継ぐ機能のURL
 * @export
 * @interface FetchProjects200ResponseItemsInnerFeaturesTransferAccountUrl
 */
export interface FetchProjects200ResponseItemsInnerFeaturesTransferAccountUrl {}
/**
 *
 * @export
 * @enum {string}
 */

export const FetchProjects200ResponseItemsInnerFeaturesTransferAccountUrlAnyOf =
  {
    SameDomain: 'SAME_DOMAIN',
  } as const

export type FetchProjects200ResponseItemsInnerFeaturesTransferAccountUrlAnyOf =
  (typeof FetchProjects200ResponseItemsInnerFeaturesTransferAccountUrlAnyOf)[keyof typeof FetchProjects200ResponseItemsInnerFeaturesTransferAccountUrlAnyOf]

/**
 *
 * @export
 * @interface FetchProjects200ResponseItemsInnerProvidersInner
 */
export interface FetchProjects200ResponseItemsInnerProvidersInner {
  /**
   *
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerProvidersInner
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof FetchProjects200ResponseItemsInnerProvidersInner
   */
  displayName: string
}
/**
 *
 * @export
 * @interface FetchProviders200Response
 */
export interface FetchProviders200Response {
  /**
   *
   * @type {Array<FetchProviders200ResponseItemsInner>}
   * @memberof FetchProviders200Response
   */
  items: Array<FetchProviders200ResponseItemsInner>
  /**
   *
   * @type {number}
   * @memberof FetchProviders200Response
   */
  total: number
}
/**
 *
 * @export
 * @interface FetchProviders200ResponseItemsInner
 */
export interface FetchProviders200ResponseItemsInner {
  /**
   *
   * @type {string}
   * @memberof FetchProviders200ResponseItemsInner
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof FetchProviders200ResponseItemsInner
   */
  displayName: string
  /**
   *
   * @type {string}
   * @memberof FetchProviders200ResponseItemsInner
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof FetchProviders200ResponseItemsInner
   */
  createdAt: string
}
/**
 *
 * @export
 * @interface FetchReleases200Response
 */
export interface FetchReleases200Response {
  /**
   *
   * @type {Array<FetchReleases200ResponseItemsInner>}
   * @memberof FetchReleases200Response
   */
  items: Array<FetchReleases200ResponseItemsInner>
  /**
   *
   * @type {number}
   * @memberof FetchReleases200Response
   */
  total: number
}
/**
 *
 * @export
 * @interface FetchReleases200ResponseItemsInner
 */
export interface FetchReleases200ResponseItemsInner {
  /**
   *
   * @type {string}
   * @memberof FetchReleases200ResponseItemsInner
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof FetchReleases200ResponseItemsInner
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof FetchReleases200ResponseItemsInner
   */
  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof FetchReleases200ResponseItemsInner
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof FetchReleases200ResponseItemsInner
   */
  projectId: string
  /**
   *
   * @type {Array<CreateRule201ResponseItem>}
   * @memberof FetchReleases200ResponseItemsInner
   */
  rules: Array<CreateRule201ResponseItem>
  /**
   *
   * @type {Array<CreateCounseling201ResponseItem>}
   * @memberof FetchReleases200ResponseItemsInner
   */
  counselings: Array<CreateCounseling201ResponseItem>
  /**
   *
   * @type {boolean}
   * @memberof FetchReleases200ResponseItemsInner
   */
  activated: boolean
  /**
   * 購買データCSVのSHA256ハッシュ値
   * @type {string}
   * @memberof FetchReleases200ResponseItemsInner
   */
  aiModelId?: string | null
}
/**
 *
 * @export
 * @interface FetchRules200Response
 */
export interface FetchRules200Response {
  /**
   *
   * @type {number}
   * @memberof FetchRules200Response
   */
  limit?: number
  /**
   *
   * @type {number}
   * @memberof FetchRules200Response
   */
  offset?: number
  /**
   *
   * @type {Array<FetchRules200ResponseItemsInner>}
   * @memberof FetchRules200Response
   */
  items: Array<FetchRules200ResponseItemsInner>
  /**
   *
   * @type {number}
   * @memberof FetchRules200Response
   */
  total: number
}
/**
 *
 * @export
 * @interface FetchRules200ResponseItemsInner
 */
export interface FetchRules200ResponseItemsInner {
  /**
   *
   * @type {string}
   * @memberof FetchRules200ResponseItemsInner
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof FetchRules200ResponseItemsInner
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof FetchRules200ResponseItemsInner
   */
  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof FetchRules200ResponseItemsInner
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof FetchRules200ResponseItemsInner
   */
  ruleLogicalOperator: FetchRules200ResponseItemsInnerRuleLogicalOperatorEnum
  /**
   *
   * @type {Array<FetchRules200ResponseItemsInnerConditionsInner>}
   * @memberof FetchRules200ResponseItemsInner
   */
  conditions: Array<FetchRules200ResponseItemsInnerConditionsInner>
  /**
   *
   * @type {Array<FetchRules200ResponseItemsInnerRewardProductsInner>}
   * @memberof FetchRules200ResponseItemsInner
   */
  rewardProducts: Array<FetchRules200ResponseItemsInnerRewardProductsInner>
  /**
   *
   * @type {Array<FetchRules200ResponseItemsInnerRewardProductsInner>}
   * @memberof FetchRules200ResponseItemsInner
   */
  rewardFeedbacks: Array<FetchRules200ResponseItemsInnerRewardProductsInner>
  /**
   *
   * @type {boolean}
   * @memberof FetchRules200ResponseItemsInner
   */
  isRewardedReleases: boolean
}

export const FetchRules200ResponseItemsInnerRuleLogicalOperatorEnum = {
  And: 'and',
  Or: 'or',
} as const

export type FetchRules200ResponseItemsInnerRuleLogicalOperatorEnum =
  (typeof FetchRules200ResponseItemsInnerRuleLogicalOperatorEnum)[keyof typeof FetchRules200ResponseItemsInnerRuleLogicalOperatorEnum]

/**
 *
 * @export
 * @interface FetchRules200ResponseItemsInnerConditionsInner
 */
export interface FetchRules200ResponseItemsInnerConditionsInner {
  /**
   *
   * @type {string}
   * @memberof FetchRules200ResponseItemsInnerConditionsInner
   */
  type: FetchRules200ResponseItemsInnerConditionsInnerTypeEnum
  /**
   *
   * @type {string}
   * @memberof FetchRules200ResponseItemsInnerConditionsInner
   */
  operation: FetchRules200ResponseItemsInnerConditionsInnerOperationEnum
  /**
   *
   * @type {FetchRules200ResponseItemsInnerConditionsInnerValue}
   * @memberof FetchRules200ResponseItemsInnerConditionsInner
   */
  value: FetchRules200ResponseItemsInnerConditionsInnerValue
  /**
   *
   * @type {string}
   * @memberof FetchRules200ResponseItemsInnerConditionsInner
   */
  target?: string
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof FetchRules200ResponseItemsInnerConditionsInner
   */
  options?: { [key: string]: any }
}

export const FetchRules200ResponseItemsInnerConditionsInnerTypeEnum = {
  SkinStatusWrinkle: 'skin-status-wrinkle',
  SkinStatusTexture: 'skin-status-texture',
  SkinStatusStain: 'skin-status-stain',
  SkinStatusClarity: 'skin-status-clarity',
  SkinStatusMoisture: 'skin-status-moisture',
  SkinStatusPores: 'skin-status-pores',
  WorstSkinStatus: 'worst-skin-status',
  BestSkinStatus: 'best-skin-status',
  SkinType: 'skin-type',
  SkinAge: 'skin-age',
  Gender: 'gender',
  Age: 'age',
  Counseling: 'counseling',
  UserCustomAttribute: 'user-custom-attribute',
  DynamicParam: 'dynamic-param',
  ConnectId: 'connect-id',
} as const

export type FetchRules200ResponseItemsInnerConditionsInnerTypeEnum =
  (typeof FetchRules200ResponseItemsInnerConditionsInnerTypeEnum)[keyof typeof FetchRules200ResponseItemsInnerConditionsInnerTypeEnum]
export const FetchRules200ResponseItemsInnerConditionsInnerOperationEnum = {
  LessThan: '<',
  GreaterThan: '>',
  LessThanOrEqualTo: '<=',
  GreaterThanOrEqualTo: '>=',
  Equal: '=',
  NotEqual: '!=',
  In: 'in',
  NotIn: 'not-in',
} as const

export type FetchRules200ResponseItemsInnerConditionsInnerOperationEnum =
  (typeof FetchRules200ResponseItemsInnerConditionsInnerOperationEnum)[keyof typeof FetchRules200ResponseItemsInnerConditionsInnerOperationEnum]

/**
 *
 * @export
 * @interface FetchRules200ResponseItemsInnerConditionsInnerValue
 */
export interface FetchRules200ResponseItemsInnerConditionsInnerValue {}
/**
 *
 * @export
 * @interface FetchRules200ResponseItemsInnerRewardProductsInner
 */
export interface FetchRules200ResponseItemsInnerRewardProductsInner {
  /**
   *
   * @type {string}
   * @memberof FetchRules200ResponseItemsInnerRewardProductsInner
   */
  id: string
  /**
   *
   * @type {number}
   * @memberof FetchRules200ResponseItemsInnerRewardProductsInner
   */
  priority: number
}
/**
 *
 * @export
 * @interface FetchUpdateRequests200Response
 */
export interface FetchUpdateRequests200Response {
  /**
   *
   * @type {Array<FetchUpdateRequests200ResponseItemsInner>}
   * @memberof FetchUpdateRequests200Response
   */
  items: Array<FetchUpdateRequests200ResponseItemsInner>
  /**
   *
   * @type {number}
   * @memberof FetchUpdateRequests200Response
   */
  total: number
}
/**
 *
 * @export
 * @interface FetchUpdateRequests200ResponseItemsInner
 */
export interface FetchUpdateRequests200ResponseItemsInner {
  /**
   *
   * @type {string}
   * @memberof FetchUpdateRequests200ResponseItemsInner
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof FetchUpdateRequests200ResponseItemsInner
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof FetchUpdateRequests200ResponseItemsInner
   */
  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof FetchUpdateRequests200ResponseItemsInner
   */
  projectId: string
  /**
   *
   * @type {string}
   * @memberof FetchUpdateRequests200ResponseItemsInner
   */
  type: FetchUpdateRequests200ResponseItemsInnerTypeEnum
  /**
   *
   * @type {string}
   * @memberof FetchUpdateRequests200ResponseItemsInner
   */
  status?: FetchUpdateRequests200ResponseItemsInnerStatusEnum
  /**
   *
   * @type {string}
   * @memberof FetchUpdateRequests200ResponseItemsInner
   */
  rejectReason?: string | null
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof FetchUpdateRequests200ResponseItemsInner
   */
  content: { [key: string]: any }
  /**
   *
   * @type {string}
   * @memberof FetchUpdateRequests200ResponseItemsInner
   */
  applicantEmail: string
}

export const FetchUpdateRequests200ResponseItemsInnerTypeEnum = {
  Welcomeupdate: 'welcome:update',
  PrivacyPolicyupdate: 'privacyPolicy:update',
  TermsOfServiceupdate: 'termsOfService:update',
} as const

export type FetchUpdateRequests200ResponseItemsInnerTypeEnum =
  (typeof FetchUpdateRequests200ResponseItemsInnerTypeEnum)[keyof typeof FetchUpdateRequests200ResponseItemsInnerTypeEnum]
export const FetchUpdateRequests200ResponseItemsInnerStatusEnum = {
  Pending: 'pending',
  Approved: 'approved',
  Rejected: 'rejected',
  Withdraw: 'withdraw',
} as const

export type FetchUpdateRequests200ResponseItemsInnerStatusEnum =
  (typeof FetchUpdateRequests200ResponseItemsInnerStatusEnum)[keyof typeof FetchUpdateRequests200ResponseItemsInnerStatusEnum]

/**
 *
 * @export
 * @interface FetchUserByRefreshToken200Response
 */
export interface FetchUserByRefreshToken200Response {
  /**
   *
   * @type {FetchUserByRefreshToken200ResponsePayload}
   * @memberof FetchUserByRefreshToken200Response
   */
  payload: FetchUserByRefreshToken200ResponsePayload | null
  /**
   *
   * @type {string}
   * @memberof FetchUserByRefreshToken200Response
   */
  accessToken?: string
  /**
   *
   * @type {number}
   * @memberof FetchUserByRefreshToken200Response
   */
  expiresIn?: number
}
/**
 *
 * @export
 * @interface FetchUserByRefreshToken200ResponsePayload
 */
export interface FetchUserByRefreshToken200ResponsePayload {
  /**
   *
   * @type {string}
   * @memberof FetchUserByRefreshToken200ResponsePayload
   */
  userId: string
  /**
   *
   * @type {string}
   * @memberof FetchUserByRefreshToken200ResponsePayload
   */
  projectId: string
  /**
   *
   * @type {string}
   * @memberof FetchUserByRefreshToken200ResponsePayload
   */
  domain: string
  /**
   *
   * @type {string}
   * @memberof FetchUserByRefreshToken200ResponsePayload
   */
  connectId?: string
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof FetchUserByRefreshToken200ResponsePayload
   */
  dynamicParams?: { [key: string]: any }
}
/**
 *
 * @export
 * @interface FetchUserCustomAttributes200Response
 */
export interface FetchUserCustomAttributes200Response {
  /**
   *
   * @type {Array<FetchUserCustomAttributes200ResponseItemsInner>}
   * @memberof FetchUserCustomAttributes200Response
   */
  items: Array<FetchUserCustomAttributes200ResponseItemsInner>
  /**
   *
   * @type {number}
   * @memberof FetchUserCustomAttributes200Response
   */
  total: number
}
/**
 *
 * @export
 * @interface FetchUserCustomAttributes200ResponseItemsInner
 */
export interface FetchUserCustomAttributes200ResponseItemsInner {
  /**
   *
   * @type {string}
   * @memberof FetchUserCustomAttributes200ResponseItemsInner
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof FetchUserCustomAttributes200ResponseItemsInner
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof FetchUserCustomAttributes200ResponseItemsInner
   */
  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof FetchUserCustomAttributes200ResponseItemsInner
   */
  projectId: string
  /**
   *
   * @type {string}
   * @memberof FetchUserCustomAttributes200ResponseItemsInner
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof FetchUserCustomAttributes200ResponseItemsInner
   */
  key: string
  /**
   *
   * @type {string}
   * @memberof FetchUserCustomAttributes200ResponseItemsInner
   */
  type: FetchUserCustomAttributes200ResponseItemsInnerTypeEnum
}

export const FetchUserCustomAttributes200ResponseItemsInnerTypeEnum = {
  String: 'String',
  Number: 'Number',
  Boolean: 'Boolean',
  Date: 'Date',
} as const

export type FetchUserCustomAttributes200ResponseItemsInnerTypeEnum =
  (typeof FetchUserCustomAttributes200ResponseItemsInnerTypeEnum)[keyof typeof FetchUserCustomAttributes200ResponseItemsInnerTypeEnum]

/**
 *
 * @export
 * @interface FindDiagnosisUserById200Response
 */
export interface FindDiagnosisUserById200Response {
  /**
   *
   * @type {FetchDiagnosisUsers200ResponseItemsInner}
   * @memberof FindDiagnosisUserById200Response
   */
  user: FetchDiagnosisUsers200ResponseItemsInner
  /**
   *
   * @type {FindDiagnosisUserById200ResponseProducts}
   * @memberof FindDiagnosisUserById200Response
   */
  products: FindDiagnosisUserById200ResponseProducts
  /**
   *
   * @type {FindDiagnosisUserById200ResponseFeedbacks}
   * @memberof FindDiagnosisUserById200Response
   */
  feedbacks: FindDiagnosisUserById200ResponseFeedbacks
  /**
   *
   * @type {FindDiagnosisUserById200ResponseDiagnoses}
   * @memberof FindDiagnosisUserById200Response
   */
  diagnoses: FindDiagnosisUserById200ResponseDiagnoses
}
/**
 *
 * @export
 * @interface FindDiagnosisUserById200ResponseDiagnoses
 */
export interface FindDiagnosisUserById200ResponseDiagnoses {
  /**
   *
   * @type {Array<FindDiagnosisUserById200ResponseDiagnosesItemsInner>}
   * @memberof FindDiagnosisUserById200ResponseDiagnoses
   */
  items: Array<FindDiagnosisUserById200ResponseDiagnosesItemsInner>
  /**
   *
   * @type {number}
   * @memberof FindDiagnosisUserById200ResponseDiagnoses
   */
  total: number
}
/**
 *
 * @export
 * @interface FindDiagnosisUserById200ResponseDiagnosesItemsInner
 */
export interface FindDiagnosisUserById200ResponseDiagnosesItemsInner {
  /**
   *
   * @type {string}
   * @memberof FindDiagnosisUserById200ResponseDiagnosesItemsInner
   */
  projectId: string
  /**
   *
   * @type {FindDiagnosisUserById200ResponseDiagnosesItemsInnerScores}
   * @memberof FindDiagnosisUserById200ResponseDiagnosesItemsInner
   */
  scores: FindDiagnosisUserById200ResponseDiagnosesItemsInnerScores
  /**
   *
   * @type {{ [key: string]: FindDiagnosisUserById200ResponseDiagnosesItemsInnerCounselingValue; }}
   * @memberof FindDiagnosisUserById200ResponseDiagnosesItemsInner
   */
  counseling?: {
    [
      key: string
    ]: FindDiagnosisUserById200ResponseDiagnosesItemsInnerCounselingValue
  }
  /**
   *
   * @type {string}
   * @memberof FindDiagnosisUserById200ResponseDiagnosesItemsInner
   */
  faceImagePath?: string
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof FindDiagnosisUserById200ResponseDiagnosesItemsInner
   */
  additionalImagePaths?: { [key: string]: string }
  /**
   *
   * @type {number}
   * @memberof FindDiagnosisUserById200ResponseDiagnosesItemsInner
   */
  createdAt: number
  /**
   *
   * @type {number}
   * @memberof FindDiagnosisUserById200ResponseDiagnosesItemsInner
   */
  prevId?: number
  /**
   *
   * @type {number}
   * @memberof FindDiagnosisUserById200ResponseDiagnosesItemsInner
   */
  nextId?: number
  /**
   *
   * @type {string}
   * @memberof FindDiagnosisUserById200ResponseDiagnosesItemsInner
   */
  userId: string
  /**
   *
   * @type {string}
   * @memberof FindDiagnosisUserById200ResponseDiagnosesItemsInner
   */
  type: FindDiagnosisUserById200ResponseDiagnosesItemsInnerTypeEnum
  /**
   *
   * @type {number}
   * @memberof FindDiagnosisUserById200ResponseDiagnosesItemsInner
   */
  age: number
}

export const FindDiagnosisUserById200ResponseDiagnosesItemsInnerTypeEnum = {
  Dry: 'dry',
  Oily: 'oily',
  Mix: 'mix',
  Sensitive: 'sensitive',
  Normal: 'normal',
} as const

export type FindDiagnosisUserById200ResponseDiagnosesItemsInnerTypeEnum =
  (typeof FindDiagnosisUserById200ResponseDiagnosesItemsInnerTypeEnum)[keyof typeof FindDiagnosisUserById200ResponseDiagnosesItemsInnerTypeEnum]

/**
 *
 * @export
 * @interface FindDiagnosisUserById200ResponseDiagnosesItemsInnerCounselingValue
 */
export interface FindDiagnosisUserById200ResponseDiagnosesItemsInnerCounselingValue {}
/**
 *
 * @export
 * @interface FindDiagnosisUserById200ResponseDiagnosesItemsInnerScores
 */
export interface FindDiagnosisUserById200ResponseDiagnosesItemsInnerScores {
  /**
   *
   * @type {FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkle}
   * @memberof FindDiagnosisUserById200ResponseDiagnosesItemsInnerScores
   */
  wrinkle: FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkle
  /**
   *
   * @type {FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkle}
   * @memberof FindDiagnosisUserById200ResponseDiagnosesItemsInnerScores
   */
  stain: FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkle
  /**
   *
   * @type {FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkle}
   * @memberof FindDiagnosisUserById200ResponseDiagnosesItemsInnerScores
   */
  texture: FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkle
  /**
   *
   * @type {FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkle}
   * @memberof FindDiagnosisUserById200ResponseDiagnosesItemsInnerScores
   */
  clarity: FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkle
  /**
   *
   * @type {FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkle}
   * @memberof FindDiagnosisUserById200ResponseDiagnosesItemsInnerScores
   */
  moisture: FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkle
  /**
   *
   * @type {FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkle}
   * @memberof FindDiagnosisUserById200ResponseDiagnosesItemsInnerScores
   */
  pores: FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkle
}
/**
 *
 * @export
 * @interface FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkle
 */
export interface FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkle {
  /**
   *
   * @type {number}
   * @memberof FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkle
   */
  average?: number
  /**
   *
   * @type {number}
   * @memberof FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkle
   */
  score: number
  /**
   *
   * @type {string}
   * @memberof FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkle
   */
  rank?: FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkleRankEnum
  /**
   *
   * @type {FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkleDetails}
   * @memberof FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkle
   */
  details?: FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkleDetails | null
}

export const FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkleRankEnum =
  {
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    E: 'E',
  } as const

export type FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkleRankEnum =
  (typeof FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkleRankEnum)[keyof typeof FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkleRankEnum]

/**
 *
 * @export
 * @interface FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkleDetails
 */
export interface FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkleDetails {
  /**
   *
   * @type {string}
   * @memberof FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkleDetails
   */
  detailType?: string | null
  /**
   *
   * @type {string}
   * @memberof FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkleDetails
   */
  detailUnit?: string | null
  /**
   *
   * @type {FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkleDetailsDetailValue}
   * @memberof FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkleDetails
   */
  detailValue?: FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkleDetailsDetailValue | null
}
/**
 *
 * @export
 * @interface FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkleDetailsDetailValue
 */
export interface FindDiagnosisUserById200ResponseDiagnosesItemsInnerScoresWrinkleDetailsDetailValue {}
/**
 *
 * @export
 * @interface FindDiagnosisUserById200ResponseFeedbacks
 */
export interface FindDiagnosisUserById200ResponseFeedbacks {
  /**
   *
   * @type {Array<FindDiagnosisUserById200ResponseFeedbacksItemsInner>}
   * @memberof FindDiagnosisUserById200ResponseFeedbacks
   */
  items: Array<FindDiagnosisUserById200ResponseFeedbacksItemsInner>
  /**
   *
   * @type {number}
   * @memberof FindDiagnosisUserById200ResponseFeedbacks
   */
  total: number
}
/**
 *
 * @export
 * @interface FindDiagnosisUserById200ResponseFeedbacksItemsInner
 */
export interface FindDiagnosisUserById200ResponseFeedbacksItemsInner {
  /**
   *
   * @type {string}
   * @memberof FindDiagnosisUserById200ResponseFeedbacksItemsInner
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof FindDiagnosisUserById200ResponseFeedbacksItemsInner
   */
  title: string
  /**
   *
   * @type {number}
   * @memberof FindDiagnosisUserById200ResponseFeedbacksItemsInner
   */
  viewCount?: number
  /**
   *
   * @type {number}
   * @memberof FindDiagnosisUserById200ResponseFeedbacksItemsInner
   */
  clickCount?: number
}
/**
 *
 * @export
 * @interface FindDiagnosisUserById200ResponseProducts
 */
export interface FindDiagnosisUserById200ResponseProducts {
  /**
   *
   * @type {Array<FindDiagnosisUserById200ResponseProductsItemsInner>}
   * @memberof FindDiagnosisUserById200ResponseProducts
   */
  items: Array<FindDiagnosisUserById200ResponseProductsItemsInner>
  /**
   *
   * @type {number}
   * @memberof FindDiagnosisUserById200ResponseProducts
   */
  total: number
}
/**
 *
 * @export
 * @interface FindDiagnosisUserById200ResponseProductsItemsInner
 */
export interface FindDiagnosisUserById200ResponseProductsItemsInner {
  /**
   *
   * @type {string}
   * @memberof FindDiagnosisUserById200ResponseProductsItemsInner
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof FindDiagnosisUserById200ResponseProductsItemsInner
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof FindDiagnosisUserById200ResponseProductsItemsInner
   */
  brand?: string | null
  /**
   *
   * @type {string}
   * @memberof FindDiagnosisUserById200ResponseProductsItemsInner
   */
  thumbnailUrl?: string | null
  /**
   *
   * @type {number}
   * @memberof FindDiagnosisUserById200ResponseProductsItemsInner
   */
  viewCount?: number
  /**
   *
   * @type {number}
   * @memberof FindDiagnosisUserById200ResponseProductsItemsInner
   */
  clickCount?: number
}
/**
 *
 * @export
 * @interface FindProjectByIdOnUser200Response
 */
export interface FindProjectByIdOnUser200Response {
  /**
   *
   * @type {FindProjectByIdOnUser200ResponseItem}
   * @memberof FindProjectByIdOnUser200Response
   */
  item: FindProjectByIdOnUser200ResponseItem
}
/**
 *
 * @export
 * @interface FindProjectByIdOnUser200ResponseItem
 */
export interface FindProjectByIdOnUser200ResponseItem {
  /**
   *
   * @type {string}
   * @memberof FindProjectByIdOnUser200ResponseItem
   */
  updatedAt: string
  /**
   *
   * @type {FindProjectByIdOnUser200ResponseItemCounselings}
   * @memberof FindProjectByIdOnUser200ResponseItem
   */
  counselings: FindProjectByIdOnUser200ResponseItemCounselings
  /**
   *
   * @type {string}
   * @memberof FindProjectByIdOnUser200ResponseItem
   */
  id: string
  /**
   * 削除日時
   * @type {string}
   * @memberof FindProjectByIdOnUser200ResponseItem
   */
  deletedAt?: string | null
  /**
   *
   * @type {string}
   * @memberof FindProjectByIdOnUser200ResponseItem
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof FindProjectByIdOnUser200ResponseItem
   */
  titleImageUrl?: string | null
  /**
   *
   * @type {string}
   * @memberof FindProjectByIdOnUser200ResponseItem
   */
  thumbnailUrl?: string | null
  /**
   *
   * @type {FetchProject200ResponseItemColor}
   * @memberof FindProjectByIdOnUser200ResponseItem
   */
  color: FetchProject200ResponseItemColor
  /**
   *
   * @type {FetchProjects200ResponseItemsInnerCustomPages}
   * @memberof FindProjectByIdOnUser200ResponseItem
   */
  customPages?: FetchProjects200ResponseItemsInnerCustomPages | null
  /**
   *
   * @type {FetchProject200ResponseItemHistory}
   * @memberof FindProjectByIdOnUser200ResponseItem
   */
  history: FetchProject200ResponseItemHistory
  /**
   *
   * @type {FetchProject200ResponseItemProduct}
   * @memberof FindProjectByIdOnUser200ResponseItem
   */
  product: FetchProject200ResponseItemProduct
  /**
   *
   * @type {FetchProject200ResponseItemFeedback}
   * @memberof FindProjectByIdOnUser200ResponseItem
   */
  feedback: FetchProject200ResponseItemFeedback
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof FindProjectByIdOnUser200ResponseItem
   */
  labels?: { [key: string]: string }
  /**
   *
   * @type {Array<string>}
   * @memberof FindProjectByIdOnUser200ResponseItem
   */
  tips?: Array<string> | null
  /**
   *
   * @type {string}
   * @memberof FindProjectByIdOnUser200ResponseItem
   */
  note?: string | null
  /**
   *
   * @type {FindProjectByIdOnUser200ResponseItemFeatures}
   * @memberof FindProjectByIdOnUser200ResponseItem
   */
  features: FindProjectByIdOnUser200ResponseItemFeatures
}
/**
 *
 * @export
 * @interface FindProjectByIdOnUser200ResponseItemCounselings
 */
export interface FindProjectByIdOnUser200ResponseItemCounselings {}
/**
 * プロジェクトの機能設定
 * @export
 * @interface FindProjectByIdOnUser200ResponseItemFeatures
 */
export interface FindProjectByIdOnUser200ResponseItemFeatures {
  /**
   * ID連携の有効/無効
   * @type {string}
   * @memberof FindProjectByIdOnUser200ResponseItemFeatures
   */
  connectId?: FindProjectByIdOnUser200ResponseItemFeaturesConnectIdEnum
  /**
   * 匿名（年齢・性別の任意化）の有効/無効
   * @type {boolean}
   * @memberof FindProjectByIdOnUser200ResponseItemFeatures
   */
  anonymous?: boolean
  /**
   * ホーム画面として表示するページ
   * @type {string}
   * @memberof FindProjectByIdOnUser200ResponseItemFeatures
   */
  homePage?: FindProjectByIdOnUser200ResponseItemFeaturesHomePageEnum
  /**
   * 顔写真保存の有効/無効
   * @type {boolean}
   * @memberof FindProjectByIdOnUser200ResponseItemFeatures
   */
  saveFacePict?: boolean
  /**
   *
   * @type {FetchProjects200ResponseItemsInnerFeaturesTransferAccountUrl}
   * @memberof FindProjectByIdOnUser200ResponseItemFeatures
   */
  transferAccountUrl?: FetchProjects200ResponseItemsInnerFeaturesTransferAccountUrl
  /**
   * ID連携用のLIFF ID
   * @type {string}
   * @memberof FindProjectByIdOnUser200ResponseItemFeatures
   */
  connectLiffId?: string | null
}

export const FindProjectByIdOnUser200ResponseItemFeaturesConnectIdEnum = {
  Required: 'required',
  Optional: 'optional',
  Disabled: 'disabled',
} as const

export type FindProjectByIdOnUser200ResponseItemFeaturesConnectIdEnum =
  (typeof FindProjectByIdOnUser200ResponseItemFeaturesConnectIdEnum)[keyof typeof FindProjectByIdOnUser200ResponseItemFeaturesConnectIdEnum]
export const FindProjectByIdOnUser200ResponseItemFeaturesHomePageEnum = {
  Result: 'result',
  Shooting: 'shooting',
} as const

export type FindProjectByIdOnUser200ResponseItemFeaturesHomePageEnum =
  (typeof FindProjectByIdOnUser200ResponseItemFeaturesHomePageEnum)[keyof typeof FindProjectByIdOnUser200ResponseItemFeaturesHomePageEnum]

/**
 *
 * @export
 * @interface FindReleaseByActive200Response
 */
export interface FindReleaseByActive200Response {
  /**
   *
   * @type {FindReleaseByActive200ResponseItem}
   * @memberof FindReleaseByActive200Response
   */
  item?: FindReleaseByActive200ResponseItem | null
}
/**
 *
 * @export
 * @interface FindReleaseByActive200ResponseItem
 */
export interface FindReleaseByActive200ResponseItem {
  /**
   *
   * @type {string}
   * @memberof FindReleaseByActive200ResponseItem
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof FindReleaseByActive200ResponseItem
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof FindReleaseByActive200ResponseItem
   */
  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof FindReleaseByActive200ResponseItem
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof FindReleaseByActive200ResponseItem
   */
  projectId: string
  /**
   *
   * @type {Array<CreateRule201ResponseItem>}
   * @memberof FindReleaseByActive200ResponseItem
   */
  rules: Array<CreateRule201ResponseItem>
  /**
   *
   * @type {Array<CreateCounseling201ResponseItem>}
   * @memberof FindReleaseByActive200ResponseItem
   */
  counselings: Array<CreateCounseling201ResponseItem>
  /**
   *
   * @type {boolean}
   * @memberof FindReleaseByActive200ResponseItem
   */
  activated: boolean
  /**
   * 購買データCSVのSHA256ハッシュ値
   * @type {string}
   * @memberof FindReleaseByActive200ResponseItem
   */
  aiModelId?: string | null
}
/**
 *
 * @export
 * @interface FindUpdateRequestById200Response
 */
export interface FindUpdateRequestById200Response {
  /**
   *
   * @type {FindUpdateRequestById200ResponseItem}
   * @memberof FindUpdateRequestById200Response
   */
  item: FindUpdateRequestById200ResponseItem
}
/**
 *
 * @export
 * @interface FindUpdateRequestById200ResponseItem
 */
export interface FindUpdateRequestById200ResponseItem {
  /**
   *
   * @type {string}
   * @memberof FindUpdateRequestById200ResponseItem
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof FindUpdateRequestById200ResponseItem
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof FindUpdateRequestById200ResponseItem
   */
  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof FindUpdateRequestById200ResponseItem
   */
  type: FindUpdateRequestById200ResponseItemTypeEnum
  /**
   *
   * @type {string}
   * @memberof FindUpdateRequestById200ResponseItem
   */
  status?: FindUpdateRequestById200ResponseItemStatusEnum
  /**
   *
   * @type {string}
   * @memberof FindUpdateRequestById200ResponseItem
   */
  rejectReason?: string | null
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof FindUpdateRequestById200ResponseItem
   */
  content: { [key: string]: any }
  /**
   *
   * @type {string}
   * @memberof FindUpdateRequestById200ResponseItem
   */
  applicantEmail: string
  /**
   *
   * @type {FindUpdateRequestById200ResponseItemProject}
   * @memberof FindUpdateRequestById200ResponseItem
   */
  project: FindUpdateRequestById200ResponseItemProject
}

export const FindUpdateRequestById200ResponseItemTypeEnum = {
  Welcomeupdate: 'welcome:update',
  PrivacyPolicyupdate: 'privacyPolicy:update',
  TermsOfServiceupdate: 'termsOfService:update',
} as const

export type FindUpdateRequestById200ResponseItemTypeEnum =
  (typeof FindUpdateRequestById200ResponseItemTypeEnum)[keyof typeof FindUpdateRequestById200ResponseItemTypeEnum]
export const FindUpdateRequestById200ResponseItemStatusEnum = {
  Pending: 'pending',
  Approved: 'approved',
  Rejected: 'rejected',
  Withdraw: 'withdraw',
} as const

export type FindUpdateRequestById200ResponseItemStatusEnum =
  (typeof FindUpdateRequestById200ResponseItemStatusEnum)[keyof typeof FindUpdateRequestById200ResponseItemStatusEnum]

/**
 *
 * @export
 * @interface FindUpdateRequestById200ResponseItemProject
 */
export interface FindUpdateRequestById200ResponseItemProject {
  /**
   *
   * @type {string}
   * @memberof FindUpdateRequestById200ResponseItemProject
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof FindUpdateRequestById200ResponseItemProject
   */
  title: string
}
/**
 *
 * @export
 * @interface GetCustomPage200Response
 */
export interface GetCustomPage200Response {
  /**
   *
   * @type {GetCustomPage200ResponseItem}
   * @memberof GetCustomPage200Response
   */
  item: GetCustomPage200ResponseItem
  /**
   *
   * @type {GetCustomPage200ResponseLatestRequest}
   * @memberof GetCustomPage200Response
   */
  latestRequest?: GetCustomPage200ResponseLatestRequest | null
}
/**
 *
 * @export
 * @interface GetCustomPage200ResponseItem
 */
export interface GetCustomPage200ResponseItem {
  /**
   *
   * @type {string}
   * @memberof GetCustomPage200ResponseItem
   */
  type: GetCustomPage200ResponseItemTypeEnum
  /**
   *
   * @type {string}
   * @memberof GetCustomPage200ResponseItem
   */
  url?: string
  /**
   *
   * @type {string}
   * @memberof GetCustomPage200ResponseItem
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof GetCustomPage200ResponseItem
   */
  content?: string
  /**
   *
   * @type {string}
   * @memberof GetCustomPage200ResponseItem
   */
  btnLabel?: string
  /**
   *
   * @type {string}
   * @memberof GetCustomPage200ResponseItem
   */
  kvPath?: string
}

export const GetCustomPage200ResponseItemTypeEnum = {
  Default: 'default',
  Customize: 'customize',
  External: 'external',
} as const

export type GetCustomPage200ResponseItemTypeEnum =
  (typeof GetCustomPage200ResponseItemTypeEnum)[keyof typeof GetCustomPage200ResponseItemTypeEnum]

/**
 *
 * @export
 * @interface GetCustomPage200ResponseLatestRequest
 */
export interface GetCustomPage200ResponseLatestRequest {
  /**
   *
   * @type {string}
   * @memberof GetCustomPage200ResponseLatestRequest
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof GetCustomPage200ResponseLatestRequest
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof GetCustomPage200ResponseLatestRequest
   */
  updatedAt: string
  /**
   *
   * @type {string}
   * @memberof GetCustomPage200ResponseLatestRequest
   */
  projectId: string
  /**
   *
   * @type {string}
   * @memberof GetCustomPage200ResponseLatestRequest
   */
  type: GetCustomPage200ResponseLatestRequestTypeEnum
  /**
   *
   * @type {string}
   * @memberof GetCustomPage200ResponseLatestRequest
   */
  status?: GetCustomPage200ResponseLatestRequestStatusEnum
  /**
   *
   * @type {string}
   * @memberof GetCustomPage200ResponseLatestRequest
   */
  rejectReason?: string | null
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof GetCustomPage200ResponseLatestRequest
   */
  content: { [key: string]: any }
  /**
   *
   * @type {string}
   * @memberof GetCustomPage200ResponseLatestRequest
   */
  applicantEmail: string
}

export const GetCustomPage200ResponseLatestRequestTypeEnum = {
  Welcomeupdate: 'welcome:update',
  PrivacyPolicyupdate: 'privacyPolicy:update',
  TermsOfServiceupdate: 'termsOfService:update',
} as const

export type GetCustomPage200ResponseLatestRequestTypeEnum =
  (typeof GetCustomPage200ResponseLatestRequestTypeEnum)[keyof typeof GetCustomPage200ResponseLatestRequestTypeEnum]
export const GetCustomPage200ResponseLatestRequestStatusEnum = {
  Pending: 'pending',
  Approved: 'approved',
  Rejected: 'rejected',
  Withdraw: 'withdraw',
} as const

export type GetCustomPage200ResponseLatestRequestStatusEnum =
  (typeof GetCustomPage200ResponseLatestRequestStatusEnum)[keyof typeof GetCustomPage200ResponseLatestRequestStatusEnum]

/**
 *
 * @export
 * @interface GetProvierAccount200Response
 */
export interface GetProvierAccount200Response {
  /**
   *
   * @type {GetProvierAccount200ResponseItem}
   * @memberof GetProvierAccount200Response
   */
  item: GetProvierAccount200ResponseItem
}
/**
 *
 * @export
 * @interface GetProvierAccount200ResponseItem
 */
export interface GetProvierAccount200ResponseItem {
  /**
   *
   * @type {string}
   * @memberof GetProvierAccount200ResponseItem
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof GetProvierAccount200ResponseItem
   */
  displayName: string
  /**
   *
   * @type {string}
   * @memberof GetProvierAccount200ResponseItem
   */
  email: string
  /**
   *
   * @type {Array<GetProvierAccount200ResponseItemProjectsInner>}
   * @memberof GetProvierAccount200ResponseItem
   */
  projects: Array<GetProvierAccount200ResponseItemProjectsInner>
}
/**
 *
 * @export
 * @interface GetProvierAccount200ResponseItemProjectsInner
 */
export interface GetProvierAccount200ResponseItemProjectsInner {
  /**
   *
   * @type {string}
   * @memberof GetProvierAccount200ResponseItemProjectsInner
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof GetProvierAccount200ResponseItemProjectsInner
   */
  title: string
  /**
   *
   * @type {FetchProjects200ResponseItemsInnerBilling}
   * @memberof GetProvierAccount200ResponseItemProjectsInner
   */
  billing?: FetchProjects200ResponseItemsInnerBilling | null
  /**
   *
   * @type {string}
   * @memberof GetProvierAccount200ResponseItemProjectsInner
   */
  thumbnailUrl?: string | null
}
/**
 *
 * @export
 * @interface GetStripePortalUrl200Response
 */
export interface GetStripePortalUrl200Response {
  /**
   *
   * @type {string}
   * @memberof GetStripePortalUrl200Response
   */
  url: string
}
/**
 *
 * @export
 * @interface InitializeUser200Response
 */
export interface InitializeUser200Response {
  /**
   *
   * @type {FetchDiagnosisUsers200ResponseItemsInner}
   * @memberof InitializeUser200Response
   */
  item: FetchDiagnosisUsers200ResponseItemsInner
  /**
   *
   * @type {InitializeUser200ResponseLatestDiagnosis}
   * @memberof InitializeUser200Response
   */
  latestDiagnosis: InitializeUser200ResponseLatestDiagnosis | null
  /**
   *
   * @type {number}
   * @memberof InitializeUser200Response
   */
  serverTimestamp: number
  /**
   *
   * @type {string}
   * @memberof InitializeUser200Response
   */
  accessToken: string
  /**
   *
   * @type {number}
   * @memberof InitializeUser200Response
   */
  expiresIn: number
}
/**
 *
 * @export
 * @interface InitializeUser200ResponseLatestDiagnosis
 */
export interface InitializeUser200ResponseLatestDiagnosis {
  /**
   *
   * @type {FindDiagnosisUserById200ResponseDiagnosesItemsInner}
   * @memberof InitializeUser200ResponseLatestDiagnosis
   */
  item: FindDiagnosisUserById200ResponseDiagnosesItemsInner
  /**
   *
   * @type {DetectSkinWithReleaseDebug200Response}
   * @memberof InitializeUser200ResponseLatestDiagnosis
   */
  rewards: DetectSkinWithReleaseDebug200Response
}
/**
 *
 * @export
 * @interface InitializeUserRequest
 */
export interface InitializeUserRequest {
  /**
   *
   * @type {string}
   * @memberof InitializeUserRequest
   */
  origin: string
  /**
   *
   * @type {string}
   * @memberof InitializeUserRequest
   */
  projectId: string
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof InitializeUserRequest
   */
  dynamicParams?: { [key: string]: any }
  /**
   *
   * @type {string}
   * @memberof InitializeUserRequest
   */
  idToken?: string | null
  /**
   *
   * @type {string}
   * @memberof InitializeUserRequest
   */
  idLinkage?: string | null
}
/**
 *
 * @export
 * @interface RecordUserEventRequest
 */
export interface RecordUserEventRequest {
  /**
   *
   * @type {string}
   * @memberof RecordUserEventRequest
   */
  category: string
  /**
   *
   * @type {string}
   * @memberof RecordUserEventRequest
   */
  action: string
  /**
   *
   * @type {any}
   * @memberof RecordUserEventRequest
   */
  value?: any
}
/**
 *
 * @export
 * @interface RefreshAdminAccessToken200Response
 */
export interface RefreshAdminAccessToken200Response {
  /**
   *
   * @type {number}
   * @memberof RefreshAdminAccessToken200Response
   */
  expiresIn: number
}
/**
 *
 * @export
 * @interface RefreshUserAccessToken200Response
 */
export interface RefreshUserAccessToken200Response {
  /**
   *
   * @type {string}
   * @memberof RefreshUserAccessToken200Response
   */
  accessToken: string
  /**
   *
   * @type {number}
   * @memberof RefreshUserAccessToken200Response
   */
  expiresIn: number
}
/**
 *
 * @export
 * @interface RefreshUserAccessTokenRequest
 */
export interface RefreshUserAccessTokenRequest {
  /**
   *
   * @type {string}
   * @memberof RefreshUserAccessTokenRequest
   */
  oldRefreshToken?: string
}
/**
 *
 * @export
 * @interface RequestCustomPageUpdate201Response
 */
export interface RequestCustomPageUpdate201Response {
  /**
   *
   * @type {GetCustomPage200ResponseLatestRequest}
   * @memberof RequestCustomPageUpdate201Response
   */
  item: GetCustomPage200ResponseLatestRequest | null
}
/**
 *
 * @export
 * @interface RequestProviderPasswordResetRequest
 */
export interface RequestProviderPasswordResetRequest {
  /**
   *
   * @type {string}
   * @memberof RequestProviderPasswordResetRequest
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof RequestProviderPasswordResetRequest
   */
  recaptchaToken: string
}
/**
 *
 * @export
 * @interface SendInquiryRequest
 */
export interface SendInquiryRequest {
  /**
   * 氏名
   * @type {string}
   * @memberof SendInquiryRequest
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof SendInquiryRequest
   */
  email: string
  /**
   * 会社名
   * @type {string}
   * @memberof SendInquiryRequest
   */
  company?: string
  /**
   * 役職
   * @type {string}
   * @memberof SendInquiryRequest
   */
  position?: string
  /**
   * お問い合わせ内容
   * @type {string}
   * @memberof SendInquiryRequest
   */
  subject: string
  /**
   *
   * @type {string}
   * @memberof SendInquiryRequest
   */
  userId?: string
  /**
   * お問い合わせ本文
   * @type {string}
   * @memberof SendInquiryRequest
   */
  message: string
  /**
   * reCAPTCHA用のトークン
   * @type {string}
   * @memberof SendInquiryRequest
   */
  recaptchaToken: string
}
/**
 *
 * @export
 * @interface SigninAdmin201Response
 */
export interface SigninAdmin201Response {
  /**
   *
   * @type {string}
   * @memberof SigninAdmin201Response
   */
  refreshToken: string
  /**
   *
   * @type {number}
   * @memberof SigninAdmin201Response
   */
  expiresIn: number
}
/**
 *
 * @export
 * @interface SigninAdminRequest
 */
export interface SigninAdminRequest {
  /**
   *
   * @type {string}
   * @memberof SigninAdminRequest
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof SigninAdminRequest
   */
  password: string
  /**
   *
   * @type {string}
   * @memberof SigninAdminRequest
   */
  recaptchaToken: string
}
/**
 *
 * @export
 * @interface SigninProvider200Response
 */
export interface SigninProvider200Response {
  /**
   *
   * @type {string}
   * @memberof SigninProvider200Response
   */
  refreshToken: string
  /**
   *
   * @type {number}
   * @memberof SigninProvider200Response
   */
  expiresIn: number
}
/**
 *
 * @export
 * @interface SwitchActiveProjectRequest
 */
export interface SwitchActiveProjectRequest {
  /**
   *
   * @type {string}
   * @memberof SwitchActiveProjectRequest
   */
  id: string
}
/**
 *
 * @export
 * @interface UpdateProductImageRequest
 */
export interface UpdateProductImageRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateProductImageRequest
   */
  thumbnailData: string
}
/**
 *
 * @export
 * @interface UpdateProjectOnAdminRequest
 */
export interface UpdateProjectOnAdminRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateProjectOnAdminRequest
   */
  title?: string
  /**
   *
   * @type {FetchProjects200ResponseItemsInnerEnabledFeatures}
   * @memberof UpdateProjectOnAdminRequest
   */
  enabledFeatures?: FetchProjects200ResponseItemsInnerEnabledFeatures
  /**
   *
   * @type {string}
   * @memberof UpdateProjectOnAdminRequest
   */
  note?: string | null
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateProjectOnAdminRequest
   */
  providers?: Array<string>
}
/**
 *
 * @export
 * @interface UpdateProjectRequest
 */
export interface UpdateProjectRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateProjectRequest
   */
  title?: string
  /**
   *
   * @type {FetchProject200ResponseItemColor}
   * @memberof UpdateProjectRequest
   */
  color?: FetchProject200ResponseItemColor
  /**
   *
   * @type {FetchProjects200ResponseItemsInnerFeatures}
   * @memberof UpdateProjectRequest
   */
  features?: FetchProjects200ResponseItemsInnerFeatures
  /**
   *
   * @type {FetchProject200ResponseItemHistory}
   * @memberof UpdateProjectRequest
   */
  history?: FetchProject200ResponseItemHistory
  /**
   *
   * @type {FetchProject200ResponseItemProduct}
   * @memberof UpdateProjectRequest
   */
  product?: FetchProject200ResponseItemProduct
  /**
   *
   * @type {FetchProject200ResponseItemFeedback}
   * @memberof UpdateProjectRequest
   */
  feedback?: FetchProject200ResponseItemFeedback
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof UpdateProjectRequest
   */
  labels?: { [key: string]: string }
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateProjectRequest
   */
  tips?: Array<string> | null
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateProjectRequest
   */
  siteDomains?: Array<string>
  /**
   *
   * @type {string}
   * @memberof UpdateProjectRequest
   */
  thumbnailData?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateProjectRequest
   */
  titleImageData?: string | null
}
/**
 *
 * @export
 * @interface UpdateProviderPasswordResetRequest
 */
export interface UpdateProviderPasswordResetRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateProviderPasswordResetRequest
   */
  token: string
  /**
   *
   * @type {string}
   * @memberof UpdateProviderPasswordResetRequest
   */
  password: string
  /**
   *
   * @type {string}
   * @memberof UpdateProviderPasswordResetRequest
   */
  recaptchaToken: string
}
/**
 *
 * @export
 * @interface UpdateProviderRequest
 */
export interface UpdateProviderRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateProviderRequest
   */
  displayName?: string
  /**
   *
   * @type {string}
   * @memberof UpdateProviderRequest
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof UpdateProviderRequest
   */
  password?: string
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateProviderRequest
   */
  projects?: Array<string>
}
/**
 *
 * @export
 * @interface UpdateProvierAccountRequest
 */
export interface UpdateProvierAccountRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateProvierAccountRequest
   */
  displayName: string
  /**
   *
   * @type {string}
   * @memberof UpdateProvierAccountRequest
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof UpdateProvierAccountRequest
   */
  password: string
  /**
   *
   * @type {string}
   * @memberof UpdateProvierAccountRequest
   */
  newPassword?: string
}
/**
 *
 * @export
 * @interface UpdateReleaseRequest
 */
export interface UpdateReleaseRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateReleaseRequest
   */
  title?: string
  /**
   *
   * @type {boolean}
   * @memberof UpdateReleaseRequest
   */
  activated?: boolean
  /**
   *
   * @type {Array<CreateReleaseRequestRulesInner>}
   * @memberof UpdateReleaseRequest
   */
  rules?: Array<CreateReleaseRequestRulesInner>
  /**
   *
   * @type {Array<CreateReleaseRequestRulesInner>}
   * @memberof UpdateReleaseRequest
   */
  counselings?: Array<CreateReleaseRequestRulesInner>
  /**
   * 購買データCSVのSHA256ハッシュ値
   * @type {string}
   * @memberof UpdateReleaseRequest
   */
  aiModelId?: string | null
}
/**
 *
 * @export
 * @interface UpdateRuleRequest
 */
export interface UpdateRuleRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateRuleRequest
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof UpdateRuleRequest
   */
  ruleLogicalOperator?: UpdateRuleRequestRuleLogicalOperatorEnum
  /**
   *
   * @type {Array<FetchRules200ResponseItemsInnerConditionsInner>}
   * @memberof UpdateRuleRequest
   */
  conditions?: Array<FetchRules200ResponseItemsInnerConditionsInner>
  /**
   *
   * @type {Array<FetchRules200ResponseItemsInnerRewardProductsInner>}
   * @memberof UpdateRuleRequest
   */
  rewardProducts?: Array<FetchRules200ResponseItemsInnerRewardProductsInner>
  /**
   *
   * @type {Array<FetchRules200ResponseItemsInnerRewardProductsInner>}
   * @memberof UpdateRuleRequest
   */
  rewardFeedbacks?: Array<FetchRules200ResponseItemsInnerRewardProductsInner>
}

export const UpdateRuleRequestRuleLogicalOperatorEnum = {
  And: 'and',
  Or: 'or',
} as const

export type UpdateRuleRequestRuleLogicalOperatorEnum =
  (typeof UpdateRuleRequestRuleLogicalOperatorEnum)[keyof typeof UpdateRuleRequestRuleLogicalOperatorEnum]

/**
 *
 * @export
 * @interface UpdateUpdateRequestStatusRequest
 */
export interface UpdateUpdateRequestStatusRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateUpdateRequestStatusRequest
   */
  status: UpdateUpdateRequestStatusRequestStatusEnum
  /**
   *
   * @type {string}
   * @memberof UpdateUpdateRequestStatusRequest
   */
  rejectReason?: string
}

export const UpdateUpdateRequestStatusRequestStatusEnum = {
  Approved: 'approved',
  Rejected: 'rejected',
} as const

export type UpdateUpdateRequestStatusRequestStatusEnum =
  (typeof UpdateUpdateRequestStatusRequestStatusEnum)[keyof typeof UpdateUpdateRequestStatusRequestStatusEnum]

/**
 *
 * @export
 * @interface UpdateUserCustomAttributeRequest
 */
export interface UpdateUserCustomAttributeRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateUserCustomAttributeRequest
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof UpdateUserCustomAttributeRequest
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof UpdateUserCustomAttributeRequest
   */
  type?: UpdateUserCustomAttributeRequestTypeEnum
}

export const UpdateUserCustomAttributeRequestTypeEnum = {
  String: 'String',
  Number: 'Number',
  Boolean: 'Boolean',
  Date: 'Date',
} as const

export type UpdateUserCustomAttributeRequestTypeEnum =
  (typeof UpdateUserCustomAttributeRequestTypeEnum)[keyof typeof UpdateUserCustomAttributeRequestTypeEnum]

/**
 *
 * @export
 * @interface UpdateUserProfile200Response
 */
export interface UpdateUserProfile200Response {
  /**
   *
   * @type {FetchDiagnosisUsers200ResponseItemsInner}
   * @memberof UpdateUserProfile200Response
   */
  item: FetchDiagnosisUsers200ResponseItemsInner
}
/**
 *
 * @export
 * @interface UpdateUserProfileRequest
 */
export interface UpdateUserProfileRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateUserProfileRequest
   */
  displayName?: string
  /**
   *
   * @type {string}
   * @memberof UpdateUserProfileRequest
   */
  gender?: string
  /**
   *
   * @type {string}
   * @memberof UpdateUserProfileRequest
   */
  birthDate?: string
  /**
   *
   * @type {string}
   * @memberof UpdateUserProfileRequest
   */
  thumbnailData?: string | null
}
/**
 *
 * @export
 * @interface UploadCSVFileMultiparts201Response
 */
export interface UploadCSVFileMultiparts201Response {
  /**
   *
   * @type {boolean}
   * @memberof UploadCSVFileMultiparts201Response
   */
  success: boolean
  /**
   *
   * @type {string}
   * @memberof UploadCSVFileMultiparts201Response
   */
  purpose: UploadCSVFileMultiparts201ResponsePurposeEnum
  /**
   *
   * @type {string}
   * @memberof UploadCSVFileMultiparts201Response
   */
  id: string
}

export const UploadCSVFileMultiparts201ResponsePurposeEnum = {
  AiRecommendationModel: 'aiRecommendationModel',
} as const

export type UploadCSVFileMultiparts201ResponsePurposeEnum =
  (typeof UploadCSVFileMultiparts201ResponsePurposeEnum)[keyof typeof UploadCSVFileMultiparts201ResponsePurposeEnum]

/**
 *
 * @export
 * @interface UploadCSVFileMultiparts201ResponseAnyOf
 */
export interface UploadCSVFileMultiparts201ResponseAnyOf {
  /**
   *
   * @type {boolean}
   * @memberof UploadCSVFileMultiparts201ResponseAnyOf
   */
  success: boolean
  /**
   *
   * @type {string}
   * @memberof UploadCSVFileMultiparts201ResponseAnyOf
   */
  purpose: UploadCSVFileMultiparts201ResponseAnyOfPurposeEnum
  /**
   *
   * @type {string}
   * @memberof UploadCSVFileMultiparts201ResponseAnyOf
   */
  id: string
}

export const UploadCSVFileMultiparts201ResponseAnyOfPurposeEnum = {
  AiRecommendationModel: 'aiRecommendationModel',
} as const

export type UploadCSVFileMultiparts201ResponseAnyOfPurposeEnum =
  (typeof UploadCSVFileMultiparts201ResponseAnyOfPurposeEnum)[keyof typeof UploadCSVFileMultiparts201ResponseAnyOfPurposeEnum]

/**
 *
 * @export
 * @interface VerifyUserConnectId200Response
 */
export interface VerifyUserConnectId200Response {
  /**
   *
   * @type {boolean}
   * @memberof VerifyUserConnectId200Response
   */
  valid: boolean
  /**
   *
   * @type {boolean}
   * @memberof VerifyUserConnectId200Response
   */
  hasAccount: boolean
}
/**
 *
 * @export
 * @interface VerifyUserConnectIdRequest
 */
export interface VerifyUserConnectIdRequest {
  /**
   *
   * @type {string}
   * @memberof VerifyUserConnectIdRequest
   */
  projectId: string
  /**
   *
   * @type {string}
   * @memberof VerifyUserConnectIdRequest
   */
  idToken?: string | null
  /**
   *
   * @type {string}
   * @memberof VerifyUserConnectIdRequest
   */
  idLinkage?: string | null
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary プロジェクトの新規作成
     * @param {CreateProjectRequest} createProjectRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProject: async (
      createProjectRequest: CreateProjectRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createProjectRequest' is not null or undefined
      assertParamExists(
        'createProject',
        'createProjectRequest',
        createProjectRequest
      )
      const localVarPath = `/v1/admin/projects`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createProjectRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary プロバイダーの新規作成
     * @param {CreateProviderRequest} createProviderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProvider: async (
      createProviderRequest: CreateProviderRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createProviderRequest' is not null or undefined
      assertParamExists(
        'createProvider',
        'createProviderRequest',
        createProviderRequest
      )
      const localVarPath = `/v1/admin/providers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createProviderRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary プロジェクトの削除
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProjectOnAdmin: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteProjectOnAdmin', 'id', id)
      const localVarPath = `/v1/admin/projects/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary プロバイダーの削除
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProvider: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteProvider', 'id', id)
      const localVarPath = `/v1/admin/providers/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary プロジェクトの一覧取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchProjects: async (
      limit?: number,
      offset?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/admin/projects`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary プロバイダーの一覧取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchProviders: async (
      limit?: number,
      offset?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/admin/providers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 更新申請の一覧取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchUpdateRequests: async (
      limit?: number,
      offset?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/admin/update-request`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary プロジェクト詳細の取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findProjectByIdOnAdmin: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('findProjectByIdOnAdmin', 'id', id)
      const localVarPath = `/v1/admin/projects/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary プロバイダーの詳細取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findProviderById: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('findProviderById', 'id', id)
      const localVarPath = `/v1/admin/providers/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 更新申請の取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findUpdateRequestById: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('findUpdateRequestById', 'id', id)
      const localVarPath = `/v1/admin/update-request/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary アクセストークンの更新
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshAdminAccessToken: async (
      body?: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/admin/accessToken`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 管理者権限のサインイン
     * @param {SigninAdminRequest} signinAdminRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signinAdmin: async (
      signinAdminRequest: SigninAdminRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'signinAdminRequest' is not null or undefined
      assertParamExists('signinAdmin', 'signinAdminRequest', signinAdminRequest)
      const localVarPath = `/v1/admin/signin`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        signinAdminRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 管理者権限のサインアウト
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signoutAdmin: async (
      body?: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/admin/signout`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary プロジェクトの更新
     * @param {string} id
     * @param {UpdateProjectOnAdminRequest} [updateProjectOnAdminRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProjectOnAdmin: async (
      id: string,
      updateProjectOnAdminRequest?: UpdateProjectOnAdminRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateProjectOnAdmin', 'id', id)
      const localVarPath = `/v1/admin/projects/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateProjectOnAdminRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary プロバイダーの更新
     * @param {string} id
     * @param {UpdateProviderRequest} [updateProviderRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProvider: async (
      id: string,
      updateProviderRequest?: UpdateProviderRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateProvider', 'id', id)
      const localVarPath = `/v1/admin/providers/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateProviderRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 更新申請の承認/却下
     * @param {string} id
     * @param {UpdateUpdateRequestStatusRequest} updateUpdateRequestStatusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUpdateRequestStatus: async (
      id: string,
      updateUpdateRequestStatusRequest: UpdateUpdateRequestStatusRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateUpdateRequestStatus', 'id', id)
      // verify required parameter 'updateUpdateRequestStatusRequest' is not null or undefined
      assertParamExists(
        'updateUpdateRequestStatus',
        'updateUpdateRequestStatusRequest',
        updateUpdateRequestStatusRequest
      )
      const localVarPath = `/v1/admin/update-request/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUpdateRequestStatusRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary プロジェクトの新規作成
     * @param {CreateProjectRequest} createProjectRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createProject(
      createProjectRequest: CreateProjectRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateProject201Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createProject(
        createProjectRequest,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary プロバイダーの新規作成
     * @param {CreateProviderRequest} createProviderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createProvider(
      createProviderRequest: CreateProviderRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateProvider201Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createProvider(
        createProviderRequest,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary プロジェクトの削除
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteProjectOnAdmin(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteProjectOnAdmin(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary プロバイダーの削除
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteProvider(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProvider(
        id,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary プロジェクトの一覧取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchProjects(
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FetchProjects200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fetchProjects(
        limit,
        offset,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary プロバイダーの一覧取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchProviders(
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FetchProviders200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fetchProviders(
        limit,
        offset,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 更新申請の一覧取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchUpdateRequests(
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FetchUpdateRequests200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchUpdateRequests(
          limit,
          offset,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary プロジェクト詳細の取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findProjectByIdOnAdmin(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateProject201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findProjectByIdOnAdmin(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary プロバイダーの詳細取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findProviderById(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateProvider201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findProviderById(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 更新申請の取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findUpdateRequestById(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FindUpdateRequestById200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findUpdateRequestById(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary アクセストークンの更新
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshAdminAccessToken(
      body?: object,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<RefreshAdminAccessToken200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.refreshAdminAccessToken(body, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 管理者権限のサインイン
     * @param {SigninAdminRequest} signinAdminRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async signinAdmin(
      signinAdminRequest: SigninAdminRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<SigninAdmin201Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.signinAdmin(
        signinAdminRequest,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 管理者権限のサインアウト
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async signoutAdmin(
      body?: object,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.signoutAdmin(
        body,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary プロジェクトの更新
     * @param {string} id
     * @param {UpdateProjectOnAdminRequest} [updateProjectOnAdminRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateProjectOnAdmin(
      id: string,
      updateProjectOnAdminRequest?: UpdateProjectOnAdminRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateProject201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateProjectOnAdmin(
          id,
          updateProjectOnAdminRequest,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary プロバイダーの更新
     * @param {string} id
     * @param {UpdateProviderRequest} [updateProviderRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateProvider(
      id: string,
      updateProviderRequest?: UpdateProviderRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateProvider201Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateProvider(
        id,
        updateProviderRequest,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 更新申請の承認/却下
     * @param {string} id
     * @param {UpdateUpdateRequestStatusRequest} updateUpdateRequestStatusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUpdateRequestStatus(
      id: string,
      updateUpdateRequestStatusRequest: UpdateUpdateRequestStatusRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FindUpdateRequestById200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateUpdateRequestStatus(
          id,
          updateUpdateRequestStatusRequest,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AdminApiFp(configuration)
  return {
    /**
     *
     * @summary プロジェクトの新規作成
     * @param {CreateProjectRequest} createProjectRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProject(
      createProjectRequest: CreateProjectRequest,
      options?: any
    ): AxiosPromise<CreateProject201Response> {
      return localVarFp
        .createProject(createProjectRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary プロバイダーの新規作成
     * @param {CreateProviderRequest} createProviderRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProvider(
      createProviderRequest: CreateProviderRequest,
      options?: any
    ): AxiosPromise<CreateProvider201Response> {
      return localVarFp
        .createProvider(createProviderRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary プロジェクトの削除
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProjectOnAdmin(id: string, options?: any): AxiosPromise<object> {
      return localVarFp
        .deleteProjectOnAdmin(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary プロバイダーの削除
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProvider(id: string, options?: any): AxiosPromise<string> {
      return localVarFp
        .deleteProvider(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary プロジェクトの一覧取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchProjects(
      limit?: number,
      offset?: number,
      options?: any
    ): AxiosPromise<FetchProjects200Response> {
      return localVarFp
        .fetchProjects(limit, offset, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary プロバイダーの一覧取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchProviders(
      limit?: number,
      offset?: number,
      options?: any
    ): AxiosPromise<FetchProviders200Response> {
      return localVarFp
        .fetchProviders(limit, offset, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 更新申請の一覧取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchUpdateRequests(
      limit?: number,
      offset?: number,
      options?: any
    ): AxiosPromise<FetchUpdateRequests200Response> {
      return localVarFp
        .fetchUpdateRequests(limit, offset, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary プロジェクト詳細の取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findProjectByIdOnAdmin(
      id: string,
      options?: any
    ): AxiosPromise<CreateProject201Response> {
      return localVarFp
        .findProjectByIdOnAdmin(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary プロバイダーの詳細取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findProviderById(
      id: string,
      options?: any
    ): AxiosPromise<CreateProvider201Response> {
      return localVarFp
        .findProviderById(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 更新申請の取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findUpdateRequestById(
      id: string,
      options?: any
    ): AxiosPromise<FindUpdateRequestById200Response> {
      return localVarFp
        .findUpdateRequestById(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary アクセストークンの更新
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshAdminAccessToken(
      body?: object,
      options?: any
    ): AxiosPromise<RefreshAdminAccessToken200Response> {
      return localVarFp
        .refreshAdminAccessToken(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 管理者権限のサインイン
     * @param {SigninAdminRequest} signinAdminRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signinAdmin(
      signinAdminRequest: SigninAdminRequest,
      options?: any
    ): AxiosPromise<SigninAdmin201Response> {
      return localVarFp
        .signinAdmin(signinAdminRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 管理者権限のサインアウト
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signoutAdmin(body?: object, options?: any): AxiosPromise<string> {
      return localVarFp
        .signoutAdmin(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary プロジェクトの更新
     * @param {string} id
     * @param {UpdateProjectOnAdminRequest} [updateProjectOnAdminRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProjectOnAdmin(
      id: string,
      updateProjectOnAdminRequest?: UpdateProjectOnAdminRequest,
      options?: any
    ): AxiosPromise<CreateProject201Response> {
      return localVarFp
        .updateProjectOnAdmin(id, updateProjectOnAdminRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary プロバイダーの更新
     * @param {string} id
     * @param {UpdateProviderRequest} [updateProviderRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProvider(
      id: string,
      updateProviderRequest?: UpdateProviderRequest,
      options?: any
    ): AxiosPromise<CreateProvider201Response> {
      return localVarFp
        .updateProvider(id, updateProviderRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 更新申請の承認/却下
     * @param {string} id
     * @param {UpdateUpdateRequestStatusRequest} updateUpdateRequestStatusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUpdateRequestStatus(
      id: string,
      updateUpdateRequestStatusRequest: UpdateUpdateRequestStatusRequest,
      options?: any
    ): AxiosPromise<FindUpdateRequestById200Response> {
      return localVarFp
        .updateUpdateRequestStatus(
          id,
          updateUpdateRequestStatusRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
  /**
   *
   * @summary プロジェクトの新規作成
   * @param {CreateProjectRequest} createProjectRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public createProject(
    createProjectRequest: CreateProjectRequest,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .createProject(createProjectRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary プロバイダーの新規作成
   * @param {CreateProviderRequest} createProviderRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public createProvider(
    createProviderRequest: CreateProviderRequest,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .createProvider(createProviderRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary プロジェクトの削除
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public deleteProjectOnAdmin(id: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .deleteProjectOnAdmin(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary プロバイダーの削除
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public deleteProvider(id: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .deleteProvider(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary プロジェクトの一覧取得
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public fetchProjects(
    limit?: number,
    offset?: number,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .fetchProjects(limit, offset, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary プロバイダーの一覧取得
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public fetchProviders(
    limit?: number,
    offset?: number,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .fetchProviders(limit, offset, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 更新申請の一覧取得
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public fetchUpdateRequests(
    limit?: number,
    offset?: number,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .fetchUpdateRequests(limit, offset, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary プロジェクト詳細の取得
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public findProjectByIdOnAdmin(id: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .findProjectByIdOnAdmin(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary プロバイダーの詳細取得
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public findProviderById(id: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .findProviderById(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 更新申請の取得
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public findUpdateRequestById(id: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .findUpdateRequestById(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary アクセストークンの更新
   * @param {object} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public refreshAdminAccessToken(body?: object, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .refreshAdminAccessToken(body, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 管理者権限のサインイン
   * @param {SigninAdminRequest} signinAdminRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public signinAdmin(
    signinAdminRequest: SigninAdminRequest,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .signinAdmin(signinAdminRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 管理者権限のサインアウト
   * @param {object} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public signoutAdmin(body?: object, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .signoutAdmin(body, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary プロジェクトの更新
   * @param {string} id
   * @param {UpdateProjectOnAdminRequest} [updateProjectOnAdminRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public updateProjectOnAdmin(
    id: string,
    updateProjectOnAdminRequest?: UpdateProjectOnAdminRequest,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .updateProjectOnAdmin(id, updateProjectOnAdminRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary プロバイダーの更新
   * @param {string} id
   * @param {UpdateProviderRequest} [updateProviderRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public updateProvider(
    id: string,
    updateProviderRequest?: UpdateProviderRequest,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .updateProvider(id, updateProviderRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 更新申請の承認/却下
   * @param {string} id
   * @param {UpdateUpdateRequestStatusRequest} updateUpdateRequestStatusRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public updateUpdateRequestStatus(
    id: string,
    updateUpdateRequestStatusRequest: UpdateUpdateRequestStatusRequest,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .updateUpdateRequestStatus(id, updateUpdateRequestStatusRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * ProviderApi - axios parameter creator
 * @export
 */
export const ProviderApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary ヘルスチェック
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkHelth: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary AIレコメンドモデルの新規作成
     * @param {CreateAiRecommendationModelRequest} createAiRecommendationModelRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAiRecommendationModel: async (
      createAiRecommendationModelRequest: CreateAiRecommendationModelRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createAiRecommendationModelRequest' is not null or undefined
      assertParamExists(
        'createAiRecommendationModel',
        'createAiRecommendationModelRequest',
        createAiRecommendationModelRequest
      )
      const localVarPath = `/v1/provider/ai-recommendation-models`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createAiRecommendationModelRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary カウンセリグの新規作成
     * @param {CreateCounselingRequest} createCounselingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCounseling: async (
      createCounselingRequest: CreateCounselingRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createCounselingRequest' is not null or undefined
      assertParamExists(
        'createCounseling',
        'createCounselingRequest',
        createCounselingRequest
      )
      const localVarPath = `/v1/provider/counselings`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createCounselingRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary フィードバックの新規作成
     * @param {CreateFeedbackRequest} createFeedbackRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFeedback: async (
      createFeedbackRequest: CreateFeedbackRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createFeedbackRequest' is not null or undefined
      assertParamExists(
        'createFeedback',
        'createFeedbackRequest',
        createFeedbackRequest
      )
      const localVarPath = `/v1/provider/feedbacks`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createFeedbackRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 商品の新規作成
     * @param {CreateProductsRequestItemsInner} createProductsRequestItemsInner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProduct: async (
      createProductsRequestItemsInner: CreateProductsRequestItemsInner,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createProductsRequestItemsInner' is not null or undefined
      assertParamExists(
        'createProduct',
        'createProductsRequestItemsInner',
        createProductsRequestItemsInner
      )
      const localVarPath = `/v1/provider/products`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createProductsRequestItemsInner,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 商品カテゴリの新規作成
     * @param {CreateProductCategoryRequest} createProductCategoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProductCategory: async (
      createProductCategoryRequest: CreateProductCategoryRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createProductCategoryRequest' is not null or undefined
      assertParamExists(
        'createProductCategory',
        'createProductCategoryRequest',
        createProductCategoryRequest
      )
      const localVarPath = `/v1/provider/products/categories`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createProductCategoryRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 商品の一括登録
     * @param {CreateProductsRequest} createProductsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProducts: async (
      createProductsRequest: CreateProductsRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createProductsRequest' is not null or undefined
      assertParamExists(
        'createProducts',
        'createProductsRequest',
        createProductsRequest
      )
      const localVarPath = `/v1/provider/products/bulk`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createProductsRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary リリースの新規作成
     * @param {CreateReleaseRequest} createReleaseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRelease: async (
      createReleaseRequest: CreateReleaseRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createReleaseRequest' is not null or undefined
      assertParamExists(
        'createRelease',
        'createReleaseRequest',
        createReleaseRequest
      )
      const localVarPath = `/v1/provider/releases`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createReleaseRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary ルールの新規作成
     * @param {CreateRuleRequest} createRuleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRule: async (
      createRuleRequest: CreateRuleRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createRuleRequest' is not null or undefined
      assertParamExists('createRule', 'createRuleRequest', createRuleRequest)
      const localVarPath = `/v1/provider/rules`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createRuleRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary プロジェクトに紐づくStripeのカスタマ情報を登録する
     * @param {CreateStripeCustomerRequest} createStripeCustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createStripeCustomer: async (
      createStripeCustomerRequest: CreateStripeCustomerRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createStripeCustomerRequest' is not null or undefined
      assertParamExists(
        'createStripeCustomer',
        'createStripeCustomerRequest',
        createStripeCustomerRequest
      )
      const localVarPath = `/v1/provider/billing/stripe`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createStripeCustomerRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary カスタムユーザー属性の新規作成
     * @param {CreateUserCustomAttributeRequest} createUserCustomAttributeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserCustomAttribute: async (
      createUserCustomAttributeRequest: CreateUserCustomAttributeRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createUserCustomAttributeRequest' is not null or undefined
      assertParamExists(
        'createUserCustomAttribute',
        'createUserCustomAttributeRequest',
        createUserCustomAttributeRequest
      )
      const localVarPath = `/v1/provider/user-custom-attributes`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createUserCustomAttributeRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary AIレコメンドモデルの削除
     * @param {string} id 購買データCSVのSHA256ハッシュ値
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAiRecommendationModel: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteAiRecommendationModel', 'id', id)
      const localVarPath = `/v1/provider/ai-recommendation-models/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary カウンセリングの削除
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCounseling: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteCounseling', 'id', id)
      const localVarPath = `/v1/provider/counselings/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary フィードバックの削除
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFeedback: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteFeedback', 'id', id)
      const localVarPath = `/v1/provider/feedbacks/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 商品の削除
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProduct: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteProduct', 'id', id)
      const localVarPath = `/v1/provider/products/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 商品の削除
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProductCategory: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteProductCategory', 'id', id)
      const localVarPath = `/v1/provider/products/categories/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary リリースの削除
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRelease: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteRelease', 'id', id)
      const localVarPath = `/v1/provider/releases/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary ルールの削除
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRule: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteRule', 'id', id)
      const localVarPath = `/v1/provider/rules/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary カスタムユーザー属性の削除
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserCustomAttribute: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteUserCustomAttribute', 'id', id)
      const localVarPath = `/v1/provider/user-custom-attributes/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 特定リリースを使った診断結果のデバッグ
     * @param {string} id
     * @param {DetectSkinWithReleaseDebugRequest} detectSkinWithReleaseDebugRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    detectSkinWithReleaseDebug: async (
      id: string,
      detectSkinWithReleaseDebugRequest: DetectSkinWithReleaseDebugRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('detectSkinWithReleaseDebug', 'id', id)
      // verify required parameter 'detectSkinWithReleaseDebugRequest' is not null or undefined
      assertParamExists(
        'detectSkinWithReleaseDebug',
        'detectSkinWithReleaseDebugRequest',
        detectSkinWithReleaseDebugRequest
      )
      const localVarPath = `/v1/provider/releases/{id}/debug`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        detectSkinWithReleaseDebugRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary このプロジェクトで診断された診断履歴をCSVでダウンロード
     * @param {string} before
     * @param {string} after
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadDiagnoisRecordsCSV: async (
      before: string,
      after: string,
      limit?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'before' is not null or undefined
      assertParamExists('downloadDiagnoisRecordsCSV', 'before', before)
      // verify required parameter 'after' is not null or undefined
      assertParamExists('downloadDiagnoisRecordsCSV', 'after', after)
      const localVarPath = `/v1/provider/users/diagnoses/csv`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (before !== undefined) {
        localVarQueryParameter['before'] =
          (before as any) instanceof Date
            ? (before as any).toISOString()
            : before
      }

      if (after !== undefined) {
        localVarQueryParameter['after'] =
          (after as any) instanceof Date ? (after as any).toISOString() : after
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary カウンセリングの複製
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    duplicateCounseling: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('duplicateCounseling', 'id', id)
      const localVarPath = `/v1/provider/counselings/{id}/duplicate`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary フィードバックの複製
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    duplicateFeedback: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('duplicateFeedback', 'id', id)
      const localVarPath = `/v1/provider/feedbacks/{id}/duplicate`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 商品の複製
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    duplicateProduct: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('duplicateProduct', 'id', id)
      const localVarPath = `/v1/provider/products/{id}/duplicate`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary リリースの複製
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    duplicateRelease: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('duplicateRelease', 'id', id)
      const localVarPath = `/v1/provider/releases/{id}/duplicate`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary ルールの複製
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    duplicateRule: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('duplicateRule', 'id', id)
      const localVarPath = `/v1/provider/rules/{id}/duplicate`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary カスタムユーザー属性の複製
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    duplicateUserCustomAttribute: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('duplicateUserCustomAttribute', 'id', id)
      const localVarPath =
        `/v1/provider/user-custom-attributes/{id}/duplicate`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(id))
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary AIレコメンドモデルの一覧取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchAiRecommendationModels: async (
      limit?: number,
      offset?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/provider/ai-recommendation-models`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary カウンセリングの一覧取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {any} [excludes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchCounselings: async (
      limit?: number,
      offset?: number,
      excludes?: any,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/provider/counselings`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      if (excludes !== undefined) {
        localVarQueryParameter['excludes'] = excludes
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary ダッシュボードで使用する情報を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchDashboard: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/provider/dashboard`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 診断ユーザーに紐づくフィードバック閲覧ログの取得
     * @param {string} id
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchDiagnosisUserFeedbackLogs: async (
      id: string,
      limit?: number,
      offset?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('fetchDiagnosisUserFeedbackLogs', 'id', id)
      const localVarPath = `/v1/provider/users/{id}/feedbacks`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 診断ユーザーに紐づく診断履歴の取得
     * @param {string} id
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchDiagnosisUserHistories: async (
      id: string,
      limit?: number,
      offset?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('fetchDiagnosisUserHistories', 'id', id)
      const localVarPath = `/v1/provider/users/{id}/diagnoses`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 診断ユーザーに紐づく商品閲覧ログの取得
     * @param {string} id
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchDiagnosisUserProductLogs: async (
      id: string,
      limit?: number,
      offset?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('fetchDiagnosisUserProductLogs', 'id', id)
      const localVarPath = `/v1/provider/users/{id}/products`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 診断ユーザーの一覧取得
     * @param {number} limit
     * @param {string} [nextKey]
     * @param {'Connected' | 'Reject' | 'Request' | 'Resolve' | 'None'} [status]
     * @param {string} [connectId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchDiagnosisUsers: async (
      limit: number,
      nextKey?: string,
      status?: 'Connected' | 'Reject' | 'Request' | 'Resolve' | 'None',
      connectId?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('fetchDiagnosisUsers', 'limit', limit)
      const localVarPath = `/v1/provider/users`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (nextKey !== undefined) {
        localVarQueryParameter['nextKey'] = nextKey
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status
      }

      if (connectId !== undefined) {
        localVarQueryParameter['connectId'] = connectId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary フィードバックの一覧取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {any} [excludes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchFeedbacks: async (
      limit?: number,
      offset?: number,
      excludes?: any,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/provider/feedbacks`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      if (excludes !== undefined) {
        localVarQueryParameter['excludes'] = excludes
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 商品カテゴリ一覧を取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchProductCategories: async (
      limit?: number,
      offset?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/provider/products/categories`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 商品タグを取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchProductTags: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/provider/products/tags`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 商品名、タグでフィルタリング可能
     * @summary 商品一覧を取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [keyword]
     * @param {string} [category]
     * @param {string} [tag]
     * @param {any} [excludes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchProducts: async (
      limit?: number,
      offset?: number,
      keyword?: string,
      category?: string,
      tag?: string,
      excludes?: any,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/provider/products`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      if (keyword !== undefined) {
        localVarQueryParameter['keyword'] = keyword
      }

      if (category !== undefined) {
        localVarQueryParameter['category'] = category
      }

      if (tag !== undefined) {
        localVarQueryParameter['tag'] = tag
      }

      if (excludes !== undefined) {
        localVarQueryParameter['excludes'] = excludes
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary プロジェクト情報の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchProject: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/provider/project`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary リリースの一覧取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {any} [excludes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchReleases: async (
      limit?: number,
      offset?: number,
      excludes?: any,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/provider/releases`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      if (excludes !== undefined) {
        localVarQueryParameter['excludes'] = excludes
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary ルールの一覧取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {any} [excludes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchRules: async (
      limit?: number,
      offset?: number,
      excludes?: any,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/provider/rules`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      if (excludes !== undefined) {
        localVarQueryParameter['excludes'] = excludes
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary カスタムユーザー属性の一覧取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {any} [excludes]
     * @param {any} [includes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchUserCustomAttributes: async (
      limit?: number,
      offset?: number,
      excludes?: any,
      includes?: any,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/provider/user-custom-attributes`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      if (excludes !== undefined) {
        localVarQueryParameter['excludes'] = excludes
      }

      if (includes !== undefined) {
        localVarQueryParameter['includes'] = includes
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary AIレコメンドモデルの詳細取得
     * @param {string} id 購買データCSVのSHA256ハッシュ値
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAiRecommendationModelById: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('findAiRecommendationModelById', 'id', id)
      const localVarPath = `/v1/provider/ai-recommendation-models/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary カウンセリングの詳細取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findCounselingById: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('findCounselingById', 'id', id)
      const localVarPath = `/v1/provider/counselings/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 診断ユーザーの詳細取得
     * @param {string} id
     * @param {any} [not]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findDiagnosisUserById: async (
      id: string,
      not?: any,
      limit?: number,
      offset?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('findDiagnosisUserById', 'id', id)
      const localVarPath = `/v1/provider/users/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (not !== undefined) {
        localVarQueryParameter['not'] = not
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary フィードバックの詳細取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findFeedbackById: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('findFeedbackById', 'id', id)
      const localVarPath = `/v1/provider/feedbacks/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 商品詳細の取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findProductById: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('findProductById', 'id', id)
      const localVarPath = `/v1/provider/products/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 商品カテゴリ詳細の取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findProductCategoryById: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('findProductCategoryById', 'id', id)
      const localVarPath = `/v1/provider/products/categories/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary アクティブなリリースを取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findReleaseByActive: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/provider/releases/active`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary リリースの詳細取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findReleaseById: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('findReleaseById', 'id', id)
      const localVarPath = `/v1/provider/releases/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary ルールの詳細取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findRuleById: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('findRuleById', 'id', id)
      const localVarPath = `/v1/provider/rules/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary カスタムユーザー属性の詳細取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findUserCustomAttributeById: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('findUserCustomAttributeById', 'id', id)
      const localVarPath = `/v1/provider/user-custom-attributes/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 診断画像一式のzipを生成して返す
     * @param {string} before
     * @param {string} after
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generatePortraitZip: async (
      before: string,
      after: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'before' is not null or undefined
      assertParamExists('generatePortraitZip', 'before', before)
      // verify required parameter 'after' is not null or undefined
      assertParamExists('generatePortraitZip', 'after', after)
      const localVarPath = `/v1/provider/users/diagnoses/portraits`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (before !== undefined) {
        localVarQueryParameter['before'] =
          (before as any) instanceof Date
            ? (before as any).toISOString()
            : before
      }

      if (after !== undefined) {
        localVarQueryParameter['after'] =
          (after as any) instanceof Date ? (after as any).toISOString() : after
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary カスタムページの情報を取得する
     * @param {'welcome' | 'privacyPolicy' | 'termsOfService'} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomPage: async (
      page: 'welcome' | 'privacyPolicy' | 'termsOfService',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists('getCustomPage', 'page', page)
      const localVarPath =
        `/v1/provider/update-request/custom-pages/{page}`.replace(
          `{${'page'}}`,
          encodeURIComponent(String(page))
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary プロバイダーのアカウント情報を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProvierAccount: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/provider/account`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary プロジェクトに紐づくStripeの支払いポータルサイトへのリンクを取得
     * @param {string} redirectUrl
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStripePortalUrl: async (
      redirectUrl: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'redirectUrl' is not null or undefined
      assertParamExists('getStripePortalUrl', 'redirectUrl', redirectUrl)
      const localVarPath = `/v1/provider/billing/stripe`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (redirectUrl !== undefined) {
        localVarQueryParameter['redirectUrl'] = redirectUrl
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 最新状態を本番環境に反映する
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishReleaseToProduction: async (
      body?: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/provider/releases/publish`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary アクセストークンの更新
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshProviderAccessToken: async (
      body?: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/provider/auth/accessToken`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary カスタムページの更新を申請する
     * @param {'welcome' | 'privacyPolicy' | 'termsOfService'} page
     * @param {GetCustomPage200ResponseItem} getCustomPage200ResponseItem
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestCustomPageUpdate: async (
      page: 'welcome' | 'privacyPolicy' | 'termsOfService',
      getCustomPage200ResponseItem: GetCustomPage200ResponseItem,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists('requestCustomPageUpdate', 'page', page)
      // verify required parameter 'getCustomPage200ResponseItem' is not null or undefined
      assertParamExists(
        'requestCustomPageUpdate',
        'getCustomPage200ResponseItem',
        getCustomPage200ResponseItem
      )
      const localVarPath =
        `/v1/provider/update-request/custom-pages/{page}`.replace(
          `{${'page'}}`,
          encodeURIComponent(String(page))
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        getCustomPage200ResponseItem,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary プロバイダーのパスワードリセットの申請
     * @param {RequestProviderPasswordResetRequest} requestProviderPasswordResetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestProviderPasswordReset: async (
      requestProviderPasswordResetRequest: RequestProviderPasswordResetRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'requestProviderPasswordResetRequest' is not null or undefined
      assertParamExists(
        'requestProviderPasswordReset',
        'requestProviderPasswordResetRequest',
        requestProviderPasswordResetRequest
      )
      const localVarPath = `/v1/provider/auth/password-reset`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestProviderPasswordResetRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary お問い合わせを送る
     * @param {SendInquiryRequest} sendInquiryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendInquiry: async (
      sendInquiryRequest: SendInquiryRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sendInquiryRequest' is not null or undefined
      assertParamExists('sendInquiry', 'sendInquiryRequest', sendInquiryRequest)
      const localVarPath = `/v1/contact`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        sendInquiryRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary プロバイダー権限のサインイン
     * @param {SigninAdminRequest} signinAdminRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signinProvider: async (
      signinAdminRequest: SigninAdminRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'signinAdminRequest' is not null or undefined
      assertParamExists(
        'signinProvider',
        'signinAdminRequest',
        signinAdminRequest
      )
      const localVarPath = `/v1/provider/auth/signin`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        signinAdminRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary プロバイダー権限のサインアウト
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signoutProvider: async (
      body?: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/provider/auth/signout`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary プロジェクトを切り替える
     * @param {SwitchActiveProjectRequest} switchActiveProjectRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    switchActiveProject: async (
      switchActiveProjectRequest: SwitchActiveProjectRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'switchActiveProjectRequest' is not null or undefined
      assertParamExists(
        'switchActiveProject',
        'switchActiveProjectRequest',
        switchActiveProjectRequest
      )
      const localVarPath = `/v1/provider/project/switch-project`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        switchActiveProjectRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary AIレコメンドモデルの更新
     * @param {string} id 購買データCSVのSHA256ハッシュ値
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAiRecommendationModel: async (
      id: string,
      body?: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateAiRecommendationModel', 'id', id)
      const localVarPath = `/v1/provider/ai-recommendation-models/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary カウンセリングの更新
     * @param {string} id
     * @param {CreateCounselingRequest} createCounselingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCounseling: async (
      id: string,
      createCounselingRequest: CreateCounselingRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateCounseling', 'id', id)
      // verify required parameter 'createCounselingRequest' is not null or undefined
      assertParamExists(
        'updateCounseling',
        'createCounselingRequest',
        createCounselingRequest
      )
      const localVarPath = `/v1/provider/counselings/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createCounselingRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary フィードバックの更新
     * @param {string} id
     * @param {CreateFeedbackRequest} createFeedbackRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFeedback: async (
      id: string,
      createFeedbackRequest: CreateFeedbackRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateFeedback', 'id', id)
      // verify required parameter 'createFeedbackRequest' is not null or undefined
      assertParamExists(
        'updateFeedback',
        'createFeedbackRequest',
        createFeedbackRequest
      )
      const localVarPath = `/v1/provider/feedbacks/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createFeedbackRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 商品の更新
     * @param {string} id
     * @param {CreateProductsRequestItemsInner} createProductsRequestItemsInner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProduct: async (
      id: string,
      createProductsRequestItemsInner: CreateProductsRequestItemsInner,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateProduct', 'id', id)
      // verify required parameter 'createProductsRequestItemsInner' is not null or undefined
      assertParamExists(
        'updateProduct',
        'createProductsRequestItemsInner',
        createProductsRequestItemsInner
      )
      const localVarPath = `/v1/provider/products/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createProductsRequestItemsInner,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 商品カテゴリの更新
     * @param {string} id
     * @param {CreateProductCategoryRequest} createProductCategoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProductCategory: async (
      id: string,
      createProductCategoryRequest: CreateProductCategoryRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateProductCategory', 'id', id)
      // verify required parameter 'createProductCategoryRequest' is not null or undefined
      assertParamExists(
        'updateProductCategory',
        'createProductCategoryRequest',
        createProductCategoryRequest
      )
      const localVarPath = `/v1/provider/products/categories/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createProductCategoryRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 商品画像の更新
     * @param {string} idOrCode
     * @param {UpdateProductImageRequest} updateProductImageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProductImage: async (
      idOrCode: string,
      updateProductImageRequest: UpdateProductImageRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idOrCode' is not null or undefined
      assertParamExists('updateProductImage', 'idOrCode', idOrCode)
      // verify required parameter 'updateProductImageRequest' is not null or undefined
      assertParamExists(
        'updateProductImage',
        'updateProductImageRequest',
        updateProductImageRequest
      )
      const localVarPath = `/v1/provider/products/{idOrCode}/thumbnail`.replace(
        `{${'idOrCode'}}`,
        encodeURIComponent(String(idOrCode))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateProductImageRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary プロジェクト情報の更新
     * @param {UpdateProjectRequest} [updateProjectRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProject: async (
      updateProjectRequest?: UpdateProjectRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/provider/project`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateProjectRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary プロバイダーのパスワードリセットの実行
     * @param {UpdateProviderPasswordResetRequest} updateProviderPasswordResetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProviderPasswordReset: async (
      updateProviderPasswordResetRequest: UpdateProviderPasswordResetRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateProviderPasswordResetRequest' is not null or undefined
      assertParamExists(
        'updateProviderPasswordReset',
        'updateProviderPasswordResetRequest',
        updateProviderPasswordResetRequest
      )
      const localVarPath = `/v1/provider/auth/password-reset`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateProviderPasswordResetRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary プロバイダーのアカウント情報を更新する
     * @param {UpdateProvierAccountRequest} updateProvierAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProvierAccount: async (
      updateProvierAccountRequest: UpdateProvierAccountRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateProvierAccountRequest' is not null or undefined
      assertParamExists(
        'updateProvierAccount',
        'updateProvierAccountRequest',
        updateProvierAccountRequest
      )
      const localVarPath = `/v1/provider/account`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateProvierAccountRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary リリースの更新
     * @param {string} id
     * @param {UpdateReleaseRequest} [updateReleaseRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRelease: async (
      id: string,
      updateReleaseRequest?: UpdateReleaseRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateRelease', 'id', id)
      const localVarPath = `/v1/provider/releases/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateReleaseRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary ルールの更新
     * @param {string} id
     * @param {UpdateRuleRequest} [updateRuleRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRule: async (
      id: string,
      updateRuleRequest?: UpdateRuleRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateRule', 'id', id)
      const localVarPath = `/v1/provider/rules/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateRuleRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary カスタムユーザー属性の更新
     * @param {string} id
     * @param {UpdateUserCustomAttributeRequest} [updateUserCustomAttributeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserCustomAttribute: async (
      id: string,
      updateUserCustomAttributeRequest?: UpdateUserCustomAttributeRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateUserCustomAttribute', 'id', id)
      const localVarPath = `/v1/provider/user-custom-attributes/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserCustomAttributeRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary CSVファイルの分割アップロード
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadCSVFileMultiparts: async (
      body?: any,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/provider/files/csv`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 更新リクエストを取り消す
     * @param {string} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    withdrawUpdateRequest: async (
      requestId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'requestId' is not null or undefined
      assertParamExists('withdrawUpdateRequest', 'requestId', requestId)
      const localVarPath = `/v1/provider/update-request/{requestId}`.replace(
        `{${'requestId'}}`,
        encodeURIComponent(String(requestId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ProviderApi - functional programming interface
 * @export
 */
export const ProviderApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ProviderApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary ヘルスチェック
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkHelth(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CheckHelth200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkHelth(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary AIレコメンドモデルの新規作成
     * @param {CreateAiRecommendationModelRequest} createAiRecommendationModelRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createAiRecommendationModel(
      createAiRecommendationModelRequest: CreateAiRecommendationModelRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateAiRecommendationModel201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createAiRecommendationModel(
          createAiRecommendationModelRequest,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary カウンセリグの新規作成
     * @param {CreateCounselingRequest} createCounselingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCounseling(
      createCounselingRequest: CreateCounselingRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateCounseling201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createCounseling(
          createCounselingRequest,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary フィードバックの新規作成
     * @param {CreateFeedbackRequest} createFeedbackRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createFeedback(
      createFeedbackRequest: CreateFeedbackRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateFeedback201Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createFeedback(
        createFeedbackRequest,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 商品の新規作成
     * @param {CreateProductsRequestItemsInner} createProductsRequestItemsInner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createProduct(
      createProductsRequestItemsInner: CreateProductsRequestItemsInner,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateProduct201Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createProduct(
        createProductsRequestItemsInner,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 商品カテゴリの新規作成
     * @param {CreateProductCategoryRequest} createProductCategoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createProductCategory(
      createProductCategoryRequest: CreateProductCategoryRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateProductCategory201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createProductCategory(
          createProductCategoryRequest,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 商品の一括登録
     * @param {CreateProductsRequest} createProductsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createProducts(
      createProductsRequest: CreateProductsRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateProducts201Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createProducts(
        createProductsRequest,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary リリースの新規作成
     * @param {CreateReleaseRequest} createReleaseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createRelease(
      createReleaseRequest: CreateReleaseRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateRelease201Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createRelease(
        createReleaseRequest,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary ルールの新規作成
     * @param {CreateRuleRequest} createRuleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createRule(
      createRuleRequest: CreateRuleRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateRule201Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createRule(
        createRuleRequest,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary プロジェクトに紐づくStripeのカスタマ情報を登録する
     * @param {CreateStripeCustomerRequest} createStripeCustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createStripeCustomer(
      createStripeCustomerRequest: CreateStripeCustomerRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetStripePortalUrl200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createStripeCustomer(
          createStripeCustomerRequest,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary カスタムユーザー属性の新規作成
     * @param {CreateUserCustomAttributeRequest} createUserCustomAttributeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createUserCustomAttribute(
      createUserCustomAttributeRequest: CreateUserCustomAttributeRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateUserCustomAttribute201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createUserCustomAttribute(
          createUserCustomAttributeRequest,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary AIレコメンドモデルの削除
     * @param {string} id 購買データCSVのSHA256ハッシュ値
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAiRecommendationModel(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteAiRecommendationModel(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary カウンセリングの削除
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteCounseling(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteCounseling(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary フィードバックの削除
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteFeedback(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFeedback(
        id,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 商品の削除
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteProduct(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProduct(
        id,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 商品の削除
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteProductCategory(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteProductCategory(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary リリースの削除
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteRelease(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRelease(
        id,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary ルールの削除
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteRule(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRule(
        id,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary カスタムユーザー属性の削除
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteUserCustomAttribute(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteUserCustomAttribute(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 特定リリースを使った診断結果のデバッグ
     * @param {string} id
     * @param {DetectSkinWithReleaseDebugRequest} detectSkinWithReleaseDebugRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async detectSkinWithReleaseDebug(
      id: string,
      detectSkinWithReleaseDebugRequest: DetectSkinWithReleaseDebugRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DetectSkinWithReleaseDebug200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.detectSkinWithReleaseDebug(
          id,
          detectSkinWithReleaseDebugRequest,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary このプロジェクトで診断された診断履歴をCSVでダウンロード
     * @param {string} before
     * @param {string} after
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadDiagnoisRecordsCSV(
      before: string,
      after: string,
      limit?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.downloadDiagnoisRecordsCSV(
          before,
          after,
          limit,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary カウンセリングの複製
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async duplicateCounseling(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateCounseling201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.duplicateCounseling(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary フィードバックの複製
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async duplicateFeedback(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateFeedback201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.duplicateFeedback(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 商品の複製
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async duplicateProduct(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateProduct201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.duplicateProduct(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary リリースの複製
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async duplicateRelease(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateRelease201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.duplicateRelease(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary ルールの複製
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async duplicateRule(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateRule201Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateRule(
        id,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary カスタムユーザー属性の複製
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async duplicateUserCustomAttribute(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateUserCustomAttribute201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.duplicateUserCustomAttribute(
          id,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary AIレコメンドモデルの一覧取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchAiRecommendationModels(
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FetchAiRecommendationModels200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchAiRecommendationModels(
          limit,
          offset,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary カウンセリングの一覧取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {any} [excludes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchCounselings(
      limit?: number,
      offset?: number,
      excludes?: any,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FetchCounselings200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchCounselings(
          limit,
          offset,
          excludes,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary ダッシュボードで使用する情報を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchDashboard(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FetchDashboard200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fetchDashboard(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 診断ユーザーに紐づくフィードバック閲覧ログの取得
     * @param {string} id
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchDiagnosisUserFeedbackLogs(
      id: string,
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FindDiagnosisUserById200ResponseFeedbacks>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchDiagnosisUserFeedbackLogs(
          id,
          limit,
          offset,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 診断ユーザーに紐づく診断履歴の取得
     * @param {string} id
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchDiagnosisUserHistories(
      id: string,
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FindDiagnosisUserById200ResponseDiagnoses>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchDiagnosisUserHistories(
          id,
          limit,
          offset,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 診断ユーザーに紐づく商品閲覧ログの取得
     * @param {string} id
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchDiagnosisUserProductLogs(
      id: string,
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FindDiagnosisUserById200ResponseProducts>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchDiagnosisUserProductLogs(
          id,
          limit,
          offset,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 診断ユーザーの一覧取得
     * @param {number} limit
     * @param {string} [nextKey]
     * @param {'Connected' | 'Reject' | 'Request' | 'Resolve' | 'None'} [status]
     * @param {string} [connectId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchDiagnosisUsers(
      limit: number,
      nextKey?: string,
      status?: 'Connected' | 'Reject' | 'Request' | 'Resolve' | 'None',
      connectId?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FetchDiagnosisUsers200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchDiagnosisUsers(
          limit,
          nextKey,
          status,
          connectId,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary フィードバックの一覧取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {any} [excludes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchFeedbacks(
      limit?: number,
      offset?: number,
      excludes?: any,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FetchFeedbacks200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fetchFeedbacks(
        limit,
        offset,
        excludes,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 商品カテゴリ一覧を取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchProductCategories(
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FetchProductCategories200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchProductCategories(
          limit,
          offset,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 商品タグを取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchProductTags(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FetchProductTags200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchProductTags(options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * 商品名、タグでフィルタリング可能
     * @summary 商品一覧を取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [keyword]
     * @param {string} [category]
     * @param {string} [tag]
     * @param {any} [excludes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchProducts(
      limit?: number,
      offset?: number,
      keyword?: string,
      category?: string,
      tag?: string,
      excludes?: any,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FetchProducts200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fetchProducts(
        limit,
        offset,
        keyword,
        category,
        tag,
        excludes,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary プロジェクト情報の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchProject(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FetchProject200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fetchProject(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary リリースの一覧取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {any} [excludes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchReleases(
      limit?: number,
      offset?: number,
      excludes?: any,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FetchReleases200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fetchReleases(
        limit,
        offset,
        excludes,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary ルールの一覧取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {any} [excludes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchRules(
      limit?: number,
      offset?: number,
      excludes?: any,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FetchRules200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fetchRules(
        limit,
        offset,
        excludes,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary カスタムユーザー属性の一覧取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {any} [excludes]
     * @param {any} [includes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchUserCustomAttributes(
      limit?: number,
      offset?: number,
      excludes?: any,
      includes?: any,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FetchUserCustomAttributes200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchUserCustomAttributes(
          limit,
          offset,
          excludes,
          includes,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary AIレコメンドモデルの詳細取得
     * @param {string} id 購買データCSVのSHA256ハッシュ値
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findAiRecommendationModelById(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateAiRecommendationModel201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findAiRecommendationModelById(
          id,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary カウンセリングの詳細取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findCounselingById(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateCounseling201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findCounselingById(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 診断ユーザーの詳細取得
     * @param {string} id
     * @param {any} [not]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findDiagnosisUserById(
      id: string,
      not?: any,
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FindDiagnosisUserById200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findDiagnosisUserById(
          id,
          not,
          limit,
          offset,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary フィードバックの詳細取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findFeedbackById(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateFeedback201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findFeedbackById(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 商品詳細の取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findProductById(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateProduct201Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findProductById(
        id,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 商品カテゴリ詳細の取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findProductCategoryById(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateProductCategory201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findProductCategoryById(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary アクティブなリリースを取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findReleaseByActive(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FindReleaseByActive200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findReleaseByActive(options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary リリースの詳細取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findReleaseById(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateRelease201Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findReleaseById(
        id,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary ルールの詳細取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findRuleById(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateRule201Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findRuleById(
        id,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary カスタムユーザー属性の詳細取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findUserCustomAttributeById(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateUserCustomAttribute201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findUserCustomAttributeById(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 診断画像一式のzipを生成して返す
     * @param {string} before
     * @param {string} after
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generatePortraitZip(
      before: string,
      after: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.generatePortraitZip(
          before,
          after,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary カスタムページの情報を取得する
     * @param {'welcome' | 'privacyPolicy' | 'termsOfService'} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCustomPage(
      page: 'welcome' | 'privacyPolicy' | 'termsOfService',
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetCustomPage200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomPage(
        page,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary プロバイダーのアカウント情報を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProvierAccount(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetProvierAccount200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getProvierAccount(options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary プロジェクトに紐づくStripeの支払いポータルサイトへのリンクを取得
     * @param {string} redirectUrl
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStripePortalUrl(
      redirectUrl: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetStripePortalUrl200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getStripePortalUrl(redirectUrl, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 最新状態を本番環境に反映する
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async publishReleaseToProduction(
      body?: object,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FindReleaseByActive200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.publishReleaseToProduction(
          body,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary アクセストークンの更新
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshProviderAccessToken(
      body?: object,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<RefreshAdminAccessToken200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.refreshProviderAccessToken(
          body,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary カスタムページの更新を申請する
     * @param {'welcome' | 'privacyPolicy' | 'termsOfService'} page
     * @param {GetCustomPage200ResponseItem} getCustomPage200ResponseItem
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async requestCustomPageUpdate(
      page: 'welcome' | 'privacyPolicy' | 'termsOfService',
      getCustomPage200ResponseItem: GetCustomPage200ResponseItem,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<RequestCustomPageUpdate201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.requestCustomPageUpdate(
          page,
          getCustomPage200ResponseItem,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary プロバイダーのパスワードリセットの申請
     * @param {RequestProviderPasswordResetRequest} requestProviderPasswordResetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async requestProviderPasswordReset(
      requestProviderPasswordResetRequest: RequestProviderPasswordResetRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.requestProviderPasswordReset(
          requestProviderPasswordResetRequest,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary お問い合わせを送る
     * @param {SendInquiryRequest} sendInquiryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendInquiry(
      sendInquiryRequest: SendInquiryRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CheckHelth200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendInquiry(
        sendInquiryRequest,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary プロバイダー権限のサインイン
     * @param {SigninAdminRequest} signinAdminRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async signinProvider(
      signinAdminRequest: SigninAdminRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<SigninProvider200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.signinProvider(
        signinAdminRequest,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary プロバイダー権限のサインアウト
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async signoutProvider(
      body?: object,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.signoutProvider(
        body,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary プロジェクトを切り替える
     * @param {SwitchActiveProjectRequest} switchActiveProjectRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async switchActiveProject(
      switchActiveProjectRequest: SwitchActiveProjectRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<SigninProvider200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.switchActiveProject(
          switchActiveProjectRequest,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary AIレコメンドモデルの更新
     * @param {string} id 購買データCSVのSHA256ハッシュ値
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAiRecommendationModel(
      id: string,
      body?: object,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateAiRecommendationModel201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateAiRecommendationModel(
          id,
          body,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary カウンセリングの更新
     * @param {string} id
     * @param {CreateCounselingRequest} createCounselingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCounseling(
      id: string,
      createCounselingRequest: CreateCounselingRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateCounseling201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateCounseling(
          id,
          createCounselingRequest,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary フィードバックの更新
     * @param {string} id
     * @param {CreateFeedbackRequest} createFeedbackRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateFeedback(
      id: string,
      createFeedbackRequest: CreateFeedbackRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateFeedback201Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateFeedback(
        id,
        createFeedbackRequest,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 商品の更新
     * @param {string} id
     * @param {CreateProductsRequestItemsInner} createProductsRequestItemsInner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateProduct(
      id: string,
      createProductsRequestItemsInner: CreateProductsRequestItemsInner,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateProduct201Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateProduct(
        id,
        createProductsRequestItemsInner,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 商品カテゴリの更新
     * @param {string} id
     * @param {CreateProductCategoryRequest} createProductCategoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateProductCategory(
      id: string,
      createProductCategoryRequest: CreateProductCategoryRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateProductCategory201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateProductCategory(
          id,
          createProductCategoryRequest,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 商品画像の更新
     * @param {string} idOrCode
     * @param {UpdateProductImageRequest} updateProductImageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateProductImage(
      idOrCode: string,
      updateProductImageRequest: UpdateProductImageRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CheckHelth200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateProductImage(
          idOrCode,
          updateProductImageRequest,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary プロジェクト情報の更新
     * @param {UpdateProjectRequest} [updateProjectRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateProject(
      updateProjectRequest?: UpdateProjectRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FetchProject200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateProject(
        updateProjectRequest,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary プロバイダーのパスワードリセットの実行
     * @param {UpdateProviderPasswordResetRequest} updateProviderPasswordResetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateProviderPasswordReset(
      updateProviderPasswordResetRequest: UpdateProviderPasswordResetRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<SigninProvider200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateProviderPasswordReset(
          updateProviderPasswordResetRequest,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary プロバイダーのアカウント情報を更新する
     * @param {UpdateProvierAccountRequest} updateProvierAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateProvierAccount(
      updateProvierAccountRequest: UpdateProvierAccountRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetProvierAccount200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateProvierAccount(
          updateProvierAccountRequest,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary リリースの更新
     * @param {string} id
     * @param {UpdateReleaseRequest} [updateReleaseRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateRelease(
      id: string,
      updateReleaseRequest?: UpdateReleaseRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateRelease201Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateRelease(
        id,
        updateReleaseRequest,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary ルールの更新
     * @param {string} id
     * @param {UpdateRuleRequest} [updateRuleRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateRule(
      id: string,
      updateRuleRequest?: UpdateRuleRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateRule201Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateRule(
        id,
        updateRuleRequest,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary カスタムユーザー属性の更新
     * @param {string} id
     * @param {UpdateUserCustomAttributeRequest} [updateUserCustomAttributeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserCustomAttribute(
      id: string,
      updateUserCustomAttributeRequest?: UpdateUserCustomAttributeRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CreateUserCustomAttribute201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateUserCustomAttribute(
          id,
          updateUserCustomAttributeRequest,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary CSVファイルの分割アップロード
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadCSVFileMultiparts(
      body?: any,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UploadCSVFileMultiparts201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.uploadCSVFileMultiparts(body, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 更新リクエストを取り消す
     * @param {string} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async withdrawUpdateRequest(
      requestId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<CheckHelth200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.withdrawUpdateRequest(
          requestId,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * ProviderApi - factory interface
 * @export
 */
export const ProviderApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ProviderApiFp(configuration)
  return {
    /**
     *
     * @summary ヘルスチェック
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkHelth(options?: any): AxiosPromise<CheckHelth200Response> {
      return localVarFp
        .checkHelth(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary AIレコメンドモデルの新規作成
     * @param {CreateAiRecommendationModelRequest} createAiRecommendationModelRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAiRecommendationModel(
      createAiRecommendationModelRequest: CreateAiRecommendationModelRequest,
      options?: any
    ): AxiosPromise<CreateAiRecommendationModel201Response> {
      return localVarFp
        .createAiRecommendationModel(
          createAiRecommendationModelRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary カウンセリグの新規作成
     * @param {CreateCounselingRequest} createCounselingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCounseling(
      createCounselingRequest: CreateCounselingRequest,
      options?: any
    ): AxiosPromise<CreateCounseling201Response> {
      return localVarFp
        .createCounseling(createCounselingRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary フィードバックの新規作成
     * @param {CreateFeedbackRequest} createFeedbackRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFeedback(
      createFeedbackRequest: CreateFeedbackRequest,
      options?: any
    ): AxiosPromise<CreateFeedback201Response> {
      return localVarFp
        .createFeedback(createFeedbackRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 商品の新規作成
     * @param {CreateProductsRequestItemsInner} createProductsRequestItemsInner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProduct(
      createProductsRequestItemsInner: CreateProductsRequestItemsInner,
      options?: any
    ): AxiosPromise<CreateProduct201Response> {
      return localVarFp
        .createProduct(createProductsRequestItemsInner, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 商品カテゴリの新規作成
     * @param {CreateProductCategoryRequest} createProductCategoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProductCategory(
      createProductCategoryRequest: CreateProductCategoryRequest,
      options?: any
    ): AxiosPromise<CreateProductCategory201Response> {
      return localVarFp
        .createProductCategory(createProductCategoryRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 商品の一括登録
     * @param {CreateProductsRequest} createProductsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProducts(
      createProductsRequest: CreateProductsRequest,
      options?: any
    ): AxiosPromise<CreateProducts201Response> {
      return localVarFp
        .createProducts(createProductsRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary リリースの新規作成
     * @param {CreateReleaseRequest} createReleaseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRelease(
      createReleaseRequest: CreateReleaseRequest,
      options?: any
    ): AxiosPromise<CreateRelease201Response> {
      return localVarFp
        .createRelease(createReleaseRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary ルールの新規作成
     * @param {CreateRuleRequest} createRuleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRule(
      createRuleRequest: CreateRuleRequest,
      options?: any
    ): AxiosPromise<CreateRule201Response> {
      return localVarFp
        .createRule(createRuleRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary プロジェクトに紐づくStripeのカスタマ情報を登録する
     * @param {CreateStripeCustomerRequest} createStripeCustomerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createStripeCustomer(
      createStripeCustomerRequest: CreateStripeCustomerRequest,
      options?: any
    ): AxiosPromise<GetStripePortalUrl200Response> {
      return localVarFp
        .createStripeCustomer(createStripeCustomerRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary カスタムユーザー属性の新規作成
     * @param {CreateUserCustomAttributeRequest} createUserCustomAttributeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserCustomAttribute(
      createUserCustomAttributeRequest: CreateUserCustomAttributeRequest,
      options?: any
    ): AxiosPromise<CreateUserCustomAttribute201Response> {
      return localVarFp
        .createUserCustomAttribute(createUserCustomAttributeRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary AIレコメンドモデルの削除
     * @param {string} id 購買データCSVのSHA256ハッシュ値
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAiRecommendationModel(
      id: string,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .deleteAiRecommendationModel(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary カウンセリングの削除
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCounseling(id: string, options?: any): AxiosPromise<string> {
      return localVarFp
        .deleteCounseling(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary フィードバックの削除
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFeedback(id: string, options?: any): AxiosPromise<string> {
      return localVarFp
        .deleteFeedback(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 商品の削除
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProduct(id: string, options?: any): AxiosPromise<string> {
      return localVarFp
        .deleteProduct(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 商品の削除
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteProductCategory(id: string, options?: any): AxiosPromise<string> {
      return localVarFp
        .deleteProductCategory(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary リリースの削除
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRelease(id: string, options?: any): AxiosPromise<string> {
      return localVarFp
        .deleteRelease(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary ルールの削除
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRule(id: string, options?: any): AxiosPromise<string> {
      return localVarFp
        .deleteRule(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary カスタムユーザー属性の削除
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserCustomAttribute(id: string, options?: any): AxiosPromise<string> {
      return localVarFp
        .deleteUserCustomAttribute(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 特定リリースを使った診断結果のデバッグ
     * @param {string} id
     * @param {DetectSkinWithReleaseDebugRequest} detectSkinWithReleaseDebugRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    detectSkinWithReleaseDebug(
      id: string,
      detectSkinWithReleaseDebugRequest: DetectSkinWithReleaseDebugRequest,
      options?: any
    ): AxiosPromise<DetectSkinWithReleaseDebug200Response> {
      return localVarFp
        .detectSkinWithReleaseDebug(
          id,
          detectSkinWithReleaseDebugRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary このプロジェクトで診断された診断履歴をCSVでダウンロード
     * @param {string} before
     * @param {string} after
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadDiagnoisRecordsCSV(
      before: string,
      after: string,
      limit?: number,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .downloadDiagnoisRecordsCSV(before, after, limit, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary カウンセリングの複製
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    duplicateCounseling(
      id: string,
      options?: any
    ): AxiosPromise<CreateCounseling201Response> {
      return localVarFp
        .duplicateCounseling(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary フィードバックの複製
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    duplicateFeedback(
      id: string,
      options?: any
    ): AxiosPromise<CreateFeedback201Response> {
      return localVarFp
        .duplicateFeedback(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 商品の複製
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    duplicateProduct(
      id: string,
      options?: any
    ): AxiosPromise<CreateProduct201Response> {
      return localVarFp
        .duplicateProduct(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary リリースの複製
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    duplicateRelease(
      id: string,
      options?: any
    ): AxiosPromise<CreateRelease201Response> {
      return localVarFp
        .duplicateRelease(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary ルールの複製
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    duplicateRule(
      id: string,
      options?: any
    ): AxiosPromise<CreateRule201Response> {
      return localVarFp
        .duplicateRule(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary カスタムユーザー属性の複製
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    duplicateUserCustomAttribute(
      id: string,
      options?: any
    ): AxiosPromise<CreateUserCustomAttribute201Response> {
      return localVarFp
        .duplicateUserCustomAttribute(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary AIレコメンドモデルの一覧取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchAiRecommendationModels(
      limit?: number,
      offset?: number,
      options?: any
    ): AxiosPromise<FetchAiRecommendationModels200Response> {
      return localVarFp
        .fetchAiRecommendationModels(limit, offset, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary カウンセリングの一覧取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {any} [excludes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchCounselings(
      limit?: number,
      offset?: number,
      excludes?: any,
      options?: any
    ): AxiosPromise<FetchCounselings200Response> {
      return localVarFp
        .fetchCounselings(limit, offset, excludes, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary ダッシュボードで使用する情報を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchDashboard(options?: any): AxiosPromise<FetchDashboard200Response> {
      return localVarFp
        .fetchDashboard(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 診断ユーザーに紐づくフィードバック閲覧ログの取得
     * @param {string} id
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchDiagnosisUserFeedbackLogs(
      id: string,
      limit?: number,
      offset?: number,
      options?: any
    ): AxiosPromise<FindDiagnosisUserById200ResponseFeedbacks> {
      return localVarFp
        .fetchDiagnosisUserFeedbackLogs(id, limit, offset, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 診断ユーザーに紐づく診断履歴の取得
     * @param {string} id
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchDiagnosisUserHistories(
      id: string,
      limit?: number,
      offset?: number,
      options?: any
    ): AxiosPromise<FindDiagnosisUserById200ResponseDiagnoses> {
      return localVarFp
        .fetchDiagnosisUserHistories(id, limit, offset, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 診断ユーザーに紐づく商品閲覧ログの取得
     * @param {string} id
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchDiagnosisUserProductLogs(
      id: string,
      limit?: number,
      offset?: number,
      options?: any
    ): AxiosPromise<FindDiagnosisUserById200ResponseProducts> {
      return localVarFp
        .fetchDiagnosisUserProductLogs(id, limit, offset, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 診断ユーザーの一覧取得
     * @param {number} limit
     * @param {string} [nextKey]
     * @param {'Connected' | 'Reject' | 'Request' | 'Resolve' | 'None'} [status]
     * @param {string} [connectId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchDiagnosisUsers(
      limit: number,
      nextKey?: string,
      status?: 'Connected' | 'Reject' | 'Request' | 'Resolve' | 'None',
      connectId?: string,
      options?: any
    ): AxiosPromise<FetchDiagnosisUsers200Response> {
      return localVarFp
        .fetchDiagnosisUsers(limit, nextKey, status, connectId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary フィードバックの一覧取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {any} [excludes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchFeedbacks(
      limit?: number,
      offset?: number,
      excludes?: any,
      options?: any
    ): AxiosPromise<FetchFeedbacks200Response> {
      return localVarFp
        .fetchFeedbacks(limit, offset, excludes, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 商品カテゴリ一覧を取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchProductCategories(
      limit?: number,
      offset?: number,
      options?: any
    ): AxiosPromise<FetchProductCategories200Response> {
      return localVarFp
        .fetchProductCategories(limit, offset, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 商品タグを取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchProductTags(options?: any): AxiosPromise<FetchProductTags200Response> {
      return localVarFp
        .fetchProductTags(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * 商品名、タグでフィルタリング可能
     * @summary 商品一覧を取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [keyword]
     * @param {string} [category]
     * @param {string} [tag]
     * @param {any} [excludes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchProducts(
      limit?: number,
      offset?: number,
      keyword?: string,
      category?: string,
      tag?: string,
      excludes?: any,
      options?: any
    ): AxiosPromise<FetchProducts200Response> {
      return localVarFp
        .fetchProducts(limit, offset, keyword, category, tag, excludes, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary プロジェクト情報の取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchProject(options?: any): AxiosPromise<FetchProject200Response> {
      return localVarFp
        .fetchProject(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary リリースの一覧取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {any} [excludes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchReleases(
      limit?: number,
      offset?: number,
      excludes?: any,
      options?: any
    ): AxiosPromise<FetchReleases200Response> {
      return localVarFp
        .fetchReleases(limit, offset, excludes, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary ルールの一覧取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {any} [excludes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchRules(
      limit?: number,
      offset?: number,
      excludes?: any,
      options?: any
    ): AxiosPromise<FetchRules200Response> {
      return localVarFp
        .fetchRules(limit, offset, excludes, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary カスタムユーザー属性の一覧取得
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {any} [excludes]
     * @param {any} [includes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchUserCustomAttributes(
      limit?: number,
      offset?: number,
      excludes?: any,
      includes?: any,
      options?: any
    ): AxiosPromise<FetchUserCustomAttributes200Response> {
      return localVarFp
        .fetchUserCustomAttributes(limit, offset, excludes, includes, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary AIレコメンドモデルの詳細取得
     * @param {string} id 購買データCSVのSHA256ハッシュ値
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findAiRecommendationModelById(
      id: string,
      options?: any
    ): AxiosPromise<CreateAiRecommendationModel201Response> {
      return localVarFp
        .findAiRecommendationModelById(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary カウンセリングの詳細取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findCounselingById(
      id: string,
      options?: any
    ): AxiosPromise<CreateCounseling201Response> {
      return localVarFp
        .findCounselingById(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 診断ユーザーの詳細取得
     * @param {string} id
     * @param {any} [not]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findDiagnosisUserById(
      id: string,
      not?: any,
      limit?: number,
      offset?: number,
      options?: any
    ): AxiosPromise<FindDiagnosisUserById200Response> {
      return localVarFp
        .findDiagnosisUserById(id, not, limit, offset, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary フィードバックの詳細取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findFeedbackById(
      id: string,
      options?: any
    ): AxiosPromise<CreateFeedback201Response> {
      return localVarFp
        .findFeedbackById(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 商品詳細の取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findProductById(
      id: string,
      options?: any
    ): AxiosPromise<CreateProduct201Response> {
      return localVarFp
        .findProductById(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 商品カテゴリ詳細の取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findProductCategoryById(
      id: string,
      options?: any
    ): AxiosPromise<CreateProductCategory201Response> {
      return localVarFp
        .findProductCategoryById(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary アクティブなリリースを取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findReleaseByActive(
      options?: any
    ): AxiosPromise<FindReleaseByActive200Response> {
      return localVarFp
        .findReleaseByActive(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary リリースの詳細取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findReleaseById(
      id: string,
      options?: any
    ): AxiosPromise<CreateRelease201Response> {
      return localVarFp
        .findReleaseById(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary ルールの詳細取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findRuleById(
      id: string,
      options?: any
    ): AxiosPromise<CreateRule201Response> {
      return localVarFp
        .findRuleById(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary カスタムユーザー属性の詳細取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findUserCustomAttributeById(
      id: string,
      options?: any
    ): AxiosPromise<CreateUserCustomAttribute201Response> {
      return localVarFp
        .findUserCustomAttributeById(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 診断画像一式のzipを生成して返す
     * @param {string} before
     * @param {string} after
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generatePortraitZip(
      before: string,
      after: string,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .generatePortraitZip(before, after, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary カスタムページの情報を取得する
     * @param {'welcome' | 'privacyPolicy' | 'termsOfService'} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCustomPage(
      page: 'welcome' | 'privacyPolicy' | 'termsOfService',
      options?: any
    ): AxiosPromise<GetCustomPage200Response> {
      return localVarFp
        .getCustomPage(page, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary プロバイダーのアカウント情報を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProvierAccount(
      options?: any
    ): AxiosPromise<GetProvierAccount200Response> {
      return localVarFp
        .getProvierAccount(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary プロジェクトに紐づくStripeの支払いポータルサイトへのリンクを取得
     * @param {string} redirectUrl
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStripePortalUrl(
      redirectUrl: string,
      options?: any
    ): AxiosPromise<GetStripePortalUrl200Response> {
      return localVarFp
        .getStripePortalUrl(redirectUrl, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 最新状態を本番環境に反映する
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    publishReleaseToProduction(
      body?: object,
      options?: any
    ): AxiosPromise<FindReleaseByActive200Response> {
      return localVarFp
        .publishReleaseToProduction(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary アクセストークンの更新
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshProviderAccessToken(
      body?: object,
      options?: any
    ): AxiosPromise<RefreshAdminAccessToken200Response> {
      return localVarFp
        .refreshProviderAccessToken(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary カスタムページの更新を申請する
     * @param {'welcome' | 'privacyPolicy' | 'termsOfService'} page
     * @param {GetCustomPage200ResponseItem} getCustomPage200ResponseItem
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestCustomPageUpdate(
      page: 'welcome' | 'privacyPolicy' | 'termsOfService',
      getCustomPage200ResponseItem: GetCustomPage200ResponseItem,
      options?: any
    ): AxiosPromise<RequestCustomPageUpdate201Response> {
      return localVarFp
        .requestCustomPageUpdate(page, getCustomPage200ResponseItem, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary プロバイダーのパスワードリセットの申請
     * @param {RequestProviderPasswordResetRequest} requestProviderPasswordResetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestProviderPasswordReset(
      requestProviderPasswordResetRequest: RequestProviderPasswordResetRequest,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .requestProviderPasswordReset(
          requestProviderPasswordResetRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary お問い合わせを送る
     * @param {SendInquiryRequest} sendInquiryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendInquiry(
      sendInquiryRequest: SendInquiryRequest,
      options?: any
    ): AxiosPromise<CheckHelth200Response> {
      return localVarFp
        .sendInquiry(sendInquiryRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary プロバイダー権限のサインイン
     * @param {SigninAdminRequest} signinAdminRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signinProvider(
      signinAdminRequest: SigninAdminRequest,
      options?: any
    ): AxiosPromise<SigninProvider200Response> {
      return localVarFp
        .signinProvider(signinAdminRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary プロバイダー権限のサインアウト
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signoutProvider(body?: object, options?: any): AxiosPromise<string> {
      return localVarFp
        .signoutProvider(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary プロジェクトを切り替える
     * @param {SwitchActiveProjectRequest} switchActiveProjectRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    switchActiveProject(
      switchActiveProjectRequest: SwitchActiveProjectRequest,
      options?: any
    ): AxiosPromise<SigninProvider200Response> {
      return localVarFp
        .switchActiveProject(switchActiveProjectRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary AIレコメンドモデルの更新
     * @param {string} id 購買データCSVのSHA256ハッシュ値
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAiRecommendationModel(
      id: string,
      body?: object,
      options?: any
    ): AxiosPromise<CreateAiRecommendationModel201Response> {
      return localVarFp
        .updateAiRecommendationModel(id, body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary カウンセリングの更新
     * @param {string} id
     * @param {CreateCounselingRequest} createCounselingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCounseling(
      id: string,
      createCounselingRequest: CreateCounselingRequest,
      options?: any
    ): AxiosPromise<CreateCounseling201Response> {
      return localVarFp
        .updateCounseling(id, createCounselingRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary フィードバックの更新
     * @param {string} id
     * @param {CreateFeedbackRequest} createFeedbackRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFeedback(
      id: string,
      createFeedbackRequest: CreateFeedbackRequest,
      options?: any
    ): AxiosPromise<CreateFeedback201Response> {
      return localVarFp
        .updateFeedback(id, createFeedbackRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 商品の更新
     * @param {string} id
     * @param {CreateProductsRequestItemsInner} createProductsRequestItemsInner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProduct(
      id: string,
      createProductsRequestItemsInner: CreateProductsRequestItemsInner,
      options?: any
    ): AxiosPromise<CreateProduct201Response> {
      return localVarFp
        .updateProduct(id, createProductsRequestItemsInner, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 商品カテゴリの更新
     * @param {string} id
     * @param {CreateProductCategoryRequest} createProductCategoryRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProductCategory(
      id: string,
      createProductCategoryRequest: CreateProductCategoryRequest,
      options?: any
    ): AxiosPromise<CreateProductCategory201Response> {
      return localVarFp
        .updateProductCategory(id, createProductCategoryRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 商品画像の更新
     * @param {string} idOrCode
     * @param {UpdateProductImageRequest} updateProductImageRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProductImage(
      idOrCode: string,
      updateProductImageRequest: UpdateProductImageRequest,
      options?: any
    ): AxiosPromise<CheckHelth200Response> {
      return localVarFp
        .updateProductImage(idOrCode, updateProductImageRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary プロジェクト情報の更新
     * @param {UpdateProjectRequest} [updateProjectRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProject(
      updateProjectRequest?: UpdateProjectRequest,
      options?: any
    ): AxiosPromise<FetchProject200Response> {
      return localVarFp
        .updateProject(updateProjectRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary プロバイダーのパスワードリセットの実行
     * @param {UpdateProviderPasswordResetRequest} updateProviderPasswordResetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProviderPasswordReset(
      updateProviderPasswordResetRequest: UpdateProviderPasswordResetRequest,
      options?: any
    ): AxiosPromise<SigninProvider200Response> {
      return localVarFp
        .updateProviderPasswordReset(
          updateProviderPasswordResetRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary プロバイダーのアカウント情報を更新する
     * @param {UpdateProvierAccountRequest} updateProvierAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProvierAccount(
      updateProvierAccountRequest: UpdateProvierAccountRequest,
      options?: any
    ): AxiosPromise<GetProvierAccount200Response> {
      return localVarFp
        .updateProvierAccount(updateProvierAccountRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary リリースの更新
     * @param {string} id
     * @param {UpdateReleaseRequest} [updateReleaseRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRelease(
      id: string,
      updateReleaseRequest?: UpdateReleaseRequest,
      options?: any
    ): AxiosPromise<CreateRelease201Response> {
      return localVarFp
        .updateRelease(id, updateReleaseRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary ルールの更新
     * @param {string} id
     * @param {UpdateRuleRequest} [updateRuleRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRule(
      id: string,
      updateRuleRequest?: UpdateRuleRequest,
      options?: any
    ): AxiosPromise<CreateRule201Response> {
      return localVarFp
        .updateRule(id, updateRuleRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary カスタムユーザー属性の更新
     * @param {string} id
     * @param {UpdateUserCustomAttributeRequest} [updateUserCustomAttributeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserCustomAttribute(
      id: string,
      updateUserCustomAttributeRequest?: UpdateUserCustomAttributeRequest,
      options?: any
    ): AxiosPromise<CreateUserCustomAttribute201Response> {
      return localVarFp
        .updateUserCustomAttribute(
          id,
          updateUserCustomAttributeRequest,
          options
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary CSVファイルの分割アップロード
     * @param {any} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadCSVFileMultiparts(
      body?: any,
      options?: any
    ): AxiosPromise<UploadCSVFileMultiparts201Response> {
      return localVarFp
        .uploadCSVFileMultiparts(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 更新リクエストを取り消す
     * @param {string} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    withdrawUpdateRequest(
      requestId: string,
      options?: any
    ): AxiosPromise<CheckHelth200Response> {
      return localVarFp
        .withdrawUpdateRequest(requestId, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * ProviderApi - object-oriented interface
 * @export
 * @class ProviderApi
 * @extends {BaseAPI}
 */
export class ProviderApi extends BaseAPI {
  /**
   *
   * @summary ヘルスチェック
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public checkHelth(options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .checkHelth(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary AIレコメンドモデルの新規作成
   * @param {CreateAiRecommendationModelRequest} createAiRecommendationModelRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public createAiRecommendationModel(
    createAiRecommendationModelRequest: CreateAiRecommendationModelRequest,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .createAiRecommendationModel(createAiRecommendationModelRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary カウンセリグの新規作成
   * @param {CreateCounselingRequest} createCounselingRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public createCounseling(
    createCounselingRequest: CreateCounselingRequest,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .createCounseling(createCounselingRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary フィードバックの新規作成
   * @param {CreateFeedbackRequest} createFeedbackRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public createFeedback(
    createFeedbackRequest: CreateFeedbackRequest,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .createFeedback(createFeedbackRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 商品の新規作成
   * @param {CreateProductsRequestItemsInner} createProductsRequestItemsInner
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public createProduct(
    createProductsRequestItemsInner: CreateProductsRequestItemsInner,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .createProduct(createProductsRequestItemsInner, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 商品カテゴリの新規作成
   * @param {CreateProductCategoryRequest} createProductCategoryRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public createProductCategory(
    createProductCategoryRequest: CreateProductCategoryRequest,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .createProductCategory(createProductCategoryRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 商品の一括登録
   * @param {CreateProductsRequest} createProductsRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public createProducts(
    createProductsRequest: CreateProductsRequest,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .createProducts(createProductsRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary リリースの新規作成
   * @param {CreateReleaseRequest} createReleaseRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public createRelease(
    createReleaseRequest: CreateReleaseRequest,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .createRelease(createReleaseRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary ルールの新規作成
   * @param {CreateRuleRequest} createRuleRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public createRule(
    createRuleRequest: CreateRuleRequest,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .createRule(createRuleRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary プロジェクトに紐づくStripeのカスタマ情報を登録する
   * @param {CreateStripeCustomerRequest} createStripeCustomerRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public createStripeCustomer(
    createStripeCustomerRequest: CreateStripeCustomerRequest,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .createStripeCustomer(createStripeCustomerRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary カスタムユーザー属性の新規作成
   * @param {CreateUserCustomAttributeRequest} createUserCustomAttributeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public createUserCustomAttribute(
    createUserCustomAttributeRequest: CreateUserCustomAttributeRequest,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .createUserCustomAttribute(createUserCustomAttributeRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary AIレコメンドモデルの削除
   * @param {string} id 購買データCSVのSHA256ハッシュ値
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public deleteAiRecommendationModel(id: string, options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .deleteAiRecommendationModel(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary カウンセリングの削除
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public deleteCounseling(id: string, options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .deleteCounseling(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary フィードバックの削除
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public deleteFeedback(id: string, options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .deleteFeedback(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 商品の削除
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public deleteProduct(id: string, options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .deleteProduct(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 商品の削除
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public deleteProductCategory(id: string, options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .deleteProductCategory(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary リリースの削除
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public deleteRelease(id: string, options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .deleteRelease(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary ルールの削除
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public deleteRule(id: string, options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .deleteRule(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary カスタムユーザー属性の削除
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public deleteUserCustomAttribute(id: string, options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .deleteUserCustomAttribute(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 特定リリースを使った診断結果のデバッグ
   * @param {string} id
   * @param {DetectSkinWithReleaseDebugRequest} detectSkinWithReleaseDebugRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public detectSkinWithReleaseDebug(
    id: string,
    detectSkinWithReleaseDebugRequest: DetectSkinWithReleaseDebugRequest,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .detectSkinWithReleaseDebug(
        id,
        detectSkinWithReleaseDebugRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary このプロジェクトで診断された診断履歴をCSVでダウンロード
   * @param {string} before
   * @param {string} after
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public downloadDiagnoisRecordsCSV(
    before: string,
    after: string,
    limit?: number,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .downloadDiagnoisRecordsCSV(before, after, limit, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary カウンセリングの複製
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public duplicateCounseling(id: string, options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .duplicateCounseling(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary フィードバックの複製
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public duplicateFeedback(id: string, options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .duplicateFeedback(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 商品の複製
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public duplicateProduct(id: string, options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .duplicateProduct(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary リリースの複製
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public duplicateRelease(id: string, options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .duplicateRelease(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary ルールの複製
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public duplicateRule(id: string, options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .duplicateRule(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary カスタムユーザー属性の複製
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public duplicateUserCustomAttribute(
    id: string,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .duplicateUserCustomAttribute(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary AIレコメンドモデルの一覧取得
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public fetchAiRecommendationModels(
    limit?: number,
    offset?: number,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .fetchAiRecommendationModels(limit, offset, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary カウンセリングの一覧取得
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {any} [excludes]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public fetchCounselings(
    limit?: number,
    offset?: number,
    excludes?: any,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .fetchCounselings(limit, offset, excludes, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary ダッシュボードで使用する情報を取得
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public fetchDashboard(options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .fetchDashboard(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 診断ユーザーに紐づくフィードバック閲覧ログの取得
   * @param {string} id
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public fetchDiagnosisUserFeedbackLogs(
    id: string,
    limit?: number,
    offset?: number,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .fetchDiagnosisUserFeedbackLogs(id, limit, offset, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 診断ユーザーに紐づく診断履歴の取得
   * @param {string} id
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public fetchDiagnosisUserHistories(
    id: string,
    limit?: number,
    offset?: number,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .fetchDiagnosisUserHistories(id, limit, offset, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 診断ユーザーに紐づく商品閲覧ログの取得
   * @param {string} id
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public fetchDiagnosisUserProductLogs(
    id: string,
    limit?: number,
    offset?: number,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .fetchDiagnosisUserProductLogs(id, limit, offset, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 診断ユーザーの一覧取得
   * @param {number} limit
   * @param {string} [nextKey]
   * @param {'Connected' | 'Reject' | 'Request' | 'Resolve' | 'None'} [status]
   * @param {string} [connectId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public fetchDiagnosisUsers(
    limit: number,
    nextKey?: string,
    status?: 'Connected' | 'Reject' | 'Request' | 'Resolve' | 'None',
    connectId?: string,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .fetchDiagnosisUsers(limit, nextKey, status, connectId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary フィードバックの一覧取得
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {any} [excludes]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public fetchFeedbacks(
    limit?: number,
    offset?: number,
    excludes?: any,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .fetchFeedbacks(limit, offset, excludes, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 商品カテゴリ一覧を取得
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public fetchProductCategories(
    limit?: number,
    offset?: number,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .fetchProductCategories(limit, offset, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 商品タグを取得
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public fetchProductTags(options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .fetchProductTags(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 商品名、タグでフィルタリング可能
   * @summary 商品一覧を取得
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [keyword]
   * @param {string} [category]
   * @param {string} [tag]
   * @param {any} [excludes]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public fetchProducts(
    limit?: number,
    offset?: number,
    keyword?: string,
    category?: string,
    tag?: string,
    excludes?: any,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .fetchProducts(limit, offset, keyword, category, tag, excludes, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary プロジェクト情報の取得
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public fetchProject(options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .fetchProject(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary リリースの一覧取得
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {any} [excludes]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public fetchReleases(
    limit?: number,
    offset?: number,
    excludes?: any,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .fetchReleases(limit, offset, excludes, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary ルールの一覧取得
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {any} [excludes]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public fetchRules(
    limit?: number,
    offset?: number,
    excludes?: any,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .fetchRules(limit, offset, excludes, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary カスタムユーザー属性の一覧取得
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {any} [excludes]
   * @param {any} [includes]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public fetchUserCustomAttributes(
    limit?: number,
    offset?: number,
    excludes?: any,
    includes?: any,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .fetchUserCustomAttributes(limit, offset, excludes, includes, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary AIレコメンドモデルの詳細取得
   * @param {string} id 購買データCSVのSHA256ハッシュ値
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public findAiRecommendationModelById(
    id: string,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .findAiRecommendationModelById(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary カウンセリングの詳細取得
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public findCounselingById(id: string, options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .findCounselingById(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 診断ユーザーの詳細取得
   * @param {string} id
   * @param {any} [not]
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public findDiagnosisUserById(
    id: string,
    not?: any,
    limit?: number,
    offset?: number,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .findDiagnosisUserById(id, not, limit, offset, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary フィードバックの詳細取得
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public findFeedbackById(id: string, options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .findFeedbackById(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 商品詳細の取得
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public findProductById(id: string, options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .findProductById(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 商品カテゴリ詳細の取得
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public findProductCategoryById(id: string, options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .findProductCategoryById(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary アクティブなリリースを取得
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public findReleaseByActive(options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .findReleaseByActive(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary リリースの詳細取得
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public findReleaseById(id: string, options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .findReleaseById(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary ルールの詳細取得
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public findRuleById(id: string, options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .findRuleById(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary カスタムユーザー属性の詳細取得
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public findUserCustomAttributeById(id: string, options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .findUserCustomAttributeById(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 診断画像一式のzipを生成して返す
   * @param {string} before
   * @param {string} after
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public generatePortraitZip(
    before: string,
    after: string,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .generatePortraitZip(before, after, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary カスタムページの情報を取得する
   * @param {'welcome' | 'privacyPolicy' | 'termsOfService'} page
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public getCustomPage(
    page: 'welcome' | 'privacyPolicy' | 'termsOfService',
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .getCustomPage(page, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary プロバイダーのアカウント情報を取得する
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public getProvierAccount(options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .getProvierAccount(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary プロジェクトに紐づくStripeの支払いポータルサイトへのリンクを取得
   * @param {string} redirectUrl
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public getStripePortalUrl(redirectUrl: string, options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .getStripePortalUrl(redirectUrl, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 最新状態を本番環境に反映する
   * @param {object} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public publishReleaseToProduction(
    body?: object,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .publishReleaseToProduction(body, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary アクセストークンの更新
   * @param {object} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public refreshProviderAccessToken(
    body?: object,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .refreshProviderAccessToken(body, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary カスタムページの更新を申請する
   * @param {'welcome' | 'privacyPolicy' | 'termsOfService'} page
   * @param {GetCustomPage200ResponseItem} getCustomPage200ResponseItem
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public requestCustomPageUpdate(
    page: 'welcome' | 'privacyPolicy' | 'termsOfService',
    getCustomPage200ResponseItem: GetCustomPage200ResponseItem,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .requestCustomPageUpdate(page, getCustomPage200ResponseItem, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary プロバイダーのパスワードリセットの申請
   * @param {RequestProviderPasswordResetRequest} requestProviderPasswordResetRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public requestProviderPasswordReset(
    requestProviderPasswordResetRequest: RequestProviderPasswordResetRequest,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .requestProviderPasswordReset(
        requestProviderPasswordResetRequest,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary お問い合わせを送る
   * @param {SendInquiryRequest} sendInquiryRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public sendInquiry(
    sendInquiryRequest: SendInquiryRequest,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .sendInquiry(sendInquiryRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary プロバイダー権限のサインイン
   * @param {SigninAdminRequest} signinAdminRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public signinProvider(
    signinAdminRequest: SigninAdminRequest,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .signinProvider(signinAdminRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary プロバイダー権限のサインアウト
   * @param {object} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public signoutProvider(body?: object, options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .signoutProvider(body, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary プロジェクトを切り替える
   * @param {SwitchActiveProjectRequest} switchActiveProjectRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public switchActiveProject(
    switchActiveProjectRequest: SwitchActiveProjectRequest,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .switchActiveProject(switchActiveProjectRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary AIレコメンドモデルの更新
   * @param {string} id 購買データCSVのSHA256ハッシュ値
   * @param {object} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public updateAiRecommendationModel(
    id: string,
    body?: object,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .updateAiRecommendationModel(id, body, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary カウンセリングの更新
   * @param {string} id
   * @param {CreateCounselingRequest} createCounselingRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public updateCounseling(
    id: string,
    createCounselingRequest: CreateCounselingRequest,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .updateCounseling(id, createCounselingRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary フィードバックの更新
   * @param {string} id
   * @param {CreateFeedbackRequest} createFeedbackRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public updateFeedback(
    id: string,
    createFeedbackRequest: CreateFeedbackRequest,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .updateFeedback(id, createFeedbackRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 商品の更新
   * @param {string} id
   * @param {CreateProductsRequestItemsInner} createProductsRequestItemsInner
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public updateProduct(
    id: string,
    createProductsRequestItemsInner: CreateProductsRequestItemsInner,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .updateProduct(id, createProductsRequestItemsInner, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 商品カテゴリの更新
   * @param {string} id
   * @param {CreateProductCategoryRequest} createProductCategoryRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public updateProductCategory(
    id: string,
    createProductCategoryRequest: CreateProductCategoryRequest,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .updateProductCategory(id, createProductCategoryRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 商品画像の更新
   * @param {string} idOrCode
   * @param {UpdateProductImageRequest} updateProductImageRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public updateProductImage(
    idOrCode: string,
    updateProductImageRequest: UpdateProductImageRequest,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .updateProductImage(idOrCode, updateProductImageRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary プロジェクト情報の更新
   * @param {UpdateProjectRequest} [updateProjectRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public updateProject(
    updateProjectRequest?: UpdateProjectRequest,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .updateProject(updateProjectRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary プロバイダーのパスワードリセットの実行
   * @param {UpdateProviderPasswordResetRequest} updateProviderPasswordResetRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public updateProviderPasswordReset(
    updateProviderPasswordResetRequest: UpdateProviderPasswordResetRequest,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .updateProviderPasswordReset(updateProviderPasswordResetRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary プロバイダーのアカウント情報を更新する
   * @param {UpdateProvierAccountRequest} updateProvierAccountRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public updateProvierAccount(
    updateProvierAccountRequest: UpdateProvierAccountRequest,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .updateProvierAccount(updateProvierAccountRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary リリースの更新
   * @param {string} id
   * @param {UpdateReleaseRequest} [updateReleaseRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public updateRelease(
    id: string,
    updateReleaseRequest?: UpdateReleaseRequest,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .updateRelease(id, updateReleaseRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary ルールの更新
   * @param {string} id
   * @param {UpdateRuleRequest} [updateRuleRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public updateRule(
    id: string,
    updateRuleRequest?: UpdateRuleRequest,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .updateRule(id, updateRuleRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary カスタムユーザー属性の更新
   * @param {string} id
   * @param {UpdateUserCustomAttributeRequest} [updateUserCustomAttributeRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public updateUserCustomAttribute(
    id: string,
    updateUserCustomAttributeRequest?: UpdateUserCustomAttributeRequest,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .updateUserCustomAttribute(id, updateUserCustomAttributeRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary CSVファイルの分割アップロード
   * @param {any} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public uploadCSVFileMultiparts(body?: any, options?: AxiosRequestConfig) {
    return ProviderApiFp(this.configuration)
      .uploadCSVFileMultiparts(body, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 更新リクエストを取り消す
   * @param {string} requestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProviderApi
   */
  public withdrawUpdateRequest(
    requestId: string,
    options?: AxiosRequestConfig
  ) {
    return ProviderApiFp(this.configuration)
      .withdrawUpdateRequest(requestId, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary 肌診断をする
     * @param {DetectSkinRequest} detectSkinRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    detectSkin: async (
      detectSkinRequest: DetectSkinRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'detectSkinRequest' is not null or undefined
      assertParamExists('detectSkin', 'detectSkinRequest', detectSkinRequest)
      const localVarPath = `/v1/user/diagnosis`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        detectSkinRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 診断結果一覧を取得
     * @param {number} before
     * @param {number} after
     * @param {number} first
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchDiagnoses: async (
      before: number,
      after: number,
      first: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'before' is not null or undefined
      assertParamExists('fetchDiagnoses', 'before', before)
      // verify required parameter 'after' is not null or undefined
      assertParamExists('fetchDiagnoses', 'after', after)
      // verify required parameter 'first' is not null or undefined
      assertParamExists('fetchDiagnoses', 'first', first)
      const localVarPath = `/v1/user/diagnosis`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (before !== undefined) {
        localVarQueryParameter['before'] = before
      }

      if (after !== undefined) {
        localVarQueryParameter['after'] = after
      }

      if (first !== undefined) {
        localVarQueryParameter['first'] = first
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 診断履歴一覧を取得
     * @param {number} lastAt
     * @param {string} [startToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchHistories: async (
      lastAt: number,
      startToken?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'lastAt' is not null or undefined
      assertParamExists('fetchHistories', 'lastAt', lastAt)
      const localVarPath = `/v1/user/diagnosis/history`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (startToken !== undefined) {
        localVarQueryParameter['startToken'] = startToken
      }

      if (lastAt !== undefined) {
        localVarQueryParameter['lastAt'] = lastAt
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary ログイン済の場合にリフレッシュトークンからユーザー情報を抽出
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchUserByRefreshToken: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/user/auth/token`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 診断結果を取得
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findDiagnosisById: async (
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('findDiagnosisById', 'id', id)
      const localVarPath = `/v1/user/diagnosis/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary プロジェクト情報を取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findProjectByIdOnUser: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('findProjectByIdOnUser', 'id', id)
      const localVarPath = `/v1/user/project/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 初期化処理
     * @param {InitializeUserRequest} initializeUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initializeUser: async (
      initializeUserRequest: InitializeUserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'initializeUserRequest' is not null or undefined
      assertParamExists(
        'initializeUser',
        'initializeUserRequest',
        initializeUserRequest
      )
      const localVarPath = `/v1/user/auth`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        initializeUserRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary ログアウト（リフレッシュトークンの破棄）
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logoutUser: async (
      body?: object,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/user/auth/token`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary ユーザーの行動ログを保存
     * @param {RecordUserEventRequest} recordUserEventRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordUserEvent: async (
      recordUserEventRequest: RecordUserEventRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'recordUserEventRequest' is not null or undefined
      assertParamExists(
        'recordUserEvent',
        'recordUserEventRequest',
        recordUserEventRequest
      )
      const localVarPath = `/v1/user/event`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        recordUserEventRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary リフレッシュトークンを使ったアクセストークンの更新
     * @param {RefreshUserAccessTokenRequest} [refreshUserAccessTokenRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshUserAccessToken: async (
      refreshUserAccessTokenRequest?: RefreshUserAccessTokenRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/user/auth/token`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        refreshUserAccessTokenRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary アカウント情報を更新
     * @param {UpdateUserProfileRequest} [updateUserProfileRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserProfile: async (
      updateUserProfileRequest?: UpdateUserProfileRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/user/me`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserProfileRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary ID連携トークンが正しいか検証する
     * @param {VerifyUserConnectIdRequest} verifyUserConnectIdRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyUserConnectId: async (
      verifyUserConnectIdRequest: VerifyUserConnectIdRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'verifyUserConnectIdRequest' is not null or undefined
      assertParamExists(
        'verifyUserConnectId',
        'verifyUserConnectIdRequest',
        verifyUserConnectIdRequest
      )
      const localVarPath = `/v1/user/auth/connectId/verify`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyUserConnectIdRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary 肌診断をする
     * @param {DetectSkinRequest} detectSkinRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async detectSkin(
      detectSkinRequest: DetectSkinRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DetectSkin201Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.detectSkin(
        detectSkinRequest,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 診断結果一覧を取得
     * @param {number} before
     * @param {number} after
     * @param {number} first
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchDiagnoses(
      before: number,
      after: number,
      first: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FetchDiagnoses200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fetchDiagnoses(
        before,
        after,
        first,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 診断履歴一覧を取得
     * @param {number} lastAt
     * @param {string} [startToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchHistories(
      lastAt: number,
      startToken?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FetchHistories200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fetchHistories(
        lastAt,
        startToken,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary ログイン済の場合にリフレッシュトークンからユーザー情報を抽出
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchUserByRefreshToken(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FetchUserByRefreshToken200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.fetchUserByRefreshToken(options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 診断結果を取得
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findDiagnosisById(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DetectSkin201Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findDiagnosisById(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary プロジェクト情報を取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findProjectByIdOnUser(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<FindProjectByIdOnUser200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.findProjectByIdOnUser(id, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 初期化処理
     * @param {InitializeUserRequest} initializeUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async initializeUser(
      initializeUserRequest: InitializeUserRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InitializeUser200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.initializeUser(
        initializeUserRequest,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary ログアウト（リフレッシュトークンの破棄）
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async logoutUser(
      body?: object,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.logoutUser(
        body,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary ユーザーの行動ログを保存
     * @param {RecordUserEventRequest} recordUserEventRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recordUserEvent(
      recordUserEventRequest: RecordUserEventRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.recordUserEvent(
        recordUserEventRequest,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary リフレッシュトークンを使ったアクセストークンの更新
     * @param {RefreshUserAccessTokenRequest} [refreshUserAccessTokenRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshUserAccessToken(
      refreshUserAccessTokenRequest?: RefreshUserAccessTokenRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<RefreshUserAccessToken200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.refreshUserAccessToken(
          refreshUserAccessTokenRequest,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary アカウント情報を更新
     * @param {UpdateUserProfileRequest} [updateUserProfileRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserProfile(
      updateUserProfileRequest?: UpdateUserProfileRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UpdateUserProfile200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateUserProfile(
          updateUserProfileRequest,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary ID連携トークンが正しいか検証する
     * @param {VerifyUserConnectIdRequest} verifyUserConnectIdRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyUserConnectId(
      verifyUserConnectIdRequest: VerifyUserConnectIdRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<VerifyUserConnectId200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.verifyUserConnectId(
          verifyUserConnectIdRequest,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UserApiFp(configuration)
  return {
    /**
     *
     * @summary 肌診断をする
     * @param {DetectSkinRequest} detectSkinRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    detectSkin(
      detectSkinRequest: DetectSkinRequest,
      options?: any
    ): AxiosPromise<DetectSkin201Response> {
      return localVarFp
        .detectSkin(detectSkinRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 診断結果一覧を取得
     * @param {number} before
     * @param {number} after
     * @param {number} first
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchDiagnoses(
      before: number,
      after: number,
      first: number,
      options?: any
    ): AxiosPromise<FetchDiagnoses200Response> {
      return localVarFp
        .fetchDiagnoses(before, after, first, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 診断履歴一覧を取得
     * @param {number} lastAt
     * @param {string} [startToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchHistories(
      lastAt: number,
      startToken?: string,
      options?: any
    ): AxiosPromise<FetchHistories200Response> {
      return localVarFp
        .fetchHistories(lastAt, startToken, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary ログイン済の場合にリフレッシュトークンからユーザー情報を抽出
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchUserByRefreshToken(
      options?: any
    ): AxiosPromise<FetchUserByRefreshToken200Response> {
      return localVarFp
        .fetchUserByRefreshToken(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 診断結果を取得
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findDiagnosisById(
      id: number,
      options?: any
    ): AxiosPromise<DetectSkin201Response> {
      return localVarFp
        .findDiagnosisById(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary プロジェクト情報を取得
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findProjectByIdOnUser(
      id: string,
      options?: any
    ): AxiosPromise<FindProjectByIdOnUser200Response> {
      return localVarFp
        .findProjectByIdOnUser(id, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 初期化処理
     * @param {InitializeUserRequest} initializeUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initializeUser(
      initializeUserRequest: InitializeUserRequest,
      options?: any
    ): AxiosPromise<InitializeUser200Response> {
      return localVarFp
        .initializeUser(initializeUserRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary ログアウト（リフレッシュトークンの破棄）
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logoutUser(body?: object, options?: any): AxiosPromise<string> {
      return localVarFp
        .logoutUser(body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary ユーザーの行動ログを保存
     * @param {RecordUserEventRequest} recordUserEventRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recordUserEvent(
      recordUserEventRequest: RecordUserEventRequest,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .recordUserEvent(recordUserEventRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary リフレッシュトークンを使ったアクセストークンの更新
     * @param {RefreshUserAccessTokenRequest} [refreshUserAccessTokenRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshUserAccessToken(
      refreshUserAccessTokenRequest?: RefreshUserAccessTokenRequest,
      options?: any
    ): AxiosPromise<RefreshUserAccessToken200Response> {
      return localVarFp
        .refreshUserAccessToken(refreshUserAccessTokenRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary アカウント情報を更新
     * @param {UpdateUserProfileRequest} [updateUserProfileRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserProfile(
      updateUserProfileRequest?: UpdateUserProfileRequest,
      options?: any
    ): AxiosPromise<UpdateUserProfile200Response> {
      return localVarFp
        .updateUserProfile(updateUserProfileRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary ID連携トークンが正しいか検証する
     * @param {VerifyUserConnectIdRequest} verifyUserConnectIdRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyUserConnectId(
      verifyUserConnectIdRequest: VerifyUserConnectIdRequest,
      options?: any
    ): AxiosPromise<VerifyUserConnectId200Response> {
      return localVarFp
        .verifyUserConnectId(verifyUserConnectIdRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   *
   * @summary 肌診断をする
   * @param {DetectSkinRequest} detectSkinRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public detectSkin(
    detectSkinRequest: DetectSkinRequest,
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .detectSkin(detectSkinRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 診断結果一覧を取得
   * @param {number} before
   * @param {number} after
   * @param {number} first
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public fetchDiagnoses(
    before: number,
    after: number,
    first: number,
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .fetchDiagnoses(before, after, first, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 診断履歴一覧を取得
   * @param {number} lastAt
   * @param {string} [startToken]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public fetchHistories(
    lastAt: number,
    startToken?: string,
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .fetchHistories(lastAt, startToken, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary ログイン済の場合にリフレッシュトークンからユーザー情報を抽出
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public fetchUserByRefreshToken(options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .fetchUserByRefreshToken(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 診断結果を取得
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public findDiagnosisById(id: number, options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .findDiagnosisById(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary プロジェクト情報を取得
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public findProjectByIdOnUser(id: string, options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .findProjectByIdOnUser(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 初期化処理
   * @param {InitializeUserRequest} initializeUserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public initializeUser(
    initializeUserRequest: InitializeUserRequest,
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .initializeUser(initializeUserRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary ログアウト（リフレッシュトークンの破棄）
   * @param {object} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public logoutUser(body?: object, options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .logoutUser(body, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary ユーザーの行動ログを保存
   * @param {RecordUserEventRequest} recordUserEventRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public recordUserEvent(
    recordUserEventRequest: RecordUserEventRequest,
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .recordUserEvent(recordUserEventRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary リフレッシュトークンを使ったアクセストークンの更新
   * @param {RefreshUserAccessTokenRequest} [refreshUserAccessTokenRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public refreshUserAccessToken(
    refreshUserAccessTokenRequest?: RefreshUserAccessTokenRequest,
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .refreshUserAccessToken(refreshUserAccessTokenRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary アカウント情報を更新
   * @param {UpdateUserProfileRequest} [updateUserProfileRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public updateUserProfile(
    updateUserProfileRequest?: UpdateUserProfileRequest,
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .updateUserProfile(updateUserProfileRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary ID連携トークンが正しいか検証する
   * @param {VerifyUserConnectIdRequest} verifyUserConnectIdRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public verifyUserConnectId(
    verifyUserConnectIdRequest: VerifyUserConnectIdRequest,
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .verifyUserConnectId(verifyUserConnectIdRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
