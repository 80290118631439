import { z } from 'zod'

const jwtSchema = z.object({
  refreshToken: z.string(),
})

export const getJWT = (key: string) => {
  try {
    const value = localStorage.getItem(key)
    return jwtSchema.parse(JSON.parse(value || ''))
  } catch {
    return null
  }
}

export const setJWT = (key: string, refreshToken: string | null) => {
  if (refreshToken) {
    localStorage.setItem(key, JSON.stringify({ refreshToken }))
  } else {
    localStorage.removeItem(key)
  }
}
