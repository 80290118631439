import {
  Box,
  Chip,
  ChipProps,
  styled,
  TextField,
  TextFieldProps,
} from '@mui/material'
import {
  FocusEventHandler,
  KeyboardEventHandler,
  useState,
  useEffect,
  useCallback,
  ChangeEventHandler,
  forwardRef,
} from 'react'

export type TagsInputFieldProps = TextFieldProps & {
  onValidate?(tag: string): string | undefined
  onUpdate?(tags: string[]): void
  defaultTags?: string[]
  chipProps?: Omit<ChipProps, 'label' | 'onDelete'>
}

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    flexWrap: 'wrap',
  },
  '& .MuiInputBase-input': {
    flex: 1,
    minWidth: 100,
    padding: '6px 0',
  },
})

export const TagsInputField = forwardRef<HTMLInputElement, TagsInputFieldProps>(
  (
    {
      defaultTags: tags,
      onValidate,
      onUpdate,
      chipProps,
      InputProps,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useState('')
    const [selectedItem, setSelectedItem] = useState<string[]>([])

    // 初期値
    useEffect(() => {
      if (tags) {
        setSelectedItem(tags.map((tag) => `${tag}`))
      }
    }, [tags])

    const maybeAddTag = useCallback(
      (tag: string) => {
        const validatedTag = onValidate ? onValidate(tag) : tag

        if (
          !validatedTag ||
          selectedItem.includes(validatedTag) ||
          !validatedTag.replace(/\s/g, '').length
        ) {
          return
        }

        setSelectedItem([...selectedItem, validatedTag])
        onUpdate?.([...selectedItem, validatedTag])
        setValue('')
      },
      [selectedItem, onValidate, onUpdate]
    )

    const onKeyDown: KeyboardEventHandler<
      HTMLInputElement | HTMLTextAreaElement
    > = useCallback(
      (e) => {
        const tag = (
          e.target as HTMLInputElement | HTMLTextAreaElement
        ).value.trim()

        switch (e.key) {
          case ',':
            e.preventDefault()
            maybeAddTag(tag)
            break
          // case 'Backspace':
          //   if (selectedItem.length && !tag.length) {
          //     setSelectedItem(selectedItem.slice(0, selectedItem.length - 1))
          //   }
          //   break
          default:
            break
        }
      },
      [maybeAddTag]
    )

    const onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> =
      useCallback(
        (e) => {
          const tag = e.target.value.trim()
          if (tag) {
            maybeAddTag(tag)
          }
        },
        [maybeAddTag]
      )

    const onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =
      useCallback((e) => {
        setValue(e.target.value.trim())
      }, [])

    const handleDelete = useCallback(
      (item: string) => () => {
        setSelectedItem((prev) => {
          const newTags = [...prev]
          newTags.splice(newTags.indexOf(item), 1)
          onUpdate?.(newTags)
          return newTags
        })
      },
      [onUpdate]
    )

    return (
      <StyledTextField
        value={value}
        multiline
        size="small"
        InputProps={{
          ...InputProps,
          startAdornment: (
            <Box
              sx={{
                display: 'inline-flex',
                flexWrap: 'wrap',
                gap: 1,
                maxWidth: '100%',
                marginRight: 1,
              }}
            >
              {selectedItem.map((item) => (
                <Chip
                  key={item}
                  {...chipProps}
                  label={item}
                  tabIndex={-1}
                  onDelete={
                    InputProps?.readOnly ? undefined : handleDelete(item)
                  }
                />
              ))}
            </Box>
          ),
          onChange,
          onKeyDown,
          onBlur,
        }}
        ref={ref}
        {...props}
      />
    )
  }
)
