import z from 'zod'

const permissions = [
  '*.*',
  'project.*',
  'project.read',
  'project.write',
  'project.update',
  'project-general.*',
  'project-general.read',
  'project-general.write',
  'project-general.update',
  'project-branding.*',
  'project-branding.read',
  'project-branding.write',
  'project-branding.update',
  'project-custom_page.*',
  'project-custom_page.read',
  'project-custom_page.write',
  'project-custom_page.create',
  'project-custom_page.update',
  'project-custom_page.delete',
  'project-widget.*',
  'project-widget.read',
  'project-widget.write',
  'project-widget.update',
  'user.*',
  'user.read',
  'user.csv',
  'user.portrait',
  'user-custom_attribute.*',
  'user-custom_attribute.read',
  'user-custom_attribute.write',
  'user-custom_attribute.create',
  'user-custom_attribute.update',
  'user-custom_attribute.delete',
  'product.*',
  'product.read',
  'product.write',
  'product.create',
  'product.update',
  'product.delete',
  'product-category.*',
  'product-category.read',
  'product-category.write',
  'product-category.create',
  'product-category.update',
  'product-category.delete',
  'feedback.*',
  'feedback.read',
  'feedback.write',
  'feedback.create',
  'feedback.update',
  'feedback.delete',
  'counseling.*',
  'counseling.read',
  'counseling.write',
  'counseling.create',
  'counseling.update',
  'counseling.delete',
  'rule.*',
  'rule.read',
  'rule.write',
  'rule.create',
  'rule.update',
  'rule.delete',
  'release.*',
  'release.read',
  'release.publish',
  'release.debug',
  'release.write',
  'release.create',
  'release.update',
  'release.delete',
  'ai-recommendation-model.*',
  'ai-recommendation-model.read',
  'ai-recommendation-model.write',
  'ai-recommendation-model.create',
  'ai-recommendation-model.update',
  'ai-recommendation-model.delete',
] as const

export const permissionSchema = z.enum(permissions)

export type Permission = z.infer<typeof permissionSchema>
