import { FC, useEffect } from 'react'
import { useLoading } from '../../common/contexts/LoadingContext'

export interface UnifiedFallbackLoaderProps {
  source: string
}

export const UnifiedFallbackLoader: FC<UnifiedFallbackLoaderProps> = ({
  source,
}) => {
  const { addLoadingSource, removeLoadingSource } = useLoading()

  useEffect(() => {
    addLoadingSource(source)
    return () => {
      removeLoadingSource(source)
    }
  }, [source, addLoadingSource, removeLoadingSource])

  return null
}
