import './sentry'
import './common/styles/global.scss'

import { createRoot } from 'react-dom/client'
import { App } from './App'
// import { queryString } from './common/utils/queryString'

const DEFAULT_MOUNT_TO = 's2-root'

const defaultProps: SetupProps = {
  mountTo: `#${DEFAULT_MOUNT_TO}`,
}

let instance: S2InternalInstance | null = null

const setup = (props?: SetupProps) => {
  const {
    mountTo,
    routerType,
    baseUrl,
    projectId,
    idToken,
    idLinkage,
    dynamicParams,
    transferKey,
  } = {
    ...defaultProps,
    ...props,
  }

  try {
    if (!projectId) {
      throw new Error('プロジェクトIDが指定されていません。')
    }

    let el = mountTo
      ? document.querySelector(mountTo)
      : document.getElementById(DEFAULT_MOUNT_TO)

    if (!el) {
      el = document.createElement('div')
      el.id = DEFAULT_MOUNT_TO
      document.body.appendChild(el)
    }

    createRoot(el).render(
      <App
        projectId={projectId}
        idToken={idToken}
        idLinkage={idLinkage}
        routerType={routerType}
        baseUrl={baseUrl}
        dynamicParams={dynamicParams}
        transferKey={transferKey}
        onAuthorized={(_instance) => {
          instance = _instance
        }}
      />
    )
  } catch (e) {
    // eslint-disable-next-line no-alert
    alert(
      e instanceof Error
        ? e.message
        : 'セットアップ中にエラーが発生しました。\n設定を見直してください。'
    )
  }
}

const callback = () => {
  if (typeof window.s2Ready === 'function') {
    window.s2Ready({
      setup,
      getLatestDiagnosisTransferKey: async () =>
        instance?.getLatestDiagnosisTransferKey() ?? null,
    })
  }
}

if (document.readyState === 'loading') {
  window.addEventListener('DOMContentLoaded', callback, { once: true })
} else {
  callback()
}
