import { FC, PropsWithChildren, useMemo, useRef, useState } from 'react'
import { ThemeProvider, styled } from '@mui/material'
import { throttle, createAppTheme } from 's2-lib'
import { createBoxShadow } from '../../common/utils/createBoxShadow'
import { FillHeight } from './FillHeight'
import { useProjectValueLoadable } from '../../common/hooks/useProject'
import { LinkBehavior } from '../ui/LinkBehavior'

const headerHeight = '56px'

const Header = styled('header')(({ theme }) => {
  const { shadows, transitions } = theme
  // const mainColor = palette.primary.main
  return {
    position: 'absolute',
    zIndex: 15,
    top: 0,
    left: 0,
    width: '100%',
    height: headerHeight,
    backgroundColor: '#fff',
    // boxShadow: createBoxShadow(shadows[3], 'rgb(0,0,0)', 0.25),
    transition: transitions.create(['box-shadow'], {
      duration: transitions.duration.short,
      easing: transitions.easing.easeOut,
    }),
    '&.scrolling': {
      boxShadow: createBoxShadow(shadows[3], 'rgb(0,0,0)', 0.5),
      // boxShadow: createBoxShadow(shadows[3], mainColor, 0.5),
    },
  }
})

const Scroller = styled('main')({
  overflowY: 'auto',
  WebkitOverflowScrolling: 'touch',
  MsOverflowStyle: 'none',
  height: '100%',
})

export const BaseLayout: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [reachedTop, setReachedTop] = useState(true)
  const ref = useRef<HTMLDivElement>(null)
  const onScroll = useMemo(
    () =>
      throttle(() => {
        setReachedTop(ref.current?.scrollTop === 0)
      }, 250),
    []
  )
  const projectLoadable = useProjectValueLoadable()
  const theme = useMemo(() => {
    const color =
      projectLoadable.state === 'hasValue'
        ? projectLoadable.contents.color
        : undefined
    return createAppTheme({ ...color, LinkBehavior })
  }, [projectLoadable.contents.color, projectLoadable.state])

  return (
    <ThemeProvider theme={theme}>
      <FillHeight
        sx={{
          mx: 'auto',
          maxWidth: 'sm',
          position: 'relative',
          backgroundColor: '#fff',
          pt: headerHeight,
          boxShadow: (t) =>
            createBoxShadow(t.shadows[6], t.palette.primary.main),
        }}
      >
        <Header id="s2-ghead" className={reachedTop ? '' : 'scrolling'} />
        <Scroller ref={ref} onScroll={onScroll}>
          {children}
        </Scroller>
      </FillHeight>
    </ThemeProvider>
  )
}
