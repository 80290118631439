export function throttle<T extends any[]>(
  fn: (...args: T) => void,
  delay: number
): (...args: T) => void {
  let lastExecTime = 0
  let timerId: NodeJS.Timeout | undefined

  return (...args: T) => {
    const now = performance.now()

    if (now - lastExecTime >= delay) {
      // 指定された間隔が経過している場合は即時実行
      fn(...args)
      lastExecTime = now
    } else {
      // 経過していない場合は遅延実行を予約
      if (timerId) clearTimeout(timerId)
      timerId = setTimeout(() => {
        fn(...args)
        lastExecTime = performance.now()
      }, delay - (now - lastExecTime))
    }
  }
}

export function debounce<T extends any[]>(
  fn: (...args: T) => void,
  delay: number
): (...args: T) => void {
  let timerId: NodeJS.Timeout | undefined

  return (...args: T) => {
    if (timerId) clearTimeout(timerId)
    timerId = setTimeout(() => fn(...args), delay)
  }
}
