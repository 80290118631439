import { forwardRef, useMemo } from 'react'
import {
  Location,
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useLocation,
} from 'react-router-dom'

const mergeLocation = (
  to: RouterLinkProps['to'],
  location: Location
): Location => {
  let pathname: string
  let searchParams: URLSearchParams
  const currentSearchParamas = new URLSearchParams(location.search)
  const currentDParams = currentSearchParamas.get('d-params')
  const currentProjectId = currentSearchParamas.get('pid')

  if (typeof to === 'string') {
    const url = new URL(to, window.location.href)
    pathname = url.pathname
    searchParams = url.searchParams
  } else {
    pathname = to.pathname ?? location.pathname
    searchParams = new URLSearchParams(to.search)
  }

  if (!searchParams.has('d-params') && currentDParams) {
    searchParams.set('d-params', currentDParams)
  }

  if (!searchParams.has('pid') && currentProjectId) {
    searchParams.set('pid', currentProjectId)
  }

  return {
    ...location,
    pathname,
    search: searchParams.toString(),
    hash: typeof to === 'string' ? location.hash : to.hash || location.hash,
  }
}

export const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>(({ href, target: targetProp, rel: relProp, ...other }, ref) => {
  const loc = useLocation()
  const isExternal =
    targetProp === '_blank' ||
    (typeof href === 'string' && /^(http|mailto)/.test(href))
  const to = useMemo(
    () => (isExternal ? href : mergeLocation(href, loc)),
    [href, isExternal, loc]
  )
  const target = targetProp || (isExternal ? '_blank' : '_self')
  const rel = relProp || (isExternal ? 'noopener noreferrer' : '')

  if (isExternal) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label
      <a ref={ref} href={to as string} target={target} rel={rel} {...other} />
    )
  }

  return <RouterLink ref={ref} to={to} target={target} rel={rel} {...other} />
})

LinkBehavior.displayName = 'LinkBehavior'
