import {
  styled,
  Box,
  BoxProps,
  FormControl,
  FormHelperText,
  Stack,
  Typography,
  alpha,
  Button,
} from '@mui/material'
import {
  FC,
  ChangeEventHandler,
  DragEventHandler,
  useCallback,
  useRef,
  useState,
  PropsWithChildren,
  MouseEvent,
  useEffect,
} from 'react'
import { ControllerProps, FieldValues, Path } from 'react-hook-form'
import CloseIcon from '@mui/icons-material/Close'
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto'
import { readFile } from 's2-lib'
import { Description } from '../../ui-elements/Description'

type UploadFormProps = {
  accept?: string
  defaultValue?: string
  dragAreaProps?: BoxProps
  readOnly?: boolean
  onChange(base64: string | null): void
}

const StyledDragArea = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#efefef',
  border: '1px dashed #ccc',
  borderRadius: 1,
  transition: 'all 0.25s ease-out',
  '& .MuiSvgIcon-root path': {
    transition: 'fill 0.25s ease-out',
  },
  '&:hover': {
    cursor: 'pointer',
  },
  '&.dragover, &:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    borderColor: theme.palette.primary.main,
    '& .MuiSvgIcon-root path': {
      fill: '#fff',
    },
  },
}))

export type S2DragAreaProps = Pick<BoxProps, 'sx' | 'component'> & {
  onAdd?(fileList: FileList | null): void
  accept?: string
  readOnly?: boolean
}

export const S2DragArea: FC<PropsWithChildren<S2DragAreaProps>> = ({
  children,
  onAdd,
  accept = 'image/jpeg, image/png',
  readOnly,
  ...props
}) => {
  const fileInput = useRef<HTMLInputElement | null>(null)
  const dragArea = useRef<HTMLDivElement>()

  const handleDrop: DragEventHandler = useCallback(
    (e) => {
      e.preventDefault()
      onAdd?.(e.dataTransfer.files)
      dragArea.current?.classList.remove('dragover')
    },
    [onAdd]
  )

  const handleDragOver: DragEventHandler = useCallback((e) => {
    e.preventDefault()
    dragArea.current?.classList.add('dragover')
  }, [])

  const handleDragLeave: DragEventHandler = useCallback((e) => {
    e.preventDefault()
    dragArea.current?.classList.remove('dragover')
  }, [])

  const handleClick = useCallback(() => {
    fileInput.current?.click()
  }, [])

  const handleFileChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onAdd?.(e.target.files)
    },
    [onAdd]
  )

  return (
    <>
      <StyledDragArea
        onClick={readOnly ? undefined : handleClick}
        onDrop={readOnly ? undefined : handleDrop}
        onDragOver={readOnly ? undefined : handleDragOver}
        onDragLeave={readOnly ? undefined : handleDragLeave}
        ref={dragArea}
        style={{
          pointerEvents: readOnly ? 'none' : 'auto',
        }}
        {...props}
      >
        {children || (
          <Stack spacing={1} alignItems="center">
            <InsertPhotoIcon sx={{ color: '#ccc', width: 64, height: 64 }} />
            {!readOnly && (
              <>
                <Typography
                  color={(theme) => theme.palette.primary.main}
                  variant="body1"
                >
                  画像をドラッグ＆ドロップ
                </Typography>
                <Typography variant="body2">または</Typography>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ backgroundColor: '#fff' }}
                >
                  画像を選択
                </Button>
              </>
            )}
          </Stack>
        )}
      </StyledDragArea>
      <Box
        sx={{
          visibility: 'hidden',
          position: 'absolute',
          pointerEvents: 'none',
        }}
      >
        <input
          accept={accept}
          type="file"
          ref={fileInput}
          onChange={handleFileChange}
        />
      </Box>
    </>
  )
}

export const UploadForm: FC<UploadFormProps> = ({
  accept,
  defaultValue,
  dragAreaProps,
  readOnly,
  onChange,
}) => {
  const [image, setImage] = useState<string | null | undefined>(null)

  const onAdd = useCallback(
    async (fileList: FileList | null) => {
      const file = fileList?.item(0)
      const base64 = file && (await readFile(file))
      if (typeof base64 === 'string') {
        setImage(base64)
        onChange(base64)
      } else {
        setImage(null)
      }
    },
    [onChange]
  )

  const handleDelete = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      setImage(null)
      onChange(null)
    },
    [onChange]
  )

  useEffect(() => {
    setImage(defaultValue)
  }, [defaultValue])

  return (
    <Box>
      <S2DragArea
        component="div"
        accept={accept}
        onAdd={onAdd}
        readOnly={readOnly}
        {...dragAreaProps}
      >
        {image ? (
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              width: '100%',
              height: '100%',
              background: `url(${image}) no-repeat 50% 50%`,
              backgroundSize: 'contain',
            }}
          >
            {!readOnly && (
              <Button
                variant="contained"
                color="error"
                size="small"
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  p: 2,
                  minWidth: 0,
                  borderRadius: 20,
                }}
                onClick={handleDelete}
              >
                <CloseIcon fontSize="small" />
              </Button>
            )}
          </Box>
        ) : null}
      </S2DragArea>
      <Description variant="body2" color={(theme) => theme.palette.grey[500]}>
        ※ 容量1MB以内のJPGE画像、またはPNG画像を登録できます。
        <br />※ ファイル名は半角英数字、ハイフン、ダッシュのみ使用可能です。
      </Description>
    </Box>
  )
}

export const uploadFormRender = <T extends FieldValues, N extends Path<T>>(
  {
    field,
    fieldState,
    dragAreaWidth = 240,
  }: Parameters<ControllerProps<T, N>['render']>[0] & {
    dragAreaWidth?: number | string
  },
  options?: {
    readOnly?: boolean
  }
) => (
  <FormControl error={!!fieldState.error}>
    <UploadForm
      defaultValue={field.value as string}
      dragAreaProps={{
        sx: { width: dragAreaWidth, height: 240 },
      }}
      readOnly={options?.readOnly}
      onChange={field.onChange}
    />
    {fieldState.error && (
      <FormHelperText sx={{ mb: 2 }}>{fieldState.error.message}</FormHelperText>
    )}
  </FormControl>
)
