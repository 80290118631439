import { useCallback, useRef, useEffect } from 'react'
import { To, useLocation, useNavigate } from 'react-router-dom'
import { useProjectValue } from './useProject'

export const useRedirect = () => {
  const project = useProjectValue()
  const navigate = useNavigate()
  const loc = useLocation()
  const welcomeConfig = project?.customPages?.welcome
  const isMounted = useRef(true)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const redirect = useCallback(
    (pathname: To = '/welcome') => {
      // Only proceed if component is still mounted
      if (!isMounted.current) return

      const to = {
        ...loc,
        ...(typeof pathname === 'string' ? { pathname } : pathname),
      }
      const externalUrl =
        welcomeConfig?.type === 'external' ? welcomeConfig.url : null

      if (to.pathname === '/welcome' && externalUrl) {
        window.location.replace(externalUrl)
      } else {
        navigate(to, { replace: true })
      }
    },
    [loc, navigate, welcomeConfig]
  )

  return redirect
}
