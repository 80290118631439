import { z, ZodType, ZodRawShape } from 'zod'
import { atom, useRecoilCallback, useRecoilValue } from 'recoil'
import { useMemo } from 'react'
import { userGender, DefaultProfileEnum } from 's2-lib'
import { useProjectValue } from './useProject'
import { useAccountValue } from './useAccount'
import {
  CounselingAnswer,
  Counseling,
  counselingSchema,
} from '../schemas/counseling'
import { useLatestDiagnosisValue } from './useDiagnosis'

const answersState = atom<CounselingAnswer | undefined>({
  key: 'counselingAnswersState',
  default: undefined,
})

// This atom will store the current session's form values
// to be used as default values when navigating back to the form
const currentSessionAnswersState = atom<CounselingAnswer | undefined>({
  key: 'currentSessionAnswersState',
  default: undefined,
})

const PROFILE_CONSELINGS = [
  counselingSchema.parse({
    id: DefaultProfileEnum.BirthDate,
    title: '生年月日',
    required: true,
    fieldType: 'text',
    inputType: 'date',
  }),
  counselingSchema.parse({
    id: DefaultProfileEnum.Gender,
    title: '性別',
    required: true,
    fieldType: 'radiobox',
    options: Object.keys(userGender.Enum).map((gender) => {
      switch (gender as z.infer<typeof userGender>) {
        case 'female':
          return `${gender}:女性`
        case 'male':
          return `${gender}:男性`
        case 'others':
        default:
          return `${gender}:その他`
      }
    }),
  }),
]

export const useCounseling = () => {
  const project = useProjectValue()
  const account = useAccountValue()
  const items: Counseling[] = useMemo(() => {
    // 匿名機能ON または 入力済なら誕生日と性別はスキップ
    const skipInputProfile =
      project.features.anonymous || !!(account?.birthDate && account?.gender)

    return [
      ...(skipInputProfile ? [] : PROFILE_CONSELINGS),
      ...(project?.counselings ?? []),
    ]
  }, [
    account?.birthDate,
    account?.gender,
    project?.counselings,
    project.features.anonymous,
  ])

  const validateResolver = useMemo(
    () =>
      z.object(
        items.reduce<ZodRawShape>(
          (obj, { id, required, inputType, fieldType, options }) => {
            let ztype: ZodType | undefined
            switch (fieldType) {
              case 'checkbox':
              case 'radiobox':
              case 'selectbox': {
                const enums = options?.map(({ value }) => value)
                if (enums?.length) {
                  if (fieldType === 'radiobox' && !required) {
                    enums.push('')
                  }
                  // @ts-ignore
                  ztype = z.enum(enums, {
                    required_error: '必須項目です',
                  })
                  if (fieldType === 'checkbox') {
                    ztype = ztype.array()
                  }
                }
                break
              }
              case 'text':
                ztype = z.string({
                  required_error: '必須項目です',
                })
                switch (inputType) {
                  case 'date':
                    ztype = (ztype as z.ZodString).refine(
                      (v) => !Number.isNaN(Date.parse(v)),
                      '日付の形式に誤りがあります'
                    )
                    break
                  default:
                    break
                }
                break
              default:
                break
            }
            if (ztype) {
              if (!required) {
                ztype = ztype.optional()
              }
              // eslint-disable-next-line no-param-reassign
              obj[id] = ztype
            }
            return obj
          },
          {} as ZodRawShape
        )
      ),
    [items]
  )

  return {
    items,
    validateResolver,
  }
}

export const useCounselingWithDefaultValues = () => {
  const { items, ...args } = useCounseling()
  const latest = useLatestDiagnosisValue()
  const currentSessionAnswers = useRecoilValue(currentSessionAnswersState)

  const defaultValues = useMemo(() => {
    // Use latest diagnosis answers if available, otherwise use current session answers
    const prevAnswers = latest?.item.counseling || currentSessionAnswers
    return items.reduce<CounselingAnswer>(
      (values, { id, fieldType, options }) => {
        const enableValues = options?.map(({ value }) => value)
        const currentVal = prevAnswers?.[id]
        const currentValues =
          typeof currentVal === 'string' ? [currentVal] : currentVal
        const firstValue = currentValues?.[0]

        switch (fieldType) {
          case 'checkbox': {
            const list = currentValues?.filter((val) =>
              enableValues?.includes(val)
            )
            if (list?.length) {
              // eslint-disable-next-line no-param-reassign
              values[id] = list
            }
            break
          }
          case 'radiobox':
          case 'selectbox':
            if (firstValue && enableValues?.includes(firstValue)) {
              // eslint-disable-next-line no-param-reassign
              values[id] = firstValue
            }
            break
          case 'text':
            if (firstValue) {
              // eslint-disable-next-line no-param-reassign
              values[id] = firstValue
            }
            break
          default:
            break
        }

        return values
      },
      {}
    )
  }, [items, latest?.item.counseling, currentSessionAnswers])

  return {
    items,
    defaultValues,
    ...args,
  }
}

export const useCounselingAnswerValue = () => useRecoilValue(answersState)

export const useSetCounselingAnswer = () => {
  const { counselings } = useProjectValue()
  const requireKeys = useMemo(
    () => counselings.filter((data) => data.required).map((data) => data.id),
    [counselings]
  )

  return useRecoilCallback(
    ({ set }) =>
      (state: CounselingAnswer) => {
        const filtered = Object.entries(state).reduce<CounselingAnswer>(
          (map, [key, value]) => {
            if (requireKeys.includes(key)) {
              // 必須
              if (!value) {
                throw new Error(`${key} is required.`)
              }

              // eslint-disable-next-line no-param-reassign
              map[key] = value
            } else if (value) {
              // 任意項目
              // eslint-disable-next-line no-param-reassign
              map[key] = value
            }
            return map
          },
          {}
        )

        // Store in both the answers state and the current session state
        set(answersState, filtered)
        set(currentSessionAnswersState, filtered)
      },
    [requireKeys]
  )
}

export const useCounselingAnswer = () =>
  [useCounselingAnswerValue(), useSetCounselingAnswer()] as const
