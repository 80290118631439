import { Box, CircularProgress, Menu, MenuItem, styled } from '@mui/material'
import { FC, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { DateTime } from 'luxon'
import { MaterialIconButton, MaterialIconButtonProps } from 's2-component'
import { useHistory } from '../../../../common/hooks/useDiagnosis'
import { useIntersectionObserver } from '../../../../common/hooks/useIntersectionObserver'
import { useGA } from '../../../../common/hooks/useGA'

const ITEM_HEIGHT = 48

const MenuLauncherItem = styled(MenuItem)({
  justifyContent: 'center',
  padding: 0,
  minHeight: `${ITEM_HEIGHT}px !important`,
})

export type MenuLauncherProps = Omit<MaterialIconButtonProps, 'onSelect'> & {
  selected: number
  onSelect(id: number): void
}

export const MenuLauncher: FC<MenuLauncherProps> = ({
  selected,
  onSelect,
  ...props
}) => {
  const { history, hasNext, fetchHistory } = useHistory()
  const anchorElRef = useRef<HTMLButtonElement>(null)
  const [loadingEl, setLoadingRef] = useState<null | HTMLElement>(null)
  const isIntersectingLoading = useIntersectionObserver(loadingEl)
  const [open, setOpen] = useState(false)
  const loadingHistory = open && isIntersectingLoading && hasNext
  const { selectContent } = useGA()[1]

  const show = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      setOpen(true)
      selectContent('histroy_detail:showHistoryMenu')
    },
    [selectContent]
  )

  const hide = useCallback(() => {
    setOpen(false)
  }, [])

  const select = useCallback(
    (id: number) => {
      hide()
      // NOTE: メニューのクローズアニメーションを待つ
      // 待たないと画面が切り替わったあとにmenuが残ってしまう
      setTimeout(onSelect, 500, id)
    },
    [hide, onSelect]
  )

  useEffect(() => {
    if (loadingHistory) {
      fetchHistory()
    }
  }, [fetchHistory, loadingHistory])

  return (
    <>
      <MaterialIconButton ref={anchorElRef} {...props} onClick={show} />
      <Menu
        id="history-menu"
        MenuListProps={{
          'aria-labelledby': 'history-menu',
        }}
        anchorEl={anchorElRef.current}
        open={open}
        onClose={hide}
        slotProps={{
          paper: {
            style: {
              maxHeight: ITEM_HEIGHT * 3.5,
              width: 160,
            },
          },
        }}
      >
        {history.map((item) => (
          <MenuLauncherItem
            key={item}
            selected={item === selected}
            onClick={() => select(item)}
          >
            {DateTime.fromMillis(item).toFormat('yyyy/MM/dd HH:mm:ss')}
          </MenuLauncherItem>
        ))}
        {hasNext && (
          <Box
            ref={setLoadingRef}
            className="s2-flex-center"
            height={`${ITEM_HEIGHT}px`}
          >
            <CircularProgress size={24} />
          </Box>
        )}
      </Menu>
    </>
  )
}
