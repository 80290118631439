import {
  PropsWithChildren,
  FC,
  createContext,
  useContext,
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { z } from 'zod'
import { styled } from '@mui/material'
import { AxiosError } from 'axios'
import { Result } from '@badrap/result'
import { atom, useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil'
import { APIResult, APIError, APIErrorBody } from 's2-lib'
import { captureException } from '@sentry/react'
import { UnifiedFallbackLoader } from '../../components/ui/UnifiedFallbackLoader'
import client, { axios } from '../utils/api-client'
import { accountSchema } from '../schemas/account'
import { diagnosisResultSchema } from '../schemas/diagnosis'
import { useProjectValue, projectState } from './useProject'
import { accountState, idTokenState } from './useAccount'
import {
  currentDiagnosisIdState,
  diagnosisSelector,
  latestDiagnosisIdState,
  useTransferDiagnosis,
} from './useDiagnosis'
import { ErrorBoundaryFallback } from '../../components/ui/ErrorBoundaryFallback'
import { useLoading } from '../contexts/LoadingContext'

type UnwrapPromise<T> = T extends Promise<infer U> ? U : T

type InitializeResponse = UnwrapPromise<
  ReturnType<typeof client.initializeUser>
>['data']

type AuthState =
  // 初期状態
  | 'initial'
  // 確認中
  | 'loading'
  // 完了
  | 'ready'
  // 利用するConnectIDが既に別アカウントと紐付いている
  | 'alreadyConnected'
  // 利用するConnectIDが不正
  | 'invalidConnected'
  // 利用するリフレッシュトークンが不正
  | 'invalidRefreshToken'

const IFrame = styled('iframe')`
  width: 1px;
  height: 1px;
  position: absolute;
  top: -1px;
  left: -1px;
  pointer-events: none;
  opacity: 0;
  border: none;
`

// MEMO: 2025年になったら関連コードを削除
const REFRESH_TOKEN_LOCAL_STORAGE_KEY = 's2token'

const authState = atom<AuthState>({
  key: 'authState',
  default: 'initial',
})

const validConnectIdState = atom<boolean | null>({
  key: 'validConnectIdState',
  default: null,
})

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL
const TARGET_ORIGIN = new URL(API_BASE_URL).origin

const userSchema = z.object({
  userId: z.string(),
  projectId: z.string(),
  domain: z.string(),
  connectId: z.string().optional(),
  dynamicParams: z.record(z.string(), z.any()).optional(),
})

const fetchUserResponseSchema = z.union([
  z.object({
    payload: userSchema.nullable(),
    accessToken: z.string().optional(),
    expiresIn: z.number().int().optional(),
  }),
  z.object({
    errorCode: z.string(),
  }),
])

const refreshAccessTokenResponseSchema = z.union([
  z.object({
    accessToken: z.string(),
    expiresIn: z.number().int().positive(),
  }),
  z.object({
    errorCode: z.string(),
  }),
])

const setAuthorizationHeader = (() => {
  let id: number | undefined
  return (accessToken: string) => {
    if (typeof id === 'number') {
      axios.interceptors.request.eject(id)
    }
    id = axios.interceptors.request.use((val) => {
      // eslint-disable-next-line no-param-reassign
      val.headers = {
        ...val.headers,
        Authorization: `Bearer ${accessToken}`,
      }
      return val
    })
  }
})()

export type AuthProviderProps = PropsWithChildren<{
  projectId: string
  transferKey?: string
  onAuthorized?: (instance: S2InternalInstance) => void
}>

type AuthContext = [
  AuthState,
  {
    initialize: () => Promise<APIResult<boolean>>
    logout: (shouldReload?: boolean) => void
    validConnectId: boolean
    hasUserWithConnectId: boolean
    accessToken: string
  }
]

const ctx = createContext<AuthContext>([
  'initial',
  {
    initialize: async () => Result.ok(false),
    logout: () => {},
    validConnectId: false,
    hasUserWithConnectId: false,
    accessToken: '',
  },
])

export const AuthProvider: FC<AuthProviderProps> = ({
  projectId,
  transferKey,
  onAuthorized,
  children,
}) => {
  const [error, setError] = useState<{
    title: string
    message: React.ReactNode
  }>()
  const projectValue = useProjectValue()
  const { transferDiagnosis, signTransferKey } = useTransferDiagnosis()
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const refreshTokenTimerIdRef = useRef<NodeJS.Timeout>()
  const [authStateValue, setAuthState] = useRecoilState(authState)
  const [initialized, setInitialized] = useState(false)
  const [accessToken, setAccessToken] = useState('')
  const [hasUserWithConnectId, setHasUserWithConnectId] = useState(false)
  const { clearLoading } = useLoading()
  const validConnectId =
    (useRecoilValue(validConnectIdState) || hasUserWithConnectId) &&
    authStateValue !== 'invalidConnected'

  const requestAuth = useCallback(
    (type: string, data?: Record<string, any>) => {
      const contentWindow = iframeRef.current?.contentWindow
      if (contentWindow) {
        contentWindow.postMessage(
          {
            type,
            ...data,
          },
          TARGET_ORIGIN
        )
      } else {
        // eslint-disable-next-line no-console
        console.warn('iframe is not ready')
      }
    },
    []
  )

  const prepareLogout = useCallback(
    (shouldReload = false) => {
      requestAuth('s2-logout', { reload: shouldReload })
    },
    [requestAuth]
  )

  const verifyIdConnect = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const validConnectIdValue = await snapshot.getPromise(
          validConnectIdState
        )

        if (validConnectIdValue !== null) {
          return validConnectIdValue
        }

        const [project, idTokens] = await Promise.all([
          snapshot.getPromise(projectState),
          snapshot.getPromise(idTokenState),
        ])

        // ConnectIDの有効性を確認
        const connectIdStatus = project.features.connectId
        const hasConnectId = !!(idTokens?.idLinkage || idTokens?.idToken)
        let valid = !(
          (connectIdStatus === 'required' && !hasConnectId) ||
          (connectIdStatus === 'disabled' && hasConnectId)
        )

        if (connectIdStatus !== 'disabled' && hasConnectId) {
          const resposne = await client.verifyUserConnectId({
            projectId: project.id,
            idLinkage: idTokens?.idLinkage || undefined,
            idToken: idTokens?.idToken || undefined,
          })
          valid = resposne.data.valid
          setHasUserWithConnectId(resposne.data.hasAccount)
        }

        set(validConnectIdState, valid)
        return valid
      },
    []
  )

  const setupAuthorization = useCallback(
    (data: { accessToken: string; expiresIn: number }) => {
      setAuthorizationHeader(data.accessToken)
      clearTimeout(refreshTokenTimerIdRef.current)
      // 期限の切れる1分前に自動リフレッシュ
      const duration = data.expiresIn - (Date.now() + 60 * 1000)
      refreshTokenTimerIdRef.current = setTimeout(() => {
        requestAuth('s2-refresh-access-token', { projectId })
      }, duration)
      setAccessToken(data.accessToken)
    },
    [requestAuth, projectId]
  )

  const onError = useCallback(
    (errorCode?: string) => {
      switch (errorCode) {
        case 'ALREADY_CONNECTED_ID':
          setAuthState('alreadyConnected')
          break
        case 'INVALID_CONNECT_ID':
          setAuthState('invalidConnected')
          break
        default:
          if (
            errorCode === 'INVALID_REFRESH_TOKEN' ||
            errorCode?.startsWith('FAST_JWT_')
          ) {
            prepareLogout(true)
          }
          break
      }
    },
    [prepareLogout, setAuthState]
  )

  const prepareFetchUserByRefreshToken = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const idTokens = await snapshot.getPromise(idTokenState)
        requestAuth('s2-fetch-user-by-refresh-token', {
          ...idTokens,
        })
      },
    [requestAuth]
  )

  const prepareInitialize = useRecoilCallback(
    ({ set, snapshot }) =>
      async (): Promise<APIResult<boolean>> => {
        try {
          if ((await snapshot.getPromise(authState)) !== 'initial') {
            return Result.ok(false)
          }

          set(authState, 'loading')

          const [project, idTokens] = await Promise.all([
            snapshot.getPromise(projectState),
            snapshot.getPromise(idTokenState),
          ])

          requestAuth('s2-initialize', {
            ...idTokens,
            projectId: project.id,
          })

          return Result.ok(true)
        } catch (e) {
          const err = e as AxiosError
          onError(err.code)
          return Result.err(
            new APIError({
              code: err.code,
              statusCode: err.status ?? 500,
              message: err.message,
            })
          )
        }
      },
    [requestAuth, onError]
  )

  const postInitialize = useRecoilCallback(
    ({ set }) =>
      async ({
        item,
        latestDiagnosis,
        ...accessTokenInfo
      }: InitializeResponse) => {
        let transfered = false

        setupAuthorization(accessTokenInfo)
        set(accountState, accountSchema.parse(item))

        if (transferKey) {
          transfered = await transferDiagnosis(transferKey)
        }

        if (latestDiagnosis && !transfered) {
          const id = latestDiagnosis.item.createdAt
          set(
            diagnosisSelector(id),
            diagnosisResultSchema.parse(latestDiagnosis)
          )
          set(latestDiagnosisIdState, id)
          set(currentDiagnosisIdState, id)
        }

        onAuthorized?.({
          getLatestDiagnosisTransferKey: signTransferKey,
        })

        set(authState, 'ready')
      },
    [setupAuthorization, onAuthorized, transferKey]
  )

  const postLogout = useCallback(
    (shouldReload: boolean) => {
      localStorage.removeItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY)
      if (shouldReload) {
        window.location.reload()
      } else {
        setInitialized(false)
        setHasUserWithConnectId(false)
        setAuthState('initial')
        prepareInitialize()
      }
    },
    [setAuthState, prepareInitialize]
  )

  const hasExternalWelcomeWithConnectId = useMemo(
    () =>
      projectValue.features.connectId !== 'disabled' &&
      projectValue.customPages?.welcome?.type === 'external',
    [projectValue.features.connectId, projectValue.customPages?.welcome?.type]
  )

  const value = useMemo<AuthContext>(
    () => [
      authStateValue,
      {
        accessToken,
        hasUserWithConnectId,
        initialize: prepareInitialize,
        logout: prepareLogout,
        validConnectId,
      },
    ],
    [
      accessToken,
      authStateValue,
      hasUserWithConnectId,
      prepareLogout,
      prepareInitialize,
      validConnectId,
    ]
  )

  // APIのレスポンスエラーハンドリング
  useEffect(() => {
    const onErrorId = axios.interceptors.response.use(
      undefined,
      async (err: AxiosError) => {
        const body = (err.response?.data as APIErrorBody | undefined) ?? {
          statusCode: 500,
          code: 'INTERNAL_SERVER_ERROR',
          message: err.message ?? '',
        }

        switch (body.code) {
          case 'FAST_JWT_EXPIRED': {
            window.location.reload()
            break
          }
          default:
            if (body.code?.startsWith('FAST_JWT_')) {
              return prepareLogout(true)
            }
            break
        }

        throw new APIError(body)
      }
    )

    return () => {
      axios.interceptors.response.eject(onErrorId)
    }
  }, [prepareLogout])

  // iframe からのメッセージハンドリング
  useEffect(() => {
    async function onPostMessage({ data, origin }: MessageEvent) {
      const type = data?.type

      if (origin !== TARGET_ORIGIN) {
        return
      }

      switch (type) {
        case 's2-fetch-user-by-refresh-token': {
          const response = fetchUserResponseSchema.parse(data)
          if ('errorCode' in response) {
            onError(response.errorCode)
          } else {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const { payload, accessToken, expiresIn } = response
            const isAuthorized = !!(payload && accessToken && expiresIn)

            setHasUserWithConnectId(
              (current) => current || !!payload?.connectId
            )

            if (accessToken && expiresIn) {
              setupAuthorization({ accessToken, expiresIn })
            }

            if (isAuthorized || hasExternalWelcomeWithConnectId) {
              prepareInitialize()
            } else {
              setInitialized(true)
            }
          }
          break
        }
        case 's2-initialize': {
          if ('errorCode' in data) {
            onError(data.errorCode)
          } else {
            await postInitialize(data as InitializeResponse)
          }
          setInitialized(true)
          break
        }
        case 's2-logout':
          if (data?.success) {
            postLogout(data.reload)
          }
          break
        case 's2-refresh-access-token': {
          const response = refreshAccessTokenResponseSchema.parse(data)
          if ('errorCode' in response) {
            onError(response.errorCode)
          } else {
            setupAuthorization(response)
          }
          break
        }
        default:
          break
      }
    }

    window.addEventListener('message', onPostMessage)

    return () => {
      window.removeEventListener('message', onPostMessage)
    }
  }, [
    hasExternalWelcomeWithConnectId,
    onError,
    postInitialize,
    postLogout,
    prepareInitialize,
    setupAuthorization,
  ])

  // 初期化処理
  useEffect(() => {
    let isMounted = true
    const iframeSrc = new URL(
      `/v1/user/auth-provider?pid=${projectId}`,
      API_BASE_URL
    ).toString()

    Promise.all([
      // ID連携の検証
      verifyIdConnect(),
      // iframe のロード完了
      fetch(iframeSrc).then((res) => {
        if (!isMounted) {
          return Promise.resolve()
        }

        return new Promise<void>((resolve, reject) => {
          const iframe = iframeRef.current

          if (!res.ok) {
            setError({
              title: 'WEBサイトが許可されていません。',
              message: `管理画面のウィジェット設定から${window.location.origin}を追加してください。`,
            })
            reject(new Error('Unauthorized access detected.'))
            return
          }

          if (!iframe) {
            setError({
              title: 'iframeが見つかりません。',
              message: `iframeを設定してください。`,
            })
            reject(new Error('iframe not found.'))
            return
          }

          const timerId = setTimeout(() => {
            captureException(new Error(`auth-provider iframe timeout`))
            setError({
              title: '必須リソースの読み込みがタイムアウトしました。',
              message: `再読み込みしてください`,
            })
            reject(new Error('iframe loading timed out.'))
          }, 10000)
          iframe.onload = () => {
            clearTimeout(timerId)
            resolve()
          }
          iframe.onerror = (err) => {
            clearTimeout(timerId)
            captureException(err)
            setError({
              title: '必須リソースの読み込みエラーが発生しました。',
              message: `再読み込みしてください`,
            })
            reject(new Error('iframe failed to load.'))
          }
          iframe.src = iframeSrc
        })
      }),
    ]).then(() => {
      if (isMounted) {
        prepareFetchUserByRefreshToken()
      }
    })

    return () => {
      isMounted = false
    }
  }, [prepareFetchUserByRefreshToken, projectId, verifyIdConnect])

  useEffect(() => {
    window.s2Logout = prepareLogout
  }, [prepareLogout])

  useEffect(() => {
    if (error) {
      clearLoading()
    }
  }, [clearLoading, error])

  if (error) {
    return <ErrorBoundaryFallback {...error} />
  }

  return (
    <ctx.Provider value={value}>
      <IFrame title="s2-auth-provider" ref={iframeRef} />
      {initialized ? (
        children
      ) : (
        <UnifiedFallbackLoader source="auth-initialization" />
      )}
    </ctx.Provider>
  )
}

export const useAuth = () => useContext(ctx)
