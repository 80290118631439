import { z } from 'zod'
// import { idSchema } from './id'
import { shortTextSchema } from './text'

export const counselingSchema = z.object({
  // MEMO: id: idSchema にはしない（生年月日と性別の固定idがあるため）
  id: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  title: shortTextSchema.min(1, '必須項目です'),
  required: z.boolean(),
  fieldType: z.enum(['text', 'checkbox', 'radiobox', 'selectbox'], {
    required_error: '必須項目です',
  }),
  options: shortTextSchema.array().nullable().default(null),
})

export type Counseling = z.infer<typeof counselingSchema>

// カウンセリングで使用する固定ID
export const DefaultProfileEnum = {
  BirthDate: 'profile-birthdate',
  Gender: 'profile-gender',
} as const
