import { z } from 'zod'
import { colorSchema } from './color'
import { mediumTextSchema, shortTextSchema } from './text'
import { idSchema } from './id'
import { commonCustomPageSchema, welcomeCustomPageSchema } from './customPage'

export const projectLinkBehaviorEnum = z.enum(['blank', 'self'], {
  description: 'リンクの挙動',
})

export const defaultTips = [
  '熱々のお湯で洗ってない？30°Cくらいの「ぬるま湯」洗顔が鉄則よ',
  '化粧水ケアでペチペチ叩き入れはNG！ゆっくりハンドプレスしよ♪',
  'マスク荒れは肌摩擦が原因。予防のためにも下地は毎日塗ろう！',
  '老化原因No.1の紫外線は1年中降り注ぐ。日焼け止めでガードして！',
  '寝る前スマホはブルーライトシャワー。睡眠質・肌質も悪化します',
  'メイクしたまま寝落ちした翌朝はレンチン蒸しタオルで肌、復活！',
  '保湿ケアしてるのに肌が乾くなら、下地に乳液を混ぜて潤いプラス',
  '上向きにスプレーして浴びる保湿ミスト法で乾燥&メイク崩れ防止',
  '枕やシーツ、洗ってる？清潔な寝具はニキビ予防のひとつです',
  '美肌の人ほど帰宅後、即メイクオフ。悪い脂質は毛穴つまりのもと',
]

export const projectRecommendationTypes = ['rule', 'ai'] as const

export const projectProductSchema = z.object({
  visibleItem: z.object({
    thumbnail: z.boolean().default(true),
    brand: z.boolean().default(true),
    name: z.boolean().default(true),
    price: z.boolean().default(true),
    tags: z.boolean().default(true),
    description: z.boolean().default(true),
  }),
  maxNumOf: z.number().default(10),
  groupBy: z.enum(['category']).nullable().default(null),
  maxNumOfInGroup: z.number().default(-1),
  nonGroupPriority: z.number().default(9999),
  columns: z.number().default(2),
  linkBehavior: projectLinkBehaviorEnum.default('blank'),
})

export const projectSchema = z.object({
  id: idSchema,
  // MEMO:今後Stripeと連携し、支払い情報を入力済のユーザーのみtrueにする
  isActive: z.boolean().default(true),
  title: shortTextSchema,
  features: z.object({
    connectId: z
      .enum(['required', 'optional', 'disabled'])
      .default('disabled')
      .describe('ID連携の有効/無効'),
    anonymous: z
      .boolean()
      .default(false)
      .describe('匿名（年齢・性別の任意化）の有効/無効'),
    homePage: z
      .enum(['result', 'shooting'])
      .default('result')
      .describe('ホーム画面として表示するページ'),
    saveFacePict: z.boolean().default(false).describe('顔写真保存の有効/無効'),
    productRecommendation: z
      .enum(projectRecommendationTypes)
      .default('rule')
      .describe('商品レコメンドの種類'),
    connectLiffId: z
      .string()
      .nullable()
      .default(null)
      .describe('ID連携用のLIFF ID'),
    transferAccountUrl: z
      .union([
        z.string().url(),
        z.literal('').transform(() => 'SAME_DOMAIN'),
        z.literal('SAME_DOMAIN'),
      ])
      .optional()
      .describe('アカウント情報を別端末へ引き継ぐ機能のURL'),
    userCustomAttributeAPIEndpoint: z
      .union([z.string().url(), z.literal('').transform(() => undefined)])
      .optional()
      .describe('カスタムユーザ属性取得APIエンドポイント'),
  }),
  enabledFeatures: z
    .object({
      productRuleRecommendation: z
        .boolean({ description: '商品レコメンドの有効/無効' })
        .default(true),
      aiReccomendation: z
        .boolean({ description: '商品のAIレコメンドの有効/無効' })
        .default(false),
      connectId: z.boolean({ description: 'ID連携の有効/無効' }).default(false),
      facePict: z
        .boolean({ description: '診断画像の保存・閲覧機能の有効/無効' })
        .default(false),
      externalAPI: z
        .boolean({ description: 'API利用の有効/無効' })
        .default(false),
      trimmingNose: z
        .boolean({ description: '鼻のトリミングの有効/無効' })
        .default(false),
    })
    .describe('有償機能の有効/無効'),
  customPages: z
    .object(
      {
        welcome: welcomeCustomPageSchema.nullable().default(null),
        privacyPolicy: commonCustomPageSchema.nullable().default(null),
        termsOfService: commonCustomPageSchema.nullable().default(null),
      },
      {
        description: 'クライアント独自で用意できるカスタムページ',
      }
    )
    .nullable()
    .default(null),
  thumbnailUrl: z.string().nullable().default(null),
  titleImageUrl: z.string().nullable().default(null),
  color: z
    .object({
      primary: colorSchema,
      secondary: colorSchema,
      accent: colorSchema,
    })
    .default({
      primary: '#40AEA9',
      secondary: '#14AAFF',
      accent: '#14AAFF',
    }),
  history: z
    .object({
      visibleItem: z.object({
        skinType: z.boolean().default(true),
        skinAge: z.boolean().default(true),
        overallRating: z.boolean().default(true),
        facePict: z.boolean().default(false),
        backnumber: z.boolean().default(true),
        comparisonScore: z.boolean().default(false),
        skinScore: z
          .object({
            clarity: z.boolean(),
            moisture: z.boolean(),
            pores: z.boolean(),
            stain: z.boolean(),
            texture: z.boolean(),
            wrinkle: z.boolean(),
          })
          .default(() => ({
            clarity: true,
            moisture: true,
            pores: true,
            stain: true,
            texture: true,
            wrinkle: true,
          })),
      }),
      features: z.object({
        detailView: z.boolean().default(true),
        transactionView: z.boolean().default(true),
        defaultView: z.enum(['detail', 'transaction']).default('detail'),
        trimmingPict: z
          .enum(['full', 'nose'], {
            description: '顔写真のトリミング方法',
          })
          .default('full'),
        roundOverallRating: z
          .boolean({ description: '総合評価を四捨五入するか' })
          .default(false),
        additionalPicts: z
          .enum(['dir-right', 'dir-left'], {
            description: '追加画像の種類',
          })
          .array()
          .default(() => []),
      }),
    })
    .default({
      visibleItem: {},
      features: {},
    }),
  product: projectProductSchema,
  feedback: z
    .object({
      maxNumOf: z.number(),
      linkBehavior: projectLinkBehaviorEnum,
    })
    .default({
      maxNumOf: 3,
      linkBehavior: 'blank',
    }),
  labels: z.record(mediumTextSchema).default({}),
  tips: z
    .custom<string[]>()
    .refine(
      (v) =>
        !v?.length || v.every((val) => shortTextSchema.safeParse(val).success),
      '1行あたり64文字以内に収めてください'
    )
    .transform((v) => v || [...defaultTips]),
})

export type Project = z.infer<typeof projectSchema>

export const projectRecommendationLabel: Record<
  (typeof projectRecommendationTypes)[number],
  string
> = {
  rule: 'ルールベース',
  ai: 'AIベース',
}

export const projectEnableFeatureLabel: Record<
  keyof Project['enabledFeatures'],
  string
> = {
  aiReccomendation: 'AIベース商品レコメンド',
  productRuleRecommendation: 'ルールベース商品レコメンド',
  connectId: 'ID連携',
  facePict: '顔写真の保存・閲覧',
  externalAPI: 'API利用',
  trimmingNose: '鼻のトリミング',
}
