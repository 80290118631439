import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@mui/material'
import { FieldValues, Path, useFormContext, Controller } from 'react-hook-form'
import { parseOptions, FieldProps } from './helper'

type Prop = {
  convertBoolean?: boolean
  disabled?: boolean
  readOnly?: boolean
}

const RadioBoxField = <T extends FieldValues, N extends Path<T>>({
  name,
  options,
  size,
  fullWidth,
  disabled,
  readOnly,
  convertBoolean = false,
}: Prop & FieldProps<T, N>) => {
  const { control } = useFormContext<T>()
  const parsedOption = parseOptions(options)
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={'' as any}
      render={({ field: { onChange, ...field }, fieldState }) => (
        <FormControl
          size={size}
          fullWidth={fullWidth}
          error={!!fieldState.error}
          disabled={readOnly || disabled}
          sx={{ my: -2 }}
        >
          <RadioGroup
            aria-labelledby={name}
            {...field}
            row
            onChange={(e) => {
              const v = e.target.value
              if (convertBoolean) {
                onChange(v === 'true')
              } else {
                onChange(v)
              }
            }}
          >
            {parsedOption.map(({ value, label }) => (
              <FormControlLabel
                key={value}
                value={convertBoolean ? !!value : value}
                control={<Radio />}
                label={label}
              />
            ))}
          </RadioGroup>
          {fieldState.error && (
            <FormHelperText sx={{ mb: 2 }}>
              {fieldState.error.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  )
}

export default RadioBoxField
