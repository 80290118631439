import { Fade } from '@mui/material'
import {
  type FC,
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useMemo,
  useCallback,
  useRef,
} from 'react'
import { FallbackLoader } from 's2-component'

interface LoadingContextType {
  isLoading: boolean
  clearLoading: () => void
  addLoadingSource: (source: string) => void
  removeLoadingSource: (source: string) => void
}

const LoadingContext = createContext<LoadingContextType>({
  isLoading: true,
  clearLoading: () => {},
  addLoadingSource: () => {},
  removeLoadingSource: () => {},
})

export const useLoading = () => useContext(LoadingContext)

interface LoadingProviderProps {
  children: ReactNode
}

export const LoadingProvider: FC<LoadingProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [loadingSources, setLoadingSources] = useState(
    new Set<string>(['initial'])
  )
  const removeDelayRef = useRef<NodeJS.Timeout | null>(null)
  const removeSourceRef = useRef<Set<string>>(new Set(['initial']))

  useEffect(() => {
    setIsLoading(loadingSources.size > 0)
  }, [loadingSources])

  const addLoadingSource = useCallback((source: string) => {
    setLoadingSources((prev) => {
      const newSources = new Set(prev)
      newSources.add(source)
      return newSources
    })
  }, [])
  const isMounted = useRef(true)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const removeLoadingSource = useCallback((source: string) => {
    if (removeDelayRef.current) {
      clearTimeout(removeDelayRef.current)
    }
    removeSourceRef.current.add(source)
    removeDelayRef.current = setTimeout(() => {
      if (isMounted.current) {
        setLoadingSources((prev) => {
          const removeSource = removeSourceRef.current
          const newSources = new Set(
            [...prev].filter((x) => !removeSource.has(x))
          )
          removeSource.clear()
          return newSources
        })
      }
    }, 500)
  }, [])

  const clearLoading = useCallback(() => {
    if (removeDelayRef.current) {
      clearTimeout(removeDelayRef.current)
    }
    removeSourceRef.current.clear()
    setLoadingSources(new Set())
  }, [setLoadingSources])

  const contextValue = useMemo(
    () => ({
      isLoading,
      clearLoading,
      addLoadingSource,
      removeLoadingSource,
    }),
    [isLoading, clearLoading, addLoadingSource, removeLoadingSource]
  )

  return (
    <LoadingContext.Provider value={contextValue}>
      {children}
      <Fade
        appear
        in={isLoading}
        style={{
          position: 'absolute',
          inset: 0,
          zIndex: 9999,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}
      >
        <FallbackLoader fullHeight />
      </Fade>
    </LoadingContext.Provider>
  )
}
