import { AxiosError } from 'axios'
import { Result } from '@badrap/result'
import { atom, useRecoilCallback, useRecoilValue } from 'recoil'
import { setUser } from '@sentry/react'
import { APIError, APIResult } from 's2-lib'
import { projectIdState } from './useProject'
import { accountSchema, Account } from '../schemas/account'
import client from '../utils/api-client'

export const idTokenState = atom<{
  idToken?: string
  idLinkage?: string
  dynamicParams?: Record<string, any>
} | null>({
  key: 'idTokenState',
  default: null,
})

export const accountState = atom<Account | null>({
  key: 'accountState',
  default: null,
  effects: [
    ({ onSet, getPromise }) => {
      onSet(async (account) => {
        setUser(
          account
            ? {
                id: account.id,
                username: account.displayName,
                projectId: await getPromise(projectIdState),
              }
            : null
        )
      })
    },
  ],
})

export const useAccountValue = () => useRecoilValue(accountState)

export const useAccountMethods = () => {
  const updateProfile = useRecoilCallback(
    ({ set }) =>
      async (
        input: Parameters<typeof client.updateUserProfile>[0]
      ): Promise<APIResult<Account>> => {
        try {
          const response = await client.updateUserProfile(input)
          const account = accountSchema.parse(response.data.item)
          set(accountState, account)
          return Result.ok(account)
        } catch (e) {
          const err = e as AxiosError
          return Result.err(
            new APIError({
              code: err.code,
              statusCode: err.status ?? 500,
              message: err.message,
            })
          )
        }
      },
    []
  )

  return {
    updateProfile,
  }
}

export const useAccount = () =>
  [useAccountValue(), useAccountMethods()] as const
