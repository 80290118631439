import { MobileDatePicker } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'
import { TextField, TextFieldProps } from '@mui/material'
import {
  FieldValues,
  Path,
  useFormContext,
  Controller,
  PathValue,
} from 'react-hook-form'
import { useMemo } from 'react'
import { FieldProps } from './helper'

type Props<T extends FieldValues, N extends Path<T>> = Omit<
  FieldProps<T, N>,
  'options'
> & {
  title?: string
  defaultValue?: string
  textFieldProps?: TextFieldProps
  readOnly?: boolean
}

const format = 'yyyy/MM/dd'

const DateField = <T extends FieldValues, N extends Path<T>>({
  title,
  name,
  defaultValue,
  textFieldProps,
  readOnly,
  ...props
}: Props<T, N>) => {
  const { control } = useFormContext<T>()
  const defaultDate = useMemo(() => {
    const value =
      !defaultValue || Number.isNaN(new Date(defaultValue).getTime())
        ? '1980-01-01T00:00:00+0900'
        : defaultValue
    return DateTime.fromISO(value).toFormat(format)
  }, [defaultValue])
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultDate as PathValue<T, N>}
      render={({ field: { onChange, ...field }, fieldState }) => (
        <MobileDatePicker
          {...field}
          inputFormat={format}
          toolbarPlaceholder="日付を選択してください"
          toolbarFormat={format}
          toolbarTitle={title}
          renderInput={(params) => (
            <TextField
              placeholder={defaultDate}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              InputProps={{
                readOnly,
              }}
              {...props}
              {...textFieldProps}
              {...params}
            />
          )}
          onChange={(e) => {
            const date = e as unknown as DateTime
            onChange(date.toISODate())
          }}
        />
      )}
    />
  )
}

export default DateField
