import { atom, selector, useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { Project, projectSchema } from '../schemas/project'
import client from '../utils/api-client'

export const projectIdState = atom<string | null>({
  key: 'projectIdState',
  default: null,
})

export const projectState = atom<Project>({
  key: 'projectState',
  default: selector<Project>({
    key: 'projectStateSelector',
    async get({ get }) {
      const id = get(projectIdState)

      if (!id) {
        throw new Error('Required Project ID')
      }

      const response = await client.findProjectByIdOnUser(id)
      return projectSchema.parse(response.data.item)
    },
  }),
})

export const useProjectValue = () => useRecoilValue(projectState)

export const useProjectValueLoadable = () =>
  useRecoilValueLoadable(projectState)
