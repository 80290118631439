import * as Sentry from '@sentry/react'
import { AxiosError } from 'axios'
import { useEffect } from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

const { DEV, MODE, VITE_PUBLIC_BASE_PATH, VITE_SENTRY_RELEASE } = import.meta
  .env

Sentry.init({
  dsn: 'https://5cb1f51ea84a53601b4e7d72f6f79650@o4508340901445632.ingest.us.sentry.io/4508340913242112',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
  release: VITE_SENTRY_RELEASE,
  environment: MODE,
  debug: MODE !== 'production',
  enabled: !DEV,

  beforeBreadcrumb(breadcomb) {
    if (
      typeof breadcomb.data?.url === 'string' &&
      breadcomb.data.url.startsWith('data:application/octet-stream;base64,')
    ) {
      // eslint-disable-next-line no-param-reassign
      breadcomb.data.url = 'data:application/octet-stream;base64,...'
    }
    return breadcomb
  },

  beforeSend(event, { originalException }) {
    const hasWidgetError = event.exception?.values?.some((exception) =>
      exception.stacktrace?.frames?.some((frame) =>
        frame.filename?.includes(VITE_PUBLIC_BASE_PATH)
      )
    )
    if (originalException instanceof AxiosError) {
      const statusCode =
        originalException.response?.status ?? originalException.status ?? 0
      // サーバーエラーはサーバー側でログを取るため無視
      if (statusCode >= 500) {
        return null
      }
    }
    // ウィジェット外のエラーは無視
    return hasWidgetError ? event : null
  },
})
