import { z } from 'zod'
import { nullableUrlSchema } from './url'
import { longTextSchema, shortTextSchema } from './text'
import { idSchema } from './id'

export const feedbackSchema = z.object({
  id: idSchema,
  createdAt: z.string(),
  updatedAt: z.string(),
  title: z.string().min(1, '必須項目です'),
  layout: z.enum(['rich', 'comment', 'banner'], {
    required_error: '必須項目です',
  }),
  content: z
    .object({
      banner: z.string().nullable().default(null),
      link: nullableUrlSchema,
      title: shortTextSchema,
      comment: longTextSchema,
      thumbnail: z.string().nullable().default(null),
      thumbMainTitle: shortTextSchema,
      thumbSubTitle: shortTextSchema,
    })
    .partial(),
})

export type Feedback = z.infer<typeof feedbackSchema>
